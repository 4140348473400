import { createStore } from 'vuex'

export default {
    namespaced: true,
    state: {
        status: '',
        editId: null,
        editChat: {},
        hoverId: null,
        unsaved: false,
        menu: 'edit',
    },
    getters: {
        status(state) { return state.status },
        editId(state) { return state.editId },
        editChat(state) { return state.editChat },
        hoverId(state) { return state.hoverId },
        menu(state) { return state.menu },
    },
    mutations: {
        init: function (state, payload) {
            state.status = 'view'
            state.editId = null
            state.editChat = {}
            state.hoverId = null
            state.unsaved = false
        },
        setStatus: function (state, payload) {
            state.status = payload.status
        },
        setEditId: function (state, payload) {
            state.editId = payload.editId
        },
        setEditChat: function (state, payload) {
            state.editChat = payload.editChat
        },
        setHoverId: function (state, payload) {
            state.hoverId = payload.hoverId
        },
        setMenu: function (state, payload) {
            state.menu = payload.menu
        },
        toggleRequire: function (state, payload) {
            state.editChat.data[payload.require] = !state.editChat.data[payload.require]
        },
    },
    actions: {
        //  payload: {
        //      id,
        //  }
        toEdit({ state, commit, getters, rootGetters }, payload) {
            commit('setStatus', {'status': 'edit'})
            commit('setEditId', {'editId': payload.editId})
            if(payload.editId == -1) {
                state.editChat = {
                    type: 'header_base',
                    id: -1,
                    data: {
                        'title': rootGetters['scenario/title'],
                    }
                }
            }else{
                state.editChat = rootGetters['scenario/getChat'](payload.editId)
            }
        },
    },
}
