<template>
  <div class="chat_progress">
    <span v-if="index < length" class="progress_msg">あと<span class="progress_length">{{ length - index }}</span>問({{ index }}/{{ length }})</span>
    <span v-if="index >= length" class="progress_msg">ありがとうございました</span>
    <div class="progress_bar" :style="{ width: progress }"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChatProgress',
  props: [],
  data() {
    return {
      progress: Number,
    }
  },
  computed: {
    ...mapGetters({
      length: 'scenarioPlay/questions',
      index: 'scenarioPlay/progress',
    })
  },
  watch: {
  },
  mounted: function () {
    this.progress = (this.length > 0) ? Math.trunc(100 * this.index / this.length) + '%' : '100%';
    this.$store.watch(
      (state, getters) => getters['scenarioPlay/questions'],
      (newValue, oldValue) => {
        this.progress = (this.length > 0) ? Math.trunc(100 * this.index / this.length) + '%' : '100%';
      }
    )
    this.$store.watch(
      (state, getters) => getters['scenarioPlay/progress'],
      (newValue, oldValue) => {
        this.progress = (this.length > 0) ? Math.trunc(100 * this.index / this.length) + '%' : '100%';
      }
    )
  },
  methods: {
  }
}
</script>

<style scoped>
.chat_progress {
  margin: 0;
  border: 0;
  border-top: 1px;
  padding: 0;
  height: 22px;
  width: 100%;
  background: #fcccac;
  border-style: solid;
  border-color: transparent;
}

.progress_bar {
  position: absolute;
  float: flex;
  top: 1px;
  left: 0;
  height: 21px;
  background-color: #fa9352;
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}

.progress_msg {
  position: absolute;
  left: 0;
  padding: 0 10px;
  z-index: 2;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.progress_length {
  font-weight: 700;
  font-family: sans-serif;
}
</style>
