<template>
  <div class="dialog_exit">
    <transition name="mask">
      <div v-show="show" class="modal_mask"></div>
    </transition>
    <transition name="box">
      <div v-show="show" class="dialog_box">
        <p class="dialog_msg">{{message}}</p>
        <div class="dialog_button_area">
          <button class="dialog_button_cancel" @click="click_cancel">{{cancel_word}}</button>
          <button class="dialog_button_ok" @click="click_ok">{{ok_word}}</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DialogExit',
  props: ['enable', 'message', 'cancel_word', 'ok_word'],
  components: {
  },
  data () {
    return {
      show: false,
    }
  },
  watch: {
    enable: function(val) {
      this.show = val;
    }
  },
  mounted: function() {
    this.show = false;
  },
  methods: {
    click_cancel() {
      this.show = false;
      this.$emit("click_cancel");
    },
    click_ok() {
      this.$emit("click_ok");
    }
  }
}
</script>

<style scoped>
.dialog_exit {
  position: absolute;
  margin: 0;
  border: none;
  padding: 0;
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent;
  pointer-events: none;
}
.modal_mask {
  z-index: 1;
  position: absolute;
  margin: 0;
  border: none;
  padding: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: black;
  opacity: .5;
  pointer-events: auto;
}
.dialog_box {
  z-index: 2;
  position: absolute;
  margin: 5px;
  border: none;
  padding: 15px 0 15px 0;
  top: 0;
  right: 0;
  left: 0;
  height: 104px;
  background: white;
  pointer-events: auto;
}
.dialog_msg {
  box-sizing: border-box;
  margin: 0 0 10px 0;
  border: none;
  padding: 0 15px;
  width: 100%;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

.box-enter-active, .box-leave-active {
  transition: top 0.5s;
}
.box-enter-to, .box-leave-from {
  top: 0px;
}
.box-enter-from, .box-leave-to {
  top: -104px;
}

.dialog_button_area {
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.dialog_button_cancel {
  box-sizing: content-box;
  padding: 0 3px;
  border-color: #D0D6DC;
  border-style: solid;
  border-width: 1px;
  min-width: 90px;
  min-height: 42px;
  width: 41.66666667%;
  visibility: visible;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  position: relative;
  float: left;
}
.dialog_button_cancel:hover {
  background-color: #e6e6e6;
}
.dialog_button_ok {
  box-sizing: content-box;
  padding: 0 3px;
  min-width: 90px;
  min-height: 42px;
  width: 41.66666667%;
  visibility: visible;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  font-size: 16px;
  color: #fff;
  background-color: #d9534f;
  float: right;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  position: relative;
}
.dialog_button_ok:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
</style>
