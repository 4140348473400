<template>
    <div class="side_area">
        <div class="back_to_control" @click="backTo">
            <span class="material-symbols-outlined back_icon">arrow_back_ios</span><span>戻る</span>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'SideArea',
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            scenario_dump: 'scenario/dump',
        }),
    },
    mounted: function () {
    },
    methods: {
        backTo() {
            let path = this.$store.getters['customer/backTo']
            this.$router.push({ path })
        },
    },
}
</script>

<style scoped>
.side_area {
    width: 100%;
    height: 100%;
    background-color: #455A64;
    user-select: none;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.side_area h1 {
    color: white;
    margin-top: 20px;
    left: calc(10% - 10px);
}
.edit_window {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #ffb300;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.edit_window-enter-active,
.edit_window-leave-active {
    transition: left .25s ease;
}

.edit_window-enter-to,
.edit_window-leave-from {
    left: 0;
}

.edit_window-enter-from,
.edit_window-leave-to {
    left: -100%;
}

.menu_item {
    position: relative;
    text-align: left;
    width: 70%;
    left: 10%;
    background-color: white;
    padding: 9px 19px;
    margin: 10px 0;
    border-radius: 5px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
}

.menu_item:hover {
    color: aqua;
    border-color: aqua;
}

.back_to_control {
    text-align: left;
    color: black;
    background-color: white;
    cursor: pointer;
    height: 50px;
    padding-left: 30px;
}
.back_to_control:hover {
    color: aqua;
}
.back_to_control>span {
    font-size: 28px;
    line-height: 28px;
}

.material-symbols-outlined {
    padding: 10px;
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

.back_icon {
    font-size: 36px;
}

.menu_icon {
    font-size: 18px;
    padding: 0;
    line-height: 18px;
}
</style>