<template>
  <div v-if="initialized" class="edit">
    <div class="side_menu">
      <SideArea></SideArea>
    </div>
    <div class="main_box">
      <div class="head_area">
        <UserProfile></UserProfile>
      </div>
      <div class="title_area">
        <TitleArea></TitleArea>
      </div>
      <div class="contents_menu">
        <MenuArea></MenuArea>
      </div>
    </div>
  </div>
  <div v-else>
    読み込み中
  </div>
</template>

<script>
import SideArea from '@/components/scenario/edit/SideArea.vue'
import EditArea from '@/components/scenario/edit/EditArea.vue'
import TitleArea from '@/components/scenario/edit/TitleArea.vue'
import MenuArea from '@/components/scenario/edit/MenuArea.vue'
import Api from '@/lib/Api.js'
import UserProfile from '@/components/global/UserProfile.vue'

export default {
  name: 'EditView',
  components: {
    SideArea,
    EditArea,
    TitleArea,
    UserProfile,
    MenuArea,
  },
  data() {
    return {
      status: null,
      initialized: false,
    }
  },
  created() {
    this.$store.commit('editView/init')
  },
  mounted: async function () {
    this.status = await Api.getStatus(this)
    if (this.status.login_status != "LOGGED_IN") {
      this.$router.push("/login")
    }
    this.$store.watch(
      (state, getters) => getters['customer/customer_id'],
      (newValue, oldValue) => {
        if (newValue == null) {
          this.$router.push("/login")
        }
      }
    );
    this.initialized = true
  },
  methods: {
  },
}
</script>
  
<style scoped>
.edit {
  position: absolute;
  width: 100%;
  height: 100%;
}

.side_menu {
  position: absolute;
  border: none;
  border-width: 1px;
  border-color: black;
  left: 0;
  top: 0;
  width: 320px;
  height: 100%;
  margin: 0;
  padding: 0;
}

.main_box {
  position: absolute;
  border: none;
  border-width: 1px;
  border-color: black;
  left: 320px;
  width: max(calc(100% - 320px), 1100px);
  height: 100%;
  margin: 0;
  padding: 0;
}

.head_area {
  width: 100%;
  height: 50px;
}
.title_area {
  width: 100%;
  height: 120px;
}

.contents_menu {
  position: relative;
  border: none;
  border-width: 0;
  border-color: black;
  width: 100%;
  height: calc(100% - 170px);
  margin: 0;
  padding: 0;
}

</style>
