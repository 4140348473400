<template>
    <div class="title_area">
        <h1>シナリオプレビュー</h1>
        <div class="scenario_name">
            <label>シナリオ名(id:{{ scenario_id }})</label>
            <div :class="'status ' + scenario_status">{{ strStatus(scenario_status) }}</div>
            <div v-show="name_mode == 'view'" class="scenario_name_div" @click="toEditName">{{ this.name }}</div>
        </div>
    </div>
</template>
  
<script>
import component_wrapper from '@/components/scenario/edit/component_wrapper.vue'
import header_base from '@/components/chat/components/header_base.vue'
import { mapGetters } from 'vuex';

const componentLabel = {
    'operator_talk': 'オペレータートーク',
    'input_name': '会社名・姓名',
    'input_email_and_tel': 'メール・Tel',
    'select_schedule': 'スケジュール選択',
    'confirm': '確認',
    'complete': '完了',
}

export default {
    name: 'EditArea',
    props: [],
    components: {
        component_wrapper,
        header_base,
    },
    data() {
        return {
            mode: 'view',
            title: '',
            name: '',
            name_mode: 'view',
            status: '',
        }
    },
    computed: {
        ...mapGetters({
            scenario: 'scenarioPlay/scenario',
            scenario_title: 'scenarioPlay/title',
            scenario_name: 'scenarioPlay/name',
            scenario_id: 'scenarioPlay/id',
            scenario_dump: 'scenarioPlay/dump',
            scenario_status: 'scenarioPlay/status',
            scenario_saved: 'scenarioPlay/saved',
        }),
    },
    watch: {
        scenario_name: function () {
            this.name = this.scenario_name
        }
    },
    mounted: function () {
        this.name = this.scenario_name
        this.$store.watch(
            (state, getters) => getters['editView/status'],
            (newValue, oldValue) => {
                this.mode = newValue;
            }
        )
    },
    methods: {
        strStatus(status) {
            const st = {
                'draft': '非公開',
                'publish': '公開',
            }
            return st[status] == undefined ? '不明' : st[status]
        },
    },
}
</script>

<style scoped>
.title_area {
    width: 100%;
    height: 100%;
    background-color: #f6f6fa;
    overflow: hidden;
    position: relative;
}

.title_area h1 {
    margin-top: 10px;
    margin-left: 20px;
}

.scenario_name {
    text-align: left;
    margin-left: 20px;
}

.scenario_name label {
    font-size: 18px;
}

.scenario_name_div,
.scenario_name_input {
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    height: 36px;
    margin-left: 0;
    margin-right: 20%;
    font-size: 36px;
    line-height: 36px;
}

.status {
    display: inline-block;
    width: 60px;
    border-radius: 10px;
    text-align: center;
    margin-left: 30px;
    font-size: 0.8em;
    font-weight: 700;
}

.publish {
    background-color: blue;
    color: white;
}

.draft {
    background-color: red;
    color: white;
}
</style>