export default {
    namespaced: true,
    state: {
        status: '',
    },
    getters: {
        status(state) { return state.status },
    },
    mutations: {
        init: function (state, payload) {
            state.status = 'loading'
            },
        setStatus: function (state, payload) {
            state.status = payload.status
        },
    },
    actions: {
        init: function (store, payload) {
            store.commit('init', payload)
        },
        setStatus: function (store, payload) {
            store.commit('setStatus', payload)
        },
    },
}
