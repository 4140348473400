<template>
  <transition appear :name="status == 'input' ? 'fachatbot_input_free_text' : ''">
    <div class="input_free_text">
      <div class="input_body">
        <p class="title">{{ data.free_text_subject }}
          <span @click="status == 'edit' ? toggleRequire('free_text_required') : ''" v-if="data['free_text_required']"
            class="required">※必須</span>
          <span @click="status == 'edit' ? toggleRequire('free_text_required') : ''" v-if="!data['free_text_required']"
            class="optional">任意</span>
        </p>
        <div class="input_box">
          <textarea  ref="free_text" v-if="status === 'input' || status === 'edit'" type="text" :id="id + '_free_text'" class="input" :style="{'--lines' : data.free_text_display_lines }" 
            :placeholder="data.placeholder_free_text" v-model="free_text"></textarea>
          <textarea v-else type="text" :id="id + '_free_text'" class="input" :style="{'--lines' : data.free_text_display_lines }" disabled :placeholder="data.placeholder_free_text" v-model="free_text"></textarea>
        </div>
        <label v-if="err_free_text != ''" class="err">{{ err_free_text }}</label>
        <div class="next_message">
          <button v-if="status === 'input' && (btn_text == '次へ' || btn_text == '更新')" type="button" class="btn" @click="submit">
            {{ btn_text }}
          </button>
          <button v-if="status !== 'input' || btn_text == 'OK'" type="button" class="btn disabled" disabled @click="submit">
            {{ btn_text }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'InputName',
  props: ['id', 'data', 'status'],
  components: {
  },
  data() {
    return {
      free_text: '',
      err_free_text: '',
      btn_text: '次へ',
      edit_data: {},
      result: {},
    }
  },
  watch: {
    free_text: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
  },
  created: function () {
  },
  mounted: function () {
    if(this.status == 'input') {
      this.result = this.$store.getters['scenarioPlay/result'](this.id)
    }

    if (typeof this.result !== 'undefined') {
      if (typeof this.result.free_text !== 'undefined') {
        this.free_text = this.result.free_text;
      }
    }
    if (this.status == 'input') {
      this.$nextTick(() => this.$refs.free_text.focus());
    }
  },
  methods: {
    afterEnter() {
      this.$emit("afterEnter");
    },
    toggleRequire(require) {
      this.$emit("toggleRequire", require);
    },
    submit() {
      this.free_text = this.free_text.trim();
      if (this.data['free_text_required'] && this.free_text == '') {
        this.err_free_text = 'こちらは必須項目です。';
      } else {
        this.err_free_text = '';
      }
      if (this.err_free_text == '') {
        this.btn_text = 'OK';
        let data = { 'id': this.id, 'free_text': this.free_text };
        this.$emit("submit", this.id, data);
      }
    },
  }
}
</script>

<style scoped>
.input_free_text {
  padding: 3px 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.input_body {
  padding: 10px;
  background: #fcf9ce;
}

.input_group {
  width: 100%;
  word-break: break-word;
  margin-bottom: 10px;
}

.title {
  white-space: pre-line;
  padding: 3px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.input_box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.input_box textarea {
  width: calc(100% - 10px);
  font-size: 16px;
  line-height: 1.2em;
  height: calc(1.2em * var(--lines));
  margin-top: 10px;
  margin-bottom: 10px;
}

.input {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 100%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
  overflow-y: scroll;
}

.input_half {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 48%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  outline: 0;
  margin: 0 0 0 5px;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: inline-block;
}

.input::placeholder {
  color: #aaa;
}

.input_half::placeholder {
  color: #aaa;
}

.next_message {
  text-align: right;
  float: right;
  padding-right: 0px;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  overflow: visible;
}

.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}

button:not(.disabled) {
  -webkit-animation: bounce 2.6s infinite ease-in-out;
  animation: bounce 2.6s infinite ease-in-out;
}

@keyframes bounce {
  5% {
    -webkit-transform: scale(1.2, .8);
  }

  10% {
    -webkit-transform: scale(.8, 1.2) translateY(-5px);
  }

  15% {
    -webkit-transform: scale(1, 1);
  }
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.required {
  color: red;
  font-size: 12px;
}

.optional {
  color: gray;
  font-size: 12px;
}

.err {
  color: red;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.fachatbot_input_free_text-enter-active {
  transition: right .38s .3s ease, opacity .38s .3s ease-in;
}

.fachatbot_input_free_text-enter-to {
  opacity: 1;
  right: 0;
}

.fachatbot_input_free_text-enter-from {
  opacity: 0;
  right: -100px;
}
</style>
