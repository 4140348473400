<template>
  <div class="chat_base">
    <chat-progress />
    <div class="chat_area" id="chat_area">
      <component :key="c.id" :id="c.id" v-for="c in chat" :is='c.type' :data='c.data' :chat='chat' :confirm_result='confirm_result' :result='c.result' :status="status" @submit="chat_submit" @afterEnter="afterEnter" @unfreeze="unfreeze"></component>
    </div>
  </div>
</template>

<script>
import ChatProgress from './chat_progress.vue'
import operator_talk from './operator_talk.vue'
import input_name from './input_name.vue'
import input_email_and_tel from './input_email_and_tel.vue'
import select_schedule from './select_schedule.vue'
import select_free_select from '@/components/chat/components/select_free_select.vue'
import select_free_date from '@/components/chat/components/select_free_date.vue'
import select_free_radio from '@/components/chat/components/select_free_radio.vue'
import select_free_checkbox from '@/components/chat/components/select_free_checkbox.vue'
import input_free_input from '@/components/chat/components/input_free_input.vue'
import input_free_text from '@/components/chat/components/input_free_text.vue'
import input_agreement from '@/components/chat/components/input_agreement.vue'
import confirm from './confirm.vue'
import complete from './complete.vue'
import input_credit_add_account from '@/components/chat/components/input_credit_add_account.vue'
import input_credit from '@/components/chat/components/input_credit.vue'
import { mapGetters } from 'vuex';

export default {
  name: 'ChatBase',
  props: [],
  components: {
    ChatProgress,
    operator_talk,
    input_name,
    input_email_and_tel,
    select_schedule,
    select_free_select,
    select_free_date,
    select_free_radio,
    select_free_checkbox,
    input_free_input,
    input_free_text,
    input_agreement,
    confirm,
    complete,
    input_credit_add_account,
    input_credit,
  },
  data () {
    return {
      status: 'input',
      chat: [],
      confirm_result: null,
    }
  },
  computed: {
    ...mapGetters({
      scenario: 'scenarioPlay/scenario',
      questions: 'scenarioPlay/questions',
      progress: 'scenarioPlay/progress',
    })
  },
  watch: {
  },
  mounted: function() {
    this.$store.watch(
      (state, getters) => getters['scenarioPlay/visible'],
      (newValue, oldValue) => {
        this.clear()
      }
    )
  },
  methods: {
    clear(){
      if(this.status === 'input') {
        this.chat=[]
        this.$store.commit('scenarioPlay/setProgress', 0)
        this.$nextTick(function() {
          this.chat.push(this.scenario[0]);
        })
      }
    },
    chat_submit(id, result) {
      let i = this.scenario.findIndex((s) => s.id === id);
      let s = this.scenario[i]
      this.$store.commit('scenarioPlay/setProgress', s.progress)
      this.$store.commit('scenarioPlay/setResult', {'id': id, 'result': result, 'chat': s})
      let nextId = i + 1
      if(this.chat.slice(-1)[0]['id'] != id){ // 表示中の最後の質問が回答されたかどうか
        // 過去質問が更新された場合
        // 更新された質問以降の要素を削除
        while(this.chat.slice(-1)[0]['id'] != id) {
          this.chat.pop();
        }
        // 削除後に表示が更新後に後の要素を追加
        this.$nextTick(function() {
          if(nextId != this.scenario.length) {
            this.chat.push(this.scenario[nextId]);
          }
        })
      }else{
        // 最新の質問が回答された場合

        if(s['type'] === 'confirm') {
          this.status = 'freeze';
          this.confirm_result = result;
        }
        if(nextId != this.scenario.length) {
          this.chat.push(this.scenario[nextId]);
        }
      }
    },
    afterEnter() {
      const chat_area = document.getElementById('chat_area');
      if (!chat_area) return
      chat_area.scrollTop = chat_area.scrollHeight
    },
    unfreeze() {
      this.status = 'input';
    }
  }
}
</script>

<style scoped>
.chat_base {
  position: absolute;
  margin: 0;
  border: none;
  padding: 0;
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  background: black;
}

.chat_area {
  background: #fff;
  padding: 5px 10px 60px 5px;
  position: absolute;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  top: 22px;
  bottom: 0;
}
</style>
