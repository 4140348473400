<template>
  <div class="edit_box">
    <div class="title_box">
      <div class="edit_title">チャット設定</div>
      <div class="right">
        <div class="edit_submit" @click="submit">項目を保存</div>
      </div>
    </div>
    <div class="input_group">
      <div class="input_box">
        <label for="text">チャットタイトル</label>
        <input ref="input" id="text" type="text" v-model="edit_data.title" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditHeaderBase',
  props: ['chat'],
  components: {
  },
  data() {
    return {
      edit_data: {},
    }
  },
  watch: {
  },
  mounted: function () {
    this.edit_data = this.chat.data;
    this.$nextTick(() => {
      this.$refs.input.focus()
    })
  },
  methods: {
    submit: function () {
      this.$emit("submit", this.edit_data);
    },
  }
}
</script>

<style scoped>
.edit_box {
  margin: 20px;
}

.title_box {
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
}

.edit_title {
  position: absolute;
  color: white;
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
}

.right {
  position: absolute;
  height: 30px;
  right: 0;
}

.edit_submit {
  background-color: #42B5D1;
  border-color: #42B5D1;
  color: white;
  line-height: 24px;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 8px;
}

.input_group {
  overflow: hidden;
  border: 0 solid #ffb300;
  text-align: left;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  margin-bottom: 20px;
}

.input_box {
  border: 1px solid #ffb300;
  padding: 10px 5px;
  position: relative;
}

.input_box label {
  margin-bottom: 5px;
}

.input_box input[type="text"] {
  width: calc(100% - 10px);
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.input_box textarea {
  width: calc(100% - 10px);
  font-size: 16px;
  line-height: 1.2em;
  height: calc(1.2em * 5);
  margin-top: 10px;
  margin-bottom: 10px;
}

.subject {
  color: white;
}
</style>
