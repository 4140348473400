<template>
  <transition :name="device_type">
    <div v-show="visibility" :class="class_baseframe">
      <HeaderBase @click="dialog_exit" status="play" />
      <ChatBase v-if="scenario !== null" ref="chatbase" />
      <DialogNormal :enable="dialog_exit_flag" @click_ok="dialog_exit_ok" @click_cancel="dialog_exit_cancel"
        message="本当に閉じますか？" cancel_word="チャットに戻る" ok_word="閉じる" />
    </div>
  </transition>
</template>

<script>
//import axios from 'axios';
import HeaderBase from './/header_base.vue'
import ChatBase from './chat_base.vue'
import DialogNormal from './dialog_normal.vue'
import { mapGetters } from 'vuex';

export default {
  name: 'BaseFrame',
  props: [],
  components: {
    HeaderBase,
    ChatBase,
    DialogNormal,
  },
  data() {
    return {
      class_baseframe: '',
      device_type: '',
      dialog_exit_flag: false,
      global: {},
    }
  },
  computed: {
    ...mapGetters({
      scenario: 'scenarioPlay/scenario',
      visibility: 'scenarioPlay/visible',
    })
  },
  watch: {
  },
  mounted: async function () {
    if (navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)) {
      this.device_type = 'sp';
    } else {
      this.device_type = 'pc';
    }
    this.global['device_type'] = this.device_type;
    this.class_baseframe = 'baseframe-' + this.device_type;
    // this.scenario = this.$store.getters['scenarioPlay/scenario'];
    this.$store.watch(
      (state, getters) => getters['scenarioPlay/visible'],
      (newValue, oldValue) => {
        this.dialog_exit_flag = false;
      }
    )
    this.$store.commit('scenarioPlay/setVisible', false);
  },
  methods: {
    clear() {
      this.$nextTick(function () {
        this.$refs.chatbase.clear();
      })
    },
    dialog_exit() {
      this.dialog_exit_flag = true;
    },
    dialog_exit_cancel() {
      this.dialog_exit_flag = false;
    },
    dialog_exit_ok() {
      this.dialog_exit_flag = false;
      this.$store.commit('scenarioPlay/setVisible', false);
    },
  }
}
</script>

<style scoped>
.baseframe-pc {
  color: #2c3e50;
  background-color: transparent;
  z-index: 9999;
  position: absolute;
  /* width: min(calc(100% - max(min(calc(100% - 450px), 10px), 0px)), 450px); */
  width: 450px;
  height: min(100%, 700px);
  left: max(calc((100% - 450px) / 2), 0px);
  bottom: 0;
  opacity: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.baseframe-sp {
  color: #2c3e50;
  background-color: transparent;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.pc-enter-active,
.pc-leave-active {
  transition: bottom 0.5s;
}

.pc-enter-to,
.pc-leave-from {
  bottom: 0px;
}

.pc-enter-from,
.pc-leave-to {
  bottom: -700px;
}
</style>
