<template>
  <div class="edit_box">
    <div class="title_box">
      <div class="edit_title">名前</div>
      <div class="right">
        <div class="edit_submit" @click="submit">項目を保存</div>
      </div>
    </div>
    <div class="subject">表示設定</div>
    <div class="input_group">
      <div class="input_box">
        <input id="company_display" type="checkbox" v-model="edit_data.company_display" />
        <label for="company_display">会社名を表示</label>
      </div>
      <div class="input_box">
        <input id="name_display" type="checkbox" v-model="edit_data.name_display" />
        <label for="name_display">お名前を表示</label>

      </div>
      <div class="input_box">
        <input id="kana_display" type="checkbox" v-model="edit_data.kana_display" />
        <label for="kana_display">フリガナを表示</label>

      </div>
      <div class="input_box">
        <input id="separate" type="checkbox" v-model="edit_data.separate" />
        <label for="separate">姓名を分離する</label>
      </div>
    </div>

    <div v-if="edit_data.company_display">
      <div class="subject">会社名</div>
      <div class="input_group">
        <div class="input_box">
          <input id="company_required" type="checkbox" v-model="edit_data.company_required" />
          <label for="company_required">会社名を必須とする</label>
          
        </div>
        <div class="input_box">
          <label for="company_subject">タイトル</label>
          <input id="company_subject" type="text" v-model="edit_data.company_subject" />
        </div>
        <div class="input_box">
          <label for="placeholder_company">入力例</label>
          <input id="placeholder_company" type="text" v-model="edit_data.placeholder_company" />
        </div>
      </div>
    </div>

    <div v-if="edit_data.name_display">
      <div class="subject">お名前</div>
      <div v-if="edit_data.separate" class="input_group">
        <div class="input_box">
          <input id="name_required" type="checkbox" v-model="edit_data.name_required" />
          <label for="name_required">お名前を必須とする</label>
          
        </div>
        <div class="input_box">
          <label for="name_subject">タイトル</label>
          <input id="name_subject" type="text" v-model="edit_data.name_subject" />
        </div>
        <div class="input_box">
          <label for="placeholder_name1">入力例(姓)</label>
          <input id="placeholder_name1" type="text" v-model="edit_data.placeholder_name1" />
        </div>
        <div class="input_box">
          <label for="placeholder_name2">入力例(名)</label>
          <input id="placeholder_name2" type="text" v-model="edit_data.placeholder_name2" />
        </div>
      </div>
      <div v-if="!edit_data.separate" class="input_group">
        <div class="input_box">
          <input id="name_required" type="checkbox" v-model="edit_data.name_required" />
          <label for="name_required">お名前を必須とする</label>
          
        </div>
        <div class="input_box">
          <label for="name_subject">タイトル</label>
          <input id="name_subject" type="text" v-model="edit_data.name_subject" />
        </div>
        <div class="input_box">
          <label for="placeholder_name">入力例</label>
          <input id="placeholder_name" type="text" v-model="edit_data.placeholder_name" />
        </div>
      </div>
    </div>

    <div v-if="edit_data.kana_display">
      <div class="subject">フリガナ</div>
      <div v-if="edit_data.separate" class="input_group">
        <div class="input_box">
          <input id="kana_required" type="checkbox" v-model="edit_data.kana_required" />
          <label for="kana_required">フリガナを必須とする</label>
          
        </div>
        <div class="input_box">
          <label for="kana_subject">タイトル</label>
          <input id="kana_subject" type="text" v-model="edit_data.kana_subject" />
        </div>
        <div class="input_box">
          <label for="placeholder_kana1">入力例(姓)</label>
          <input id="placeholder_kana1" type="text" v-model="edit_data.placeholder_kana1" />
        </div>
        <div class="input_box">
          <label for="placeholder_kana2">入力例(名)</label>
          <input id="placeholder_kana2" type="text" v-model="edit_data.placeholder_kana2" />
        </div>
      </div>
      <div v-if="!edit_data.separate" class="input_group">
        <div class="input_box">
          <input id="kana_required" type="checkbox" v-model="edit_data.kana_required" />
          <label for="kana_required">フリガナを必須とする</label>
          
        </div>
        <div class="input_box">
          <label for="kana_subject">タイトル</label>
          <input id="kana_subject" type="text" v-model="edit_data.kana_subject" />
        </div>
        <div class="input_box">
          <label for="placeholder_kana">入力例</label>
          <input id="placeholder_kana" type="text" v-model="edit_data.placeholder_kana" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditInputName',
  props: ['chat'],
  components: {
  },
  data() {
    return {
      edit_data: {},
    }
  },
  watch: {
  },
  mounted: function () {
    this.edit_data = this.chat.data;
  },
  methods: {
    submit: function () {
      let data = this.edit_data;
      this.$emit("submit", data);
    },
  }
}
</script>

<style scoped>
.edit_box {
  margin: 20px;
}

.title_box {
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
}

.edit_title {
  position: absolute;
  color: white;
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
}

.right {
  position: absolute;
  height: 30px;
  right: 0;
}

.edit_submit {
  background-color: #42B5D1;
  border-color: #42B5D1;
  color: white;
  line-height: 24px;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 8px;
}

.input_group {
  overflow: hidden;
  border: 0 solid #ffb300;
  text-align: left;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  margin-bottom: 20px;
}

.input_box {
  border: 1px solid #ffb300;
  padding: 10px 5px;
  position: relative;
}

.input_box label {
  width: 100%;
  cursor: pointer;
}

.input_box input[type="text"] {
  width: calc(100% - 10px);
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.subject {
  color: white;
}
</style>
