<template>
  <transition appear name="input_credit" v-on:after-enter="afterEnter">
    <div class="input_credit">
      <div v-if="data.payment_method == 'univapay'" class="input_body">
        <p class="title">{{ data.subject }}</p>
        <div v-if="univapay_token != '' && univapay_secret != ''">
          <div v-if="status == 'input' && email == ''" style="font-weight: 700;color: red;">
            シナリオの「カード情報入力」より上に「メール・電話」のメール入力欄を設置してください。
          </div>
          <div v-if="state == 'input' && email != ''">
            <iframe ref="targetFrame" :src="univapay_url" class="input_group"></iframe>
          </div>
          <div v-if="state != 'input' && email != ''" class="input_box">
            <div>
              <pre class="input preformat">
カード名義：　{{ response.data.data.card.cardholder }}
カード番号：　****-****-****-{{ response.data.data.card.lastFour }}
</pre>
            </div>
            <div @click="updateCard" class="update_card_btn">カード情報変更</div>
          </div>
        </div>
        <div v-else>
          UnivaPayのトークン、シークレットが未設定です。
        </div>
        <!-- {{ data }} -->
        <div class="next_message">
          <button v-if="status === 'input' && (btn_text == '次へ' || btn_text == '更新')" type="button" class="btn"
            @click="submit">
            {{ btn_text }}
          </button>
          <button v-if="status !== 'input' || btn_text == 'OK'" type="button" class="btn disabled" disabled
            @click="submit">
            {{ btn_text }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
//import axios from 'axios';
import Api from '@/lib/Api.js'
export default {
  name: 'input_credit',
  props: ['id', 'data', 'result', 'status'],
  components: {
  },
  data() {
    return {
      btn_text: '次へ',
      univapay_token: '',
      univapay_secret: '',
      univapay_url: '',
      state: 'input',
      response: null,
      email: '',
    }
  },
  watch: {
  },
  mounted: function () {
    let s = this.$store.getters['scenarioPlay/scenario']
    this.email = ''
    if(this.status == 'input') {
      s.some((c) => {
        if(c.type == 'input_email_and_tel' && c.data.email_display) {
            let r = this.$store.getters['scenarioPlay/result'](c.id)
            this.email = r.email
        } else if(c.id == this.id){
          return true
        }
      })
    } else {
          this.email = 'dummy@example.com'
    }
    this.univapay_token = this.data.univapay_token
    this.univapay_secret = this.data.univapay_secret
    this.univapay_url = process.env.VUE_APP_API_URL
      + 'univapay.php?data-app-id=' + this.data.univapay_token
      + '&data-email=' + this.email
      + '&data-token-type=' + this.data.univapay_tokentype
      + '&preview=yes&cid=' + this.id
    if (typeof this.result !== 'undefined') {
      if (typeof this.result.card_number !== 'undefined') {
        this.card_number = this.result.card_number;
      }
      if (typeof this.result.card_month !== 'undefined') {
        this.card_month = this.result.card_month;
      }
      if (typeof this.result.card_year !== 'undefined') {
        this.card_year = this.result.card_year;
      }
    }
    var url = new URL(process.env.VUE_APP_API_URL)
    window.addEventListener('message', this.getMessage, false);
  },
  beforeUnmount: function () {
    window.removeEventListener('message', this.getMessage, false);
  },
  methods: {
    afterEnter() {
      this.$emit("afterEnter");
    },
    submit() {
      this.btn_text = 'OK';
      // var target = document.getElementById('univapay_frame_' + this.id);
      // target.postMessage({'method': 'submit'}, location.origin)
      this.$refs.targetFrame.contentWindow.postMessage({'method': 'submit'}, location.origin)
    },
    /**
     * 文字列内にある全角を半角へ変換
     * @param {string} value 変換したい文字列
     * @return {string} 変換された文字列を返す
     */
    toHalfWidth(value) {
      if (!value) return value;

      return String(value).replace(/[！-～]/g, function (all) {
        return String.fromCharCode(all.charCodeAt(0) - 0xFEE0);
      });
    },
    updateCard() {
      this.btn_text = '更新'
      this.state = 'input'
    },
    async getMessage (event) {
      var url = new URL(process.env.VUE_APP_API_URL)

      // 送られてきたのが確実に「c-brains.co.jp」からである事を確認
      if (event.origin != url.origin) {
        return;
      }
      if (event.data.cid != this.id) {
        return;
      }
      let response
      switch (event.data.method) {
        case 'height':
          var child_height = event.data.height;
          this.frameSize = "height:" + child_height + 'px'
          // var target = document.getElementById('univapay_frame_' + this.id);
          var target = this.$refs.targetFrame
          // // 受け取った数値を高さに設定
          target.style.height = child_height + 'px'
          break;
        case 'submit':
          this.response = await Api.univapayGetTokenInfo(this, {appToken: this.data.univapay_token, secret: this.data.univapay_secret, transactionToken: event.data.data.transactionToken})
          this.state = "complete"
          this.$emit("submit", this.id, {
            'card_number': this.response.data.data.card.lastFour,
            'card_name': this.response.data.data.card.cardholder,
            'payment_method': this.data.payment_method,
            'univapay_transaction_token': event.data.data.transactionToken,
            'univapay_app_token': this.data.univapay_token,
            'univapay_app_secret': this.data.univapay_secret,
            'amount': this.data.amount,
          });
          break;
        case 'error':
          this.btn_text = '次へ';
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style scoped>
.input_credit {
  padding: 3px 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.input_body {
  padding: 10px;
  background: #fcf9ce;
}

.input_group {
  width: 100%;
  word-break: break-word;
  margin-bottom: 10px;
}

.title {
  white-space: pre-line;
  padding: 3px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.input_box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.input_box pre {
  overflow-x: auto;
  padding: 10px;
}

.input {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 100%;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 0 8px;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
}

.noinput {
  text-align: left;
  font-size: 16px;
  background-color: #e4e4e4;
  color: rgb(0, 0, 0);
  border-color: #575757;
  width: 100%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 0 8px;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
}

.input_half {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 48%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 0 8px;
  outline: 0;
  margin: 0 0 0 5px;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}

.input::placeholder,
.input_half::placeholder {
  color: #aaa;
}

.next_message {
  text-align: right;
  float: right;
  padding-right: 0px;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  overflow: visible;
}

.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}

button:not(.disabled) {
  -webkit-animation: bounce 2.6s infinite ease-in-out;
  animation: bounce 2.6s infinite ease-in-out;
}

@keyframes bounce {
  5% {
    -webkit-transform: scale(1.2, .8);
  }

  10% {
    -webkit-transform: scale(.8, 1.2) translateY(-5px);
  }

  15% {
    -webkit-transform: scale(1, 1);
  }
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.required {
  color: red;
  font-size: 12px;
}

.err {
  color: red;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.input_credit-enter-active {
  transition: right .38s .3s ease, opacity .38s .3s ease-in;
}

.input_credit-enter-to {
  opacity: 1;
  right: 0;
}

.input_credit-enter {
  opacity: 0;
  right: -100px;
}
.update_card_btn {
  border: 1px solid #888;
  background-color: gray;
  width: 130px;
  color: white;
  margin-right: 0;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
}
</style>
