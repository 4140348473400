<template>
    <div class="title_area">
        <h1>チャットボット一覧</h1>
        <div class="button_list">
            <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/" target="_blank">マニュアル</a>
            <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/syoki_daikou/" target="_blank">初期設定代行サービス</a>
            <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/contact/" target="_blank">お問い合せ</a>
        </div>
        <div v-if="false" class="description_group">
            <div class="description"><span class="material-symbols-outlined icon-adj" title="プレビュー">live_tv</span>-プレビュー</div>
            <div class="description"><span class="material-symbols-outlined icon-adj" title="コピー">file_copy</span>-コピー</div>
            <div class="description"><span class="material-symbols-outlined icon-adj" title="自動返信メール設定">mail</span>-自動返信メール設定</div>
            <div class="description"><span class="material-symbols-outlined icon-adj" title="広告タグ設定">code_blocks</span>-広告タグ設定</div>
            <div class="description"><span class="material-symbols-outlined icon-adj" title="削除">delete</span>-削除</div>
        </div>

    </div>
</template>
  
<script>
import component_wrapper from '@/components/scenario/edit/component_wrapper.vue'
import header_base from '@/components/chat/components/header_base.vue'
import { mapGetters } from 'vuex';

const componentLabel = {
    'operator_talk': 'オペレータートーク',
    'input_name': '会社名・姓名',
    'input_email_and_tel': 'メール・Tel',
    'select_schedule': 'スケジュール選択',
    'confirm': '確認',
    'complete': '完了',
}

export default {
    name: 'TitleArea',
    props: [],
    components: {
        component_wrapper,
        header_base,
    },
    data() {
        return {
            mode: 'view',
            title: '',
            name: '',
            name_mode: 'view',
            status: '',
        }
    },
    computed: {
        ...mapGetters({
            scenario: 'scenario/scenario',
            scenario_title: 'scenario/title',
            scenario_name: 'scenario/name',
        })
    },
    watch: {
        scenario_name: function () {
            this.name = this.scenario_name
        }
    },
    mounted: function () {
        this.name = this.scenario_name
        this.$store.watch(
            (state, getters) => getters['editView/status'],
            (newValue, oldValue) => {
                this.mode = newValue;
            }
        )
    },
    methods: {
        toEditName() {
            this.name_mode = 'edit'
            this.$nextTick(() => {
                this.$refs.scenario_name_input.focus()
            })
        },
        mouseOutAction() {
            this.$store.state.scenarioEditView_hoverId = null;
        },
        otherEditCancel() {
            if (this.mode == 'edit') {
                this.mode = 'view';
                this.$store.commit('editView/setStatus', { 'status': 'view' })
                this.$store.commit('editView/setHoverId', { 'hoverId': null })
            }
        },
        dragList(event, dragIndex) {
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.setData('drag-index', dragIndex)
            event.dataTransfer.setData('type_component_wrapper', 'component_wrapper')
        },
        dragoverList(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_component_wrapper') != -1) {
                event.preventDefault()
            }
        },
        dragenterList(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_component_wrapper') != -1) {
                event.preventDefault()
            }
        },
        dropList(event, dropIndex) {
            const dragIndex = event.dataTransfer.getData('drag-index')
            const types = event.dataTransfer.types
            if (types.indexOf('type_component_wrapper') != -1)
                this.$store.commit('scenario/move', { 'fromIndex': dragIndex, 'toIndex': dropIndex })
        },
        dragoverAdd(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_side_area_chat') != -1) {
                event.preventDefault()
            }
        },
        dragenterAdd(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_side_area_chat') != -1) {
                event.preventDefault()
            }
        },
        dropAdd(event, dropIndex) {
            const types = event.dataTransfer.types
            let index = dropIndex == -1 ? this.$store.getters['scenario/length'] : dropIndex
            if (types.indexOf('type_side_area_chat') != -1) {
                const chat = JSON.parse(event.dataTransfer.getData('type_side_area_chat'))
                this.$store.commit('scenario/insertChat', { 'chat': chat, 'index': index })
            }
        },
    },
}
</script>

<style scoped>
.title_area {
    width: 100%;
    height: 100%;
    background-color: #eaeaf2;
    overflow: hidden;
    position: relative;
}

.title_area h1 {
    margin-top: 10px;
    margin-left: 20px;
}
.button_list {
    position: absolute;
    top: 20px;
    right: 20px;
}
.title_button {
    background-color: white;
    color: #00bcd4;
    text-decoration:none;
    margin: 0 10px;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 700;
    border-radius: 4px;
}
.title_button:hover {
    color: white;
    background: #00bcd4;
}
</style>