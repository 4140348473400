<template>
  <transition appear :name="status == 'input' ? 'fachatbot_input_email_and_tel' : ''">
    <div class="input_email_and_tel">
      <div class="input_body">
        <div v-if="data['email_display']" class="input_group">
          <p class="title">{{ data.email_subject }}
            <span @click="status == 'edit' ? toggleRequire('email_required') : ''" v-if="data['email_required']"
              class="required">※必須</span>
            <span @click="status == 'edit' ? toggleRequire('email_required') : ''" v-if="!data['email_required']"
              class="optional">任意</span>
          </p>
          <div class="input_box">
            <input ref="email" v-if="status === 'input'" type="email" class="input"
              :placeholder="data.placeholder_email" v-model="email">
            <input ref="email" v-if="status !== 'input'" type="email" class="input" disabled
              :placeholder="data.placeholder_email" v-model="email">
          </div>
          <label v-if="err_email != ''" class="err">{{ err_email }}</label>
        </div>
        <div v-if="data['tel_display']" class="input_group">
          <p class="title">{{ data.tel_subject }}
            <span @click="status == 'edit' ? toggleRequire('tel_required') : ''" v-if="data['tel_required']"
              class="required">※必須</span>
            <span @click="status == 'edit' ? toggleRequire('tel_required') : ''" v-if="!data['tel_required']"
              class="optional">任意</span>
          </p>
          <div class="input_box">
            <input ref="tel" v-if="status === 'input'" type="tel" class="input" :placeholder="data.placeholder_tel"
              v-model="tel">
            <input ref="tel" v-if="status !== 'input'" type="tel" class="input" disabled
              :placeholder="data.placeholder_tel" v-model="tel">
          </div>
          <label v-if="err_tel != ''" class="err">{{ err_tel }}</label>
        </div>
        <div class="next_message">
          <button v-if="status === 'input' && (btn_text == '次へ' || btn_text == '更新')" type="button" class="btn"
            @click="submit">
            {{ btn_text }}
          </button>
          <button v-if="status !== 'input' || btn_text == 'OK'" type="button" class="btn disabled" disabled
            @click="submit">
            {{ btn_text }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'InputEmailAndTel',
  props: ['id', 'data', 'status'],
  components: {
  },
  data() {
    return {
      email: '',
      tel: '',
      err_email: '',
      err_tel: '',
      placeholder_email: '例）example@example.co.jp',
      placeholder_tel: '09012345678',
      btn_text: '次へ',
      mode: 'view',
      edit_data: {},
      result: {},
    }
  },
  watch: {
    email: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    tel: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
  },
  mounted: function () {
    if (this.status == 'input') {
      this.result = this.$store.getters['scenarioPlay/result'](this.id)
    }
    if (typeof this.result !== 'undefined') {
      if (typeof this.result.email !== 'undefined') {
        this.email = this.result.email;
      }
      if (typeof this.result.tel !== 'undefined') {
        this.tel = this.result.tel;
      }
    }

    if (this.status == 'input') {
      this.$nextTick(() => {
        if (this.data.email_display) {
          this.$refs.email.focus()
        } else if (this.data.tel_display) {
          this.$refs.tel.focus()
        }
      })
    }
  },
  methods: {
    afterEnter() {
      this.$emit("afterEnter");
    },
    toggleRequire(require) {
      this.$emit("toggleRequire", require);
    },
    submit() {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.err_email = '';
      this.err_tel = '';
      if (this.data['email_display']) {
        if (this.data['email_required'] && this.email == '') {
          this.err_email = 'こちらは必須項目です。';
        }
        if (this.email != '' && !re.test(this.email)) {
          this.err_email = '有効なメールアドレス形式で指定してください。';
        }
      }
      re = /^[0-9]{3}[0-9]{3,4}[0-9]{3,4}$/;
      if (this.data['tel_display']) {
        if (this.data['tel_required'] && this.tel == '') {
          this.err_tel = 'こちらは必須項目です。';
        }
        if (!re.test(this.tel) && this.tel != '') {
          this.err_tel = '形式​が正しくありません。';
        }
      }
      if (this.err_email == '' && this.err_tel == '') {
        this.btn_text = 'OK';
        let data = { 'email': this.email, 'tel': this.tel };
        this.$emit("submit", this.id, data);
      }
    },
  }
}
</script>

<style scoped>
.input_email_and_tel {
  padding: 3px 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.input_body {
  padding: 10px;
  background: #fcf9ce;
}

.input_group {
  width: 100%;
  word-break: break-word;
  margin-bottom: 10px;
}

.title {
  white-space: pre-line;
  padding: 3px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.input_box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.input {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 100%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
}

.input::placeholder {
  color: #aaa;
}

.next_message {
  text-align: right;
  float: right;
  padding-right: 0px;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  overflow: visible;
}

.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}

button:not(.disabled) {
  -webkit-animation: bounce 2.6s infinite ease-in-out;
  animation: bounce 2.6s infinite ease-in-out;
}

@keyframes bounce {
  5% {
    -webkit-transform: scale(1.2, .8);
  }

  10% {
    -webkit-transform: scale(.8, 1.2) translateY(-5px);
  }

  15% {
    -webkit-transform: scale(1, 1);
  }
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.required {
  color: red;
  font-size: 12px;
}

.optional {
  color: gray;
  font-size: 12px;
}

.err {
  color: red;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.fachatbot_input_email_and_tel-enter-active {
  transition: right .38s .3s ease, opacity .38s .3s ease-in;
}

.fachatbot_input_email_and_tel-enter-to {
  opacity: 1;
  right: 0;
}

.fachatbot_input_email_and_tel-enter-from {
  opacity: 0;
  right: -100px;
}
</style>
