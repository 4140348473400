<template>
    <div class="side_area">
        <!-- <div class="back_to_control" @click="backToHome">
                <span class="material-symbols-outlined back_icon">undo</span><span>戻る</span>
            </div> -->
        <div class="side_title">Menu</div>
        <div class="menu_item" @click="newWithTemplate">
            <span class="menu_icon">＋ </span>
            <span style="font-size: 0.9em;">チャットボット作成（テンプレート）</span>
        </div>
        <div class="menu_item" @click="newScenario">
            <span class="menu_icon">＋ </span>
            <span style="font-size: 0.9em;">チャットボット作成（オリジナル）</span>
        </div>
        <div class="menu_item" @click="toProfileEdit">
            <span>ユーザ情報</span>
        </div>
        <div class="menu_item" @click="toPaymentConfig">
            <span>お支払い設定</span>
        </div>
        <div class="menu_item" @click="toPaymentDetail">
            <span>お支払い明細</span>
        </div>

        <div v-if="admin">
            <hr />
            <div class="side_title">管理者機能</div>
            <div>
                <div class="menu_item" @click.prevent="toConfigPayment">
                    <div>
                        <label>
                            UnivaPayトークン登録
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'SideArea',
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            scenario_dump: 'scenario/dump',
            admin: 'customer/admin',
        }),
    },
    mounted: function () {
    },
    methods: {
        init() {
            this.$store.commit('dummyBackend/init')
        },
        backToHome() {
            this.$router.push({ path: '/' })
        },
        async newScenario() {
            this.$store.commit('scenario/init')
            let s = await Api.scenarioNew(this.$store, this.scenario_dump)
            this.$store.commit('editView/setMenu', { 'menu': 'EditArea' })
            this.$store.commit('scenario/setScenario', s.data)
            this.$store.commit('scenario/setSaved', { "saved": true })
            this.$router.push({ path: '/scenario/edit' })
        },
        async newWithTemplate() {
            this.$store.commit(
                'scenario/setScenarioTemplate',
                Api.getTemplate(0)
            )
            let s = await Api.scenarioNew(this.$store, this.scenario_dump)
            this.$store.commit('editView/setMenu', { 'menu': 'EditArea' })
            this.$store.commit('scenario/setScenario', s.data)
            this.$store.commit('scenario/setSaved', { "saved": true })
            this.$router.push({ path: '/scenario/edit' })
        },
        toProfileEdit() {
            this.$store.commit('customer/setBackTo', this.$route.path)
            this.$router.push('/profile/edit')
        },
        toPaymentConfig() {
            this.$store.commit('customer/setBackTo', this.$route.path)
            this.$router.push('/payment/config')
        },
        toPaymentDetail() {
            this.$store.commit('customer/setBackTo', this.$route.path)
            this.$router.push('/payment/detail')
        },
        toConfigPayment() {
            this.$store.commit('customer/setBackTo', this.$route.path)
            this.$router.push('/admin/paymentconfig')
        },
    },
}
</script>

<style scoped>
.side_area {
    width: 100%;
    height: 100%;
    background-color: #455A64;
    user-select: none;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.side_area h1 {
    color: white;
    margin-top: 20px;
    left: calc(10% - 10px);
}

.edit_window {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #ffb300;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.edit_window-enter-active,
.edit_window-leave-active {
    transition: left .25s ease;
}

.edit_window-enter-to,
.edit_window-leave-from {
    left: 0;
}

.edit_window-enter-from,
.edit_window-leave-to {
    left: -100%;
}

.side_title {
    text-align: center;
    position: relative;
    margin: 0;
    padding: 10px;
    font-size: 2em;
    color: white;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-color: #374850;
}

.menu_item {
    position: relative;
    text-align: left;
    width: 100%;
    left: 0;
    color: white;
    padding: 12px 19px;
    margin: 0;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-color: #374850;
    box-sizing: border-box;
}

.menu_item:hover {
    color: aqua;
}

.back_to_control {
    font-size: 36px;
    text-align: left;
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.material-symbols-outlined {
    padding: 10px;
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

.back_icon {
    font-size: 36px;
}

.menu_icon {
    font-size: 18px;
    padding: 0;
    line-height: 18px;
}
</style>