<template>
    <div class="dialog_area">
        <div class="input_group">
            <div class="alert_box">
                <h2><img src="@/assets/system-icon/warn.png" />注意<img src="@/assets/system-icon/warn.png" /></h2>
                <p>設定しない場合はチャットボット内で申込みが完結します。</p>
                <p>設定する場合はCVタグを完了ページ内に設定してください。</p>
            </div>
        </div>
        <div class="input_box">
            <label>
                <div>完了ページURL</div>
                <div><input type="text" v-model="completeOtherPageUrl" style="width: calc(100% - 120px);"
                        placeholder="https://example.com/complete.html" /></div>
            </label>
        </div>
        <div class="input_box">
            <button class="ok" @click.stop="btnOk">保存する</button>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'dialogComplete',
    props: ['id'],
    components: {
    },
    data() {
        return {
            scenario: {},
            completeOtherPageUrl: '',
        }
    },
    computed: {
        ...mapGetters({
            scenario_id: 'scenario/id',
        })
    },
    created: async function () {
        let response = await Api.scenarioGet(this, this.scenario_id)
        let s = response.data
        this.completeOtherPageUrl = s.completeOtherPageUrl
    },
    mounted: function () {
    },
    methods: {
        btnCancel() {
            this.$emit('submit', { 'method': 'cancel' })
        },
        async btnOk() {
            let response = await Api.updateComplete(this.$store, {
                'id': this.scenario_id,
                'completeOtherPageUrl': this.completeOtherPageUrl,
            })
            if (response.login_status == "LOGGED_IN" && response.status == "OK") {
                alert('申込完了ページ設定を保存しました')
            }
            this.$store.commit('scenario/setCompleteOtherPageUrl', { completeOtherPageUrl: this.completeOtherPageUrl })
            this.$store.commit('scenario/setSaved', { "saved": true })

        },
    },
}
</script>

<style scoped>
.dialog_area {
    position: relative;
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: white;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 3;
    padding: 0;
}

.ok {
    /* position: absolute; */
    right: auto;
    /* left: auto; */
    /* top: calc(max(100%, 400px) - 70px); */
    box-sizing: border-box;
    border: 1px solid #00bcd4;
    line-height: 20px;
    background-color: #00bcd4;
    color: white;
    padding: 5px 20px;
    border-radius: 4px;
    font-weight: 700;
}

.ok:hover {
    line-height: 20px;
    background-color: white;
    color: #00bcd4;
}

.ok:disabled {
    border: 1px solid gray;
    line-height: 20px;
    background-color: #008a9c;
    color: #c0c0c0;
}

.dialog_input {
    width: calc(100% - 46px);
    top: 30px;
    left: 20px;
    height: max(calc(100% - 150px), 250px);
    resize: none;
    font-size: 15px;
    position: absolute;
}

.notice {
    /* top: calc(20px + 180px + max(100%, 400px) - 210px - 74px); */
    position: absolute;
    top: 0;
    left: 20px;
}

.input_group {
    margin-left: 30px;
    margin-right: 30px;
}

.input_box {
    margin-bottom: 30px;
}

.alert_box {
    box-sizing: border-box;
    background-color: #ffb300;
    border-radius: 10px;
    margin: 30px 30px;
    padding: 30px;
    width: calc(100% - 60px);
}

.alert_box>h2 {
    font-size: 30px;
}

.alert_box>h2>img {
    position: relative;
    width: 32px;
    height: 32px;
    top: 5px;
    margin: 0 20px;
}
</style>