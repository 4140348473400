<template>
  <transition appear :name="status == 'input' ? 'fachatbot_select_schedule' : ''">
    <div class="select_schedule">
      <div class="input_body">
        <div class="input_group">
          <p class="title">{{ data.schedule_subject }}
            <span @click="status == 'edit' ? toggleRequire('schedule_required') : ''" v-if="data['schedule_required']"
              class="required">※必須</span>
            <span @click="status == 'edit' ? toggleRequire('schedule_required') : ''" v-if="!data['schedule_required']"
              class="optional">任意</span>
          </p>
          <div class="input_box">
            <select ref="schedule" v-if="status === 'input' || status === 'edit'" class="input" v-model='date'>
              <option value="" :disabled="data.schedule_required">{{ data.placeholder_schedule }}</option>
              <option v-for="s in data.schedules" :key="s.id" :value="s" :disabled="s.full_flg == 1">{{ sprintDate(s) }}
              </option>
            </select>
            <select ref="schedule" v-else class="input" v-model='date' disabled>
              <option value="" :disabled="data.schedule_required">{{ data.placeholder_schedule }}</option>
              <option v-for="s in data.schedules" :key="s.id" :value="s" :disabled="s.full_flg == 1">{{ sprintDate(s) }}
              </option>
            </select>
          </div>
          <label v-if="err_schedule != ''" class="err">{{ err_schedule }}</label>
        </div>
        <div class="next_message">
          <button v-if="status === 'input' && (btn_text == '次へ' || btn_text == '更新')" type="button" class="btn"
            @click="submit">
            {{ btn_text }}
          </button>
          <button v-if="status !== 'input' || btn_text == 'OK'" type="button" class="btn disabled" disabled
            @click="submit">
            {{ btn_text }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SelectSchedule',
  props: ['id', 'data', 'status'],
  components: {
  },
  data() {
    return {
      date: '',
      err_schedule: '',
      btn_text: '次へ',
      mode: 'view',
      edit_data: {},
      result: {},
    }
  },
  watch: {
    date: function () {
      if (this.btn_text == 'OK' && this.status == 'input') this.btn_text = '更新';
    },
  },
  mounted: function () {
    if (this.status == 'input') {
      this.result = this.$store.getters['scenarioPlay/result'](this.id)
    }

    if (typeof this.result !== 'undefined') {
      if (typeof this.result.date !== 'undefined') {
        this.date = this.result.date;
      }
    }
    if (this.status == 'input') {
      this.$nextTick(() => {
        this.$refs.schedule.focus()
      })
    }
  },
  methods: {
    afterEnter() {
      this.$emit("afterEnter");
    },
    toggleRequire(require) {
      this.$emit("toggleRequire", require);
    },
    submit() {
      if (this.data['schedule_required'] && (this.date == '')) {
        this.err_schedule = 'こちらは必須項目です。';
      } else {
        this.err_schedule = '';
      }
      if (this.err_schedule == '') {
        this.btn_text = 'OK';
        let data = { 'date': this.date, 'dow': this.dayObWeek(this.date.year, this.date.month, this.date.day) };
        this.$emit("submit", this.id, data);
      }
    },
    sprintDate(s, data) {
      return (s.year + '/' + s.month + '/' + s.day + '(' + this.dayObWeek(s.year, s.month, s.day) + ') '
        + ('0' + s.start_hour).slice(-2) + ':' + ('0' + s.start_minutes).slice(-2) + ' - '
        + ('0' + s.end_hour).slice(-2) + ':' + ('0' + s.end_minutes).slice(-2))
        + ((s.full_flg) ? (typeof data == "undefined" ? this.data.full_subject : data.full_subject) : '');
    },
    dayObWeek(year, month, day) {
      return ["日", "月", "火", "水", "木", "金", "土"][(new Date(year, month - 1, day)).getDay()]
    },
  }
}
</script>

<style scoped>
.select_schedule {
  padding: 3px 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.input_body {
  padding: 10px;
  background: #fcf9ce;
}

.input_group {
  width: 100%;
  word-break: break-word;
  margin-bottom: 10px;
}

.title {
  white-space: pre-line;
  padding: 3px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.input_box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.input {
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 100%;
  height: 36px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  text-align: -webkit-center;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
  padding: 8px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input::placeholder {
  color: #aaa;
}

.input option:disabled {
  background-color: rgb(173, 173, 173);
}

.next_message {
  text-align: right;
  float: right;
  padding-right: 0px;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  overflow: visible;
}

.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}

button:not(.disabled) {
  -webkit-animation: bounce 2.6s infinite ease-in-out;
  animation: bounce 2.6s infinite ease-in-out;
}

@keyframes bounce {
  5% {
    -webkit-transform: scale(1.2, .8);
  }

  10% {
    -webkit-transform: scale(.8, 1.2) translateY(-5px);
  }

  15% {
    -webkit-transform: scale(1, 1);
  }
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.required {
  color: red;
  font-size: 12px;
}

.optional {
  color: gray;
  font-size: 12px;
}


.err {
  color: red;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.fachatbot_select_schedule-enter-active {
  transition: right .38s .3s ease, opacity .38s .3s ease-in;
}

.fachatbot_select_schedule-enter-to {
  opacity: 1;
  right: 0;
}

.fachatbot_select_schedule-enter-from {
  opacity: 0;
  right: -100px;
}
</style>
