<template>
  <div class="edit_box">
    <div class="title_box">
      <div class="edit_title">日程</div>
      <div class="right">
        <div class="edit_submit" @click="submit">項目を保存</div>
      </div>
    </div>

    <div class="input_group">
      <div class="input_box">
        <input id="schedule_required" type="checkbox" v-model="edit_data.schedule_required" />
        <label for="schedule_required">日程を必須とする</label>

      </div>
      <div class="input_box">
        <label for="schedule_subject">タイトル</label>
        <input id="schedule_subject" type="text" v-model="edit_data.schedule_subject" />
      </div>
      <div class="input_box">
        <label for="placeholder_schedule">未選択時</label>
        <input id="placeholder_schedule" type="text" v-model="edit_data.placeholder_schedule" />
      </div>
      <div class="input_box">
        <label for="full_subject">満員時</label>
        <input id="full_subject" type="text" v-model="edit_data.full_subject" />
      </div>
    </div>

    <div class="subject">日程</div>
    <div class="input_group">
      <div class="input_box" v-if="edit_data.schedules && edit_data.schedules.length == 0">
        日程がありません。
      </div>
      <div class="input_box" :class="{ 'full': s.full_flg }" v-for="(s) in edit_data.schedules" :key="s.key">
        <!-- <div v-if="index > 0" class="schedule_btn schedule_up" @click.stop="moveUp(s.id)">上</div>
        <div v-if="index < edit_data.schedules.length - 1" class="schedule_btn schedule_down"
          @click.stop="moveDown(s.id)">下</div> -->
        <div class="schedule_btn schedule_remove" @click="remove(s.id)">×</div>
        <div><input type="checkbox" v-model="s.full_flg" /> 募集締め切り</div>
        <div>
          <span>{{ s.year + '/' + s.month + '/' + s.day + '(' + dayObWeek(s.year, s.month, s.day) + ') ' }}</span>
          <!-- <div>開始時刻</div> -->
          <span>{{ ('0' + s.start_hour).slice(-2) + ':' + ('0' + s.start_minutes).slice(-2) }}</span> -
          <!-- <div>終了時刻</div> -->
          <span>{{ ('0' + s.end_hour).slice(-2) + ':' + ('0' + s.end_minutes).slice(-2) }}</span>
        </div>
      </div>
    </div>

    <div class="subject">日程追加</div>
    <div class="input_group">
      <div>
        <v-date-picker v-model="date" is-expanded />
      </div>
      <div class="input_box">
        {{ date.getFullYear() }}年{{ date.getMonth() + 1 }}月{{ date.getDate() }}日({{ [...'日月火水木金土'][date.getDay()]
        }})<br />
        <div>
          開始時刻
          <select v-model="startHour">
            <option v-for="h in [...Array(24)].map((_, i) => i)" :key="h" :value="h">{{ ('00' + h).slice(-2) }}</option>
          </select>時
          <select v-model="startMinute">
            <option v-for="h in [...Array(60)].map((_, i) => i)" :key="h" :value="h">{{ ('00' + h).slice(-2) }}</option>
          </select>分
        </div>
        <div>
          終了時刻
          <select v-model="endHour">
            <option v-for="h in [...Array(24)].map((_, i) => i)" :key="h" :value="h">{{ ('00' + h).slice(-2) }}</option>
          </select>時
          <select v-model="endMinute">
            <option v-for="h in [...Array(60)].map((_, i) => i)" :key="h" :value="h">{{ ('00' + h).slice(-2) }}</option>
          </select>分
        </div>
        <button @click="add">追加</button>
      </div>
    </div>

  </div>
</template>

<script>
import 'v-calendar/dist/style.css';
export default {
  name: 'EditSelectSchedule',
  props: ['chat'],
  components: {
  },
  data() {
    return {
      edit_data: {},
      lastKey: 0,
      date: new Date(),
      startHour: 0,
      startMinute: 0,
      endHour: 0,
      endMinute: 0,
    }
  },
  watch: {
  },
  mounted: function () {
    this.edit_data = this.chat.data;
    this.edit_data.schedules.forEach(element => {
      element.key = this.lastKey++
    });
  },
  methods: {
    submit: function () {
      let data = this.edit_data;
      this.$emit("submit", data);
    },
    sprintDate(s) {
      return (s.year + '/' + s.month + '/' + s.day + '(' + this.dayObWeek(s.year, s.month, s.day) + ') '
        + ('0' + s.start_hour).slice(-2) + ':' + ('0' + s.start_minutes).slice(-2) + ' - '
        + ('0' + s.end_hour).slice(-2) + ':' + ('0' + s.end_minutes).slice(-2))
        + ((s.full_flg === '0') ? '' : this.edit_data.full_subject);
    },
    dayObWeek(year, month, day) {
      return ["日", "月", "火", "水", "木", "金", "土"][(new Date(year, month - 1, day)).getDay()]
    },
    moveUp(id) {
      let i = this.edit_data.schedules.findIndex(c => c.id == id);
      if (i > 0) {
        let tmp = this.edit_data.schedules.splice(i, 1);
        this.edit_data.schedules.splice(i - 1, 0, tmp[0]);
      }
    },
    moveDown(id) {
      let i = this.edit_data.schedules.findIndex(c => c.id == id);
      if (i != -1) {
        let tmp = this.edit_data.schedules.splice(i, 1);
        this.edit_data.schedules.splice(i + 1, 0, tmp[0]);
      }
    },
    remove(id) {
      if (window.confirm("削除した要素は戻せません。よろしいですか？")) {
        let i = this.edit_data.schedules.findIndex(c => c.id == id);
        if (i != -1) {
          this.edit_data.schedules.splice(i, 1);
        }
      }
    },
    add: function () {
      let tmp = {
        'id': this.edit_data.lastId++,
        'full_flg': false,
        'year': this.date.getFullYear(),
        'month': this.date.getMonth() + 1,
        'day': this.date.getDate(),
        'start_hour': this.startHour,
        'start_minutes': this.startMinute,
        'end_hour': this.endHour,
        'end_minutes': this.endMinute,
        'key': this.lastKey++,
      };
      this.edit_data.schedules.push(tmp);
      this.edit_data.schedules = this.edit_data.schedules.sort(function (a, b) {
        return (a.year * 100000000 + a.month * 1000000 + a.day * 10000 + a.start_hour * 100 + a.start_minutes < b.year * 100000000 + b.month * 1000000 + b.day * 10000 + b.start_hour * 100 + b.start_minutes) ? -1 : 1;
      })
    },
  }
}
</script>

<style scoped>
.edit_box {
  margin: 20px;
}

.title_box {
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
}

.edit_title {
  position: absolute;
  color: white;
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
}

.right {
  position: absolute;
  height: 30px;
  right: 0;
}

.edit_submit {
  background-color: #42B5D1;
  border-color: #42B5D1;
  color: white;
  line-height: 24px;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 8px;
}

.input_group {
  overflow: hidden;
  border: 0 solid #ffb300;
  text-align: left;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  margin-bottom: 20px;
}

.input_box {
  border: 1px solid #ffb300;
  padding: 10px 10px;
  position: relative;
}

.input_box label {
  margin-bottom: 5px;
}

.input_box input[type="text"] {
  width: calc(100% - 10px);
  font-size: 18px;
}

.full {
  background-color: #B0B0B0;
}

.subject {
  color: white;
}

.schedule_box {
  width: 100%;
}

.schedule_btn {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  font-weight: 700;
  background-color: gray;
  color: white;
}

.schedule_btn:hover {
  background-color: #C0C0C0;
}

.schedule_up {
  top: 10px;
  right: 80px;
}

.schedule_down {
  top: 10px;
  right: 50px;
}

.schedule_remove {
  top: 10px;
  right: 20px;
}
</style>
