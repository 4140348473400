<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  methods: {
    editScenario: function () {
      this.$router.push({ path: '/scenario/edit' })
    },
  },
}

</script>
<style>
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.app {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}

h1 {
  text-align: left;
  position: relative;
  margin: 0;
  padding: 0 0 0 30px;
}

h1::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 46px;
  background-color: #00bcd4;
  left: 0;
}

/* div, span, pre, h1, h2, h3, h4 {
  user-select: none;
} */
</style>
