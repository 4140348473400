export default {
    namespaced: true,
    state: {
        scenarios: [],
        lastId: 0,
    },
    getters: {
        scenario(state) {
            return (id) => {
                let c = state.scenarios.find(c => c.id == id)
                if (c == undefined) { c = [] }
                return JSON.parse(JSON.stringify(c))
            }
        },
        scenarioList(state) {
            let list = []
            state.scenarios.forEach(s => {
                list.push({ id: s.id, name: s.name, status: s.status, length: s.scenario.length })
            });
            return list
            // return state.scenarios.find((s) => {s.id == id})
        },
    },
    mutations: {
        init: function (state, payload) {
            state.scenarios = []
            state.lastId = 0
        },
        //  payload {
        //      id,
        //      status
        //  }
        changeStatus: async function (state, payload) {
            let i = state.scenarios.findIndex(c => c.id == payload.id);
            if (i != -1) {
                state.scenarios[i].status = payload.status
            }
        },
        //  payload: {
        //      fromIndex,
        //      toIndex,
        //  }
        move(state, payload) {
            const deleteList = state.scenarios.splice(payload.fromIndex, 1);
            state.scenarios.splice(payload.toIndex, 0, deleteList[0]);
        },
        //  payload: {
        //      id,
        //  }
        remove(state, payload) {
            let i = state.scenarios.findIndex(c => c.id == payload.id);
            if (i != -1) {
                state.scenarios.splice(i, 1);
            }
        },
        //  payload: {
        //      scenario,
        //      index,
        //  }
        insertScenario(state, payload) {
            payload.scenario.id = state.lastId++
            state.scenarios.splice(payload.index, 0, payload.scenario);
        },
        setThanksMail(state, payload) {
            let i = state.scenarios.findIndex(c => c.id == payload.id);
            state.scenarios[i].thanksMail.sendMail = payload.sendMail
            state.scenarios[i].thanksMail.fromName = payload.fromName
            state.scenarios[i].thanksMail.fromAddress = payload.fromAddress
            state.scenarios[i].thanksMail.subject = payload.subject
            state.scenarios[i].thanksMail.text = payload.text
        },
        setCvTag(state, payload) {
            let i = state.scenarios.findIndex(c => c.id == payload.id);
            state.scenarios[i].cvTag = payload.text
        },
        setName(state, payload) {
            let i = state.scenarios.findIndex(c => c.id == payload.id);
            state.scenarios[i].name = payload.text
        },
    },
    actions: {
        //  scenario
        save: async function ({ state, commit, getters, rootGetters }, scenario) {
            let id
            let i = state.scenarios.findIndex(c => c.id == scenario.id);
            if (i != -1) {
                state.scenarios.splice(i, 1, scenario)
                id = scenario.id
            } else {
                id = scenario.id = state.lastId++
                let i = state.scenarios.length
                state.scenarios.splice(i, 0, scenario);
            }
            return await id
        },
        //  scenario_dump
        saveNew: async function ({ state, commit, getters, rootGetters }, scenario) {
            let id
            id = scenario.id = state.lastId++
            let i = state.scenarios.length
            state.scenarios.splice(i, 0, scenario)
            return await id
        },
    },
}
