<template>
  <transition appear :name="status == 'input' ? 'fachatbot_select_free_radio' : ''">
    <div class="select_free_radio">
      <div class="input_body">
        <div class="input_group">
          <p class="title">{{ data.free_radio_subject }}
            <span @click="status == 'edit' ? toggleRequire('free_radio_required') : ''"
              v-if="data['free_radio_required']" class="required">※必須</span>
            <span @click="status == 'edit' ? toggleRequire('free_radio_required') : ''"
              v-if="!data['free_radio_required']" class="optional">任意</span>
          </p>
          <div class="input_box">
            <div class="input">
              <div v-for="s in data.free_radios" :key="s.id">
                <input ref="radio" :id="'free_radio_' + s.id" type="radio" v-model='date' :value="s"
                  :disabled="s.full_flg == 1" />
                <label :for="'free_radio_' + s.id">{{ s.text }}</label>
              </div>
            </div>
          </div>
          <label v-if="err_free_radio != ''" class="err">{{ err_free_radio }}</label>
        </div>
        <div class="next_message">
          <button v-if="btn_text == '次へ' || btn_text == '更新'" type="button" class="btn" @click="submit">{{
            btn_text
          }}</button>
          <button v-if="btn_text == 'OK'" type="button" class="btn disabled" disabled @click="submit">{{
            btn_text
          }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SelectFreeRadio',
  props: ['id', 'data', 'status'],
  components: {
  },
  data() {
    return {
      date: [],
      err_free_radio: '',
      btn_text: '次へ',
      mode: 'view',
      edit_data: {},
      result: {},
    }
  },
  watch: {
    date: function () {
      if (this.btn_text == 'OK' && this.status == 'input') this.btn_text = '更新';
    },
  },
  mounted: function () {
    if (this.status == 'input') {
      this.result = this.$store.getters['scenarioPlay/result'](this.id)
    }

    if (typeof this.result !== 'undefined') {
      if (typeof this.result.free_radios !== 'undefined') {
        this.date = this.result.free_radios;
      }
    }
    if (this.status == 'input') {
      this.$nextTick(() => {
        this.$refs.radio[0].focus()
      })
    }
  },
  methods: {
    afterEnter() {
      this.$emit("afterEnter");
    },
    toggleRequire(require) {
      this.$emit("toggleRequire", require);
    },
    submit() {
      if (this.data['free_radio_required'] && (this.date == '')) {
        this.err_free_radio = 'こちらは必須項目です。';
      } else {
        this.err_free_radio = '';
      }
      if (this.err_free_radio == '') {
        this.btn_text = 'OK';
        let data = { 'id': this.id, 'free_radios': this.date };
        this.$emit("submit", this.id, data);
      }
    },
  }
}
</script>

<style scoped>
.select_free_radio {
  margin: 3px 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.input_body {
  padding: 10px;
  background: #fcf9ce;
}

.input_group {
  width: 100%;
  word-break: break-word;
  margin-bottom: 10px;
}

.title {
  white-space: pre-line;
  padding: 3px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.input_box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.input {
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 100%;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  text-align: -webkit-center;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
  padding: 8px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input label {
  margin-left: 10px;
  font-size: 18px;
  cursor: pointer;
}

.input input {
  margin-left: 5px;
}

.input::placeholder {
  color: #aaa;
}

.input option:disabled {
  background-color: rgb(173, 173, 173);
}

.next_message {
  text-align: right;
  float: right;
  padding-right: 0px;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  overflow: visible;
}

.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}

button:not(.disabled) {
  -webkit-animation: bounce 2.6s infinite ease-in-out;
  animation: bounce 2.6s infinite ease-in-out;
}

@keyframes bounce {
  5% {
    -webkit-transform: scale(1.2, .8);
  }

  10% {
    -webkit-transform: scale(.8, 1.2) translateY(-5px);
  }

  15% {
    -webkit-transform: scale(1, 1);
  }
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.required {
  color: red;
  font-size: 12px;
}

.optional {
  color: gray;
  font-size: 12px;
}


.err {
  color: red;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.fachatbot_select_free_radio-enter-active {
  transition: right .38s .3s ease, opacity .38s .3s ease-in;
}

.fachatbot_select_free_radio-enter-to {
  opacity: 1;
  right: 0;
}

.fachatbot_select_free_radio-enter-from {
  opacity: 0;
  right: -100px;
}
</style>
