import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import editView from './editView'
import scenario from './scenario'
import scenarioPlay from './scenarioPlay'
import chatbot from './chatbot'
import customer from './customer'
import dummyBackend from './dummyBackend'

export default createStore({
  plugins: [createPersistedState({
    key: 'faChatbotSystem',
    paths: [
      'scenario',
      'scenarioPlay',
      'customer',
      'editView',
    ],
    storage: window.sessionStorage,
  }),
  // createPersistedState({
  //   key: 'faChatbotSystemDummyBackend',
  //   paths: [
  //     'dummyBackend',
  //   ],
  //   storage: window.localStorage,
  // }),
  ],
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    editView,
    scenario,
    scenarioPlay,
    chatbot,
    dummyBackend,
    customer,
  }
})
