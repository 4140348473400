<template>
    <div class="dialog_area">
        <div class="input_group">
            <div class="alert_box">
                <p>チャットボットでお申込み後、通知設定をしたメールアドレスにお申込み情報のメールが届きます。</p>
            </div>
        </div>
        <div class="input_group">
            <div class="input_box">
                <label>
                    <div>通知メールの宛先を追加する</div>
                    <div><input ref="addInput" type="text" v-model="bccAddress" @keypress.enter="add" /><button
                            @click.stop="add">追加</button></div>
                </label>
            </div>
            <div class="input_box">
                <label>
                    <div v-if="bccAddresses.length == 0">通崎メールアドレスが未登録です</div>
                    <table v-else align="center">
                        <tr>
                            <td>メールアドレス</td>
                            <td></td>
                        </tr>
                        <tr v-for="(raw, index) in bccAddresses" :key="index">
                            <td>{{ raw }}</td>
                            <td><button @click.prevent="bccDelete(index)">削除</button></td>
                        </tr>
                    </table>
                </label>
            </div>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'dialogNotification',
    props: ['id'],
    components: {
    },
    data() {
        return {
            scenario: {},
            text: '',
            bccAddress: '',
            bccAddresses: [],
        }
    },
    computed: {
        ...mapGetters({
            scenario_id: 'scenario/id',
        })
    },
    created: async function () {
        let response = await Api.scenarioGet(this, this.scenario_id)
        let s = response.data
        this.bccAddresses = s.notificationAddress
    },
    mounted: function () {
    },
    methods: {
        async add() {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (this.bccAddress == '' || !re.test(this.bccAddress)) {
                alert('有効なメールアドレス形式で指定してください。');
                this.$refs.addInput.focus()
                return
            }

            this.bccAddresses.push(this.bccAddress)
            this.bccAddress = ''
            this.$refs.addInput.focus()

            let response = await Api.updateNotification(this.$store, {
                'id': this.scenario_id,
                'notificationAddress': this.bccAddresses,
            })
            this.$store.commit('scenario/setNotificationAddress', { notificationAddress: this.notificationAddress })
            this.$store.commit('scenario/setSaved', { "saved": true })

        },
        async bccDelete(index) {
            this.bccAddresses.splice(index, 1)
            let response = await Api.updateNotification(this.$store, {
                'id': this.scenario_id,
                'notificationAddress': this.bccAddresses,
            })
            this.$store.commit('scenario/setNotificationAddress', { notificationAddress: this.notificationAddress })
            this.$store.commit('scenario/setSaved', { "saved": true })

        },
        async btnOk() {
            let response = await Api.updateCvTag(this.$store, {
                'id': this.scenario_id,
                'text': this.text,
            })
            if (response.login_status == "LOGGED_IN" && response.status == "OK") {
                alert('広告タグを保存しました')
            }
        },
    },
}
</script>

<style scoped>
.dialog_area {
    position: relative;
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: white;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 3;
    padding: 0;
}

.ok {
    position: absolute;
    right: auto;
    left: auto;
    top: calc(max(100%, 400px) - 70px);
    box-sizing: border-box;
    border: 1px solid #00bcd4;
    line-height: 20px;
    background-color: #00bcd4;
    color: white;
    padding: 5px 20px;
    border-radius: 4px;
    font-weight: 700;
}

.ok:hover {
    line-height: 20px;
    background-color: white;
    color: #00bcd4;
}

.ok:disabled {
    border: 1px solid gray;
    line-height: 20px;
    background-color: #008a9c;
    color: #c0c0c0;
}

.dialog_input {
    width: calc(100% - 46px);
    top: 30px;
    left: 20px;
    height: max(calc(100% - 150px), 250px);
    resize: none;
    font-size: 15px;
    position: absolute;
}

.notice {
    /* top: calc(20px + 180px + max(100%, 400px) - 210px - 74px); */
    position: absolute;
    top: 0;
    left: 20px;
}

.input_group {
    margin-left: 30px;
    margin-right: 30px;
}

.input_box {
    margin-bottom: 30px;
}

table {
    border-spacing: 0;
    border: 1px solid black;
    table-layout: fixed;
}

td {
    border: 1px solid black;
    word-break: break-all;
    padding: 5px;
}
.alert_box {
    box-sizing: border-box;
    color: white;
    background-color: #55b8d2;
    border-radius: 10px;
    margin: 30px 30px;
    padding: 10px 30px;
    width: calc(100% - 60px);
}

.alert_box>h2>img {
    position: relative;
    width: 32px;
    height: 32px;
    top: 5px;
    margin: 0 20px;
}
</style>
