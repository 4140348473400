<template>
  <div class="edit_area">
    <div v-if="mode == 'normal'" class="input_group">
      <select class="invoice_select" v-model="invoiceIndex">
        <option v-for="raw in invoiceData" :value="raw.id" :key="raw.id">月次決済のご請求内容({{ raw.invoice_year }}年{{
          raw.invoice_month }}月)</option>
      </select>
      <div class="invoice" v-if="invoiceIndex != null">
        <table class="detail_table">
          <tr>
            <td></td>
            <td>単価</td>
            <td>件数</td>
            <td>合計</td>
          </tr>
          <tr>
            <td>月額料金</td>
            <td>{{ invoice.contract_fee }} 円</td>
            <td>{{ invoice.contract_quantity }} 件</td>
            <td>{{ invoice.contract_fee * invoice.contract_quantity }} 円</td>
          </tr>
          <tr>
            <td>従量課金</td>
            <td>{{ invoice.ppu_fee }} 円</td>
            <td>{{ invoice.ppu_quantity }} 件</td>
            <td>{{ invoice.ppu_fee * invoice.ppu_quantity }} 円</td>
          </tr>
        </table>
        <table class="other_table">
          <tr>
            <td>ご請求金額の合計</td>
            <td>{{ invoice.contract_fee * invoice.contract_quantity + invoice.ppu_fee * invoice.ppu_quantity }} 円</td>
          </tr>
        </table>
        <table class="other_table">
          <tr>
            <td>ご利用期間</td>
            <td>{{ invoice.period }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
  name: 'EditArea',
  props: [],
  components: {
  },
  data() {
    return {
      login_name: '',
      error_login_name: '',
      name: '',
      error_name: '',
      email: '',
      error_email: '',
      tel: '',
      error_tel: '',
      password: '',
      passwordConfirm: '',
      error_password: '',
      error_passwordConfirm: '',
      notice_password: '',
      passwordVisible: true,
      passwordUpdatable: false,
      error_profile: '',
      notice_profile: '',
      profileSavable: false,
      mode: 'normal',
      cancelDialogCancelAgree: false,
      invoiceIndex: null,
      invoiceData: [],
      invoice: {},
    }
  },
  watch: {
    invoiceIndex: function () {
      this.invoice = this.invoiceData.find(i => i.id == this.invoiceIndex)
    },
  },
  computed: {
    ...mapGetters({
      customer_dump: 'customer/dump',
    }),
  },
  beforeMount: async function () {
  },
  mounted: async function () {
    this.invoiceData = [
      { id: 0, invoice_year: 2023, invoice_month: 2, title: '月次決済のご請求内容', contract_fee: 980, contract_quantity: 1, ppu_fee: 1100, ppu_quantity: 30, period: '2023年2月1日～2023年2月28日' },
      { id: 1, invoice_year: 2023, invoice_month: 1, title: '月次決済のご請求内容', contract_fee: 980, contract_quantity: 1, ppu_fee: 1100, ppu_quantity: 25, period: '2023年1月1日～2023年1月31日' },
      { id: 2, invoice_year: 2022, invoice_month: 12, title: '月次決済のご請求内容', contract_fee: 980, contract_quantity: 1, ppu_fee: 1100, ppu_quantity: 14, period: '2022年12月1日～2023年12月31日' },
      { id: 3, invoice_year: 2022, invoice_month: 11, title: '月次決済のご請求内容', contract_fee: 980, contract_quantity: 1, ppu_fee: 1100, ppu_quantity: 55, period: '2022年11月1日～2023年11月30日' },
      { id: 4, invoice_year: 2022, invoice_month: 10, title: '月次決済のご請求内容', contract_fee: 980, contract_quantity: 1, ppu_fee: 1100, ppu_quantity: 12, period: '2022年10月1日～2023年10月31日' },
    ]
    this.invoiceIndex = 0
  },
  methods: {
  },
}
</script>

<style scoped>
.edit_area {
  width: 100%;
  height: 100%;
  background-color: #f6f6fa;
  overflow: auto;
  position: relative;
}

.input_group {
  text-align: left;
  width: calc(100% - 100px - 60px - 2px);
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: 50px;
  border: 1px solid gray;
  padding: 30px;
  border-radius: 20px;
  background-color: white;
}

.input_group>h2 {
  background-color: #455A64;
  color: white;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 15px;
  margin-top: 0;
}

.notice {
  font-size: 0.8em;
  font-weight: 700;
}

.error {
  color: red;
}

.input_box {
  margin-bottom: 10px;
}

.input_box>label {
  /* margin-left: 15px; */
  width: 100%;
  display: inline-block;
}

.input_box input[type="text"],
.input_box input[type="password"],
.input_box input[type="text"]:focus,
.input_box input[type="password"]:focus {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  font-size: 18px;
  padding: 1px 10px;
  line-height: 30px;
  border: none;
  border-radius: 4px;
  outline: 1px gray solid;
}

.password {
  width: 300px !important;
}

.password_label {
  display: inline-block;
  width: 150px !important;
}

.input_box button {
  box-sizing: border-box;
  border: 1px solid #00bcd4;
  line-height: 20px;
  display: block;
  background-color: #00bcd4;
  color: white;
  padding: 5px 20px;
  border-radius: 4px;
  font-weight: 700;
}

.input_box button:hover {
  line-height: 20px;
  display: block;
  background-color: white;
  color: #00bcd4;
}

/* .input_box button:disabled {
  border: 1px solid gray;
  line-height: 20px;
  display: block;
  background-color: #008a9c;
  color: #c0c0c0;
} */
.hiddenname {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  display: block !important;
  outline: none !important;
}

.cancel_box {
  text-align: left;
  width: calc(100% - 100px - 2px);
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 1.2em;
}

.cancel_dialog_return {
  font-size: 24px;
}

.config_alert {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
}

.config_alert>a {
  box-sizing: border-box;
  display: block;
  background: #00bcd4;
  color: white;
  text-decoration: none;
  margin: 30px 30px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 700;
  border-radius: 4px;
  width: calc(100% - 60px);
}

.alert_box {
  box-sizing: border-box;
  background-color: #ffb300;
  margin: 30px 30px;
  padding: 30px;
  width: calc(100% - 60px);
}

.alert_box>h2 {
  font-size: 30px;
}

.alert_box>p {
  font-size: 24px;
  font-weight: 700;
}

.alert_box>h2>img {
  position: relative;
  width: 32px;
  height: 32px;
  top: 5px;
  margin: 0 20px;
}

.alert_check_box {
  margin-bottom: 30px;
  font-size: 20px;
}

.alert_ok {
  box-sizing: border-box;
  border: 1px solid black;
  line-height: 32px;
  font-size: 24px;
  background-color: black;
  color: white;
  padding: 8px 30px;
  border-radius: 4px;
  font-weight: 700;
  margin-bottom: 30px;
}

.invoice {
  width: 100%;
  margin-top: 30px;
}

.invoice>table {
  width: min(100%, 800px);
  margin-bottom: 30px;
  border-spacing: 0;
}

.invoice>table>tr>td {
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  padding: 10px;
}

.invoice>table>tr>td:nth-child(1) {
  background-color: #d8f2f7;
  width: 25%;
}

.invoice>table>tr>td:not(:nth-child(1)) {
  text-align: center;
}

.detail_table>tr:nth-child(1)>td:nth-child(1) {
  background-color: inherit;
}

.other_table {
  border-top: solid 1px gray;
  border-left: solid 1px gray;
}

.invoice_select {
  width: 500px;
  font-size: 20px;
}</style>