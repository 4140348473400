<template>
  <div class="edit_box">
    <div class="title_box">
      <div class="edit_title">メール・電話番号</div>
      <div class="right">
        <div class="edit_submit" @click="submit">項目を保存</div>
      </div>
    </div>
    <div class="subject">表示設定</div>
    <div class="input_group">
      <div class="input_box">
        <input id="email_display" type="checkbox" v-model="edit_data.email_display" />
        <label for="email_display">メールを表示</label>
      </div>
      <div class="input_box">
        <input id="tel_display" type="checkbox" v-model="edit_data.tel_display" />
        <label for="tel_display">電話番号を表示</label>
      </div>
    </div>

    <div v-if="edit_data.email_display">
      <div class="subject">メール</div>
      <div class="input_group">
        <div class="input_box">
          <input id="email_required" type="checkbox" v-model="edit_data.email_required" />
          <label for="email_required">メールを必須とする</label>
          
        </div>
        <div class="input_box">
          <label for="email_subject">タイトル</label>
          <input id="email_subject" type="text" v-model="edit_data.email_subject" />
        </div>
        <div class="input_box">
          <label for="placeholder_email">入力例</label>
          <input id="placeholder_email" type="text" v-model="edit_data.placeholder_email" />
        </div>
      </div>
    </div>

    <div v-if="edit_data.tel_display">
      <div class="subject">メール</div>
      <div class="input_group">
        <div class="input_box">
          <input id="tel_required" type="checkbox" v-model="edit_data.tel_required" />
          <label for="tel_required">電話番号を必須とする</label>
          
        </div>
        <div class="input_box">
          <label for="tel_subject">タイトル</label>
          <input id="tel_subject" type="text" v-model="edit_data.tel_subject" />
        </div>
        <div class="input_box">
          <label for="placeholder_tel">入力例</label>
          <input id="placeholder_tel" type="text" v-model="edit_data.placeholder_tel" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditEmailAndTel',
  props: ['chat'],
  components: {
  },
  data() {
    return {
      edit_data: {},
    }
  },
  watch: {
  },
  mounted: function () {
    this.edit_data = this.chat.data;
  },
  methods: {
    submit: function () {
      let data = this.edit_data;
      this.$emit("submit", data);
    },
  }
}
</script>

<style scoped>
.edit_box {
  margin: 20px;
}

.title_box {
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
}

.edit_title {
  position: absolute;
  color: white;
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
}

.right {
  position: absolute;
  height: 30px;
  right: 0;
}

.edit_submit {
  background-color: #42B5D1;
  border-color: #42B5D1;
  color: white;
  line-height: 24px;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 8px;
}

.input_group {
  overflow: hidden;
  border: 0 solid #ffb300;
  text-align: left;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  margin-bottom: 20px;
}

.input_box {
  border: 1px solid #ffb300;
  padding: 10px 5px;
  position: relative;
}

.input_box label {
  margin-bottom: 5px;
}

.input_box input[type="text"] {
  width: calc(100% - 10px);
  font-size: 18px;
}

.subject {
  color: white;
}
</style>
