<template>
    <div class="profile_area">
        <!-- <a :href="manualLinkUrl" target="_blank"><div class="manualLink">マニュアル</div></a> -->
        <!-- <ul tabindex="0" class="profile_menu" @click="open" @blur="close"> -->
        <div class="profile">
            <div class="profile_name">{{ customer_name }} 様<span v-if="admin">(管理者アカウント)</span> <img src="@/assets/system-icon/arrow_down.png" class="down_icon" /></div>
            <ul tabindex="0" class="profile_menu">
                <li>
                    <div @click.stop="logout"><span class="material-symbols-outlined list_icon" >reply</span>ログアウト</div>
                </li>
            </ul>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'ProfileArea',
    props: [],
    components: {
    },
    data() {
        return {
            apiResponse: null,
            menuOpen: false,
            function: 'toProfileEdit',
            manualLinkUrl: '',
        }
    },
    computed: {
        ...mapGetters({
            customer_id: 'customer/customer_id',
            login_name: 'customer/login_name',
            customer_name: 'customer/name',
            admin: 'customer/admin',
            editMenu: 'editView/menu',
        })
    },
    mounted: function () {
        this.manualLinkUrl = 'https://webinar-master.com/webinartatsujin/manual/'
    },
    methods: {
        async logout() {
            this.apiResponse = await Api.logout(this)
        },
        open() {
            this.menuOpen = !this.menuOpen
        },
        close() {
            this.menuOpen = false
        },
        toProfileEdit() {
            this.$store.commit('customer/setBackTo', this.$route.path)
            this.$router.push('/profile/edit')
        },
    },
}
</script>

<style scoped>
.profile_area {
    box-sizing: content-box;
    position: absolute;
    top: 0;
    right: 0;
    /* overflow: hidden; */
    margin: 10px 20px;
    z-index: 10;
}

.manualLink {
    display: inline-block;
    margin-top: 7px;
    margin-right: 30px;
    padding: 10px 20px;
    vertical-align: top;
    border: none;
    background-color: blue;
    color: white;
    border-radius: 5px;
}

.profile {
    /* border: 1px solid black; */
}

.down_icon {
    position: relative;
    top: 2px;
    width: 16px;
    height: 16px;
    filter: invert(51%) sepia(15%) saturate(6119%) hue-rotate(151deg) brightness(102%) contrast(101%);
}
.profile_name {
    margin-right: 20px;
    text-align: right;
    right: 0;
    top: 0;
    padding: 0;
    color: #00bcd4;
    font-weight: 700;
}
.profile_menu {
    padding: 0;
    display: inline-block;
    display: none;
    border-radius: 8px;
    margin: 20px 0 0 0;
}

.profile:hover>.profile_menu {
    display: list-item;
    background-color: white;
}
.profile_menu>li {
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0);
    position: relative;
    right: 0;
    top: 0;
    padding: 3px 10px;
    font-weight: 700;
}

.profile_menu>li:hover {
    color: #00bcd4;
}

.list_icon {
    position: relative;
    top: 4px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.profile_menu>li:hover .list_icon {
    filter: invert(51%) sepia(15%) saturate(6119%) hue-rotate(151deg) brightness(102%) contrast(101%);
}
.disable {
    background-color: gray;
}


.profile_menu {
    list-style: none;
}
</style>