<template>
  <div class="edit_area">
    <div v-if="mode == 'normal'" class="input_group">
      <h2>UnivaPayトークンの入力</h2>
      <div class="input_box">
        <label>
          トークン
          <input type="text" v-model="token">
        </label>
      </div>
      <div class="input_box">
        <label>
          シークレット
          <input type="text" v-model="secret">
        </label>
      </div>
      <div class="input_box">
        <div class="input_box error">{{ error_profile }}</div>
        <button v-if="paymentSavable" @click="updatePayment">保存する</button>
        <button v-else disabled @click="updatePayment">保存する</button>
      </div>
      <div v-if="progress == 'in_progress'" class="in_progress">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
  name: 'EditArea',
  props: [],
  components: {
  },
  data() {
    return {
      mode: 'none',
      formVisible: false,
      payment_method: '',
      payment_token: '',
      paymentSavable: true,
      progress: '',
      secret: '',
      token: '',
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      customer_dump: 'customer/dump',
    }),
  },
  beforeMount: async function () {
  },
  mounted: async function () {
    let response = await Api.getPaymentInfo(this)
    this.token = response.data.payment_info.token
    this.secret = response.data.payment_info.secret
    this.mode = 'normal'
  },
  methods: {
    updatePayment: async function () {
      this.paymentSavable = false
      this.progress = 'in_progress'
      this.$nextTick(async function () {
        let response = await Api.updatePaymentInfo(this, {
          payment_method: 'UnivaPay',
          payment_info: {
            token: this.token,
            secret: this.secret,
          },
        })
        this.progress = ''
        this.paymentSavable = true
      })
    },
  },
}
</script>

<style scoped>
.edit_area {
  width: 100%;
  height: 100%;
  background-color: #f6f6fa;
  overflow: auto;
  position: relative;
}

.input_group {
  text-align: left;
  width: calc(100% - 100px - 60px - 2px);
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: 50px;
  border: 1px solid gray;
  padding: 30px;
  border-radius: 20px;
  background-color: white;
}

.input_group>h2 {
  background-color: #455A64;
  color: white;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 15px;
  margin-top: 0;
}

.notice {
  font-size: 0.8em;
  font-weight: 700;
}

.error {
  color: red;
}

.input_box {
  margin-bottom: 10px;
}

.input_box>label {
  /* margin-left: 15px; */
  width: 100%;
  display: inline-block;
}

.input_box input[type="text"],
.input_box input[type="password"],
.input_box input[type="text"]:focus,
.input_box input[type="password"]:focus {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  font-size: 18px;
  padding: 1px 10px;
  line-height: 30px;
  border: none;
  border-radius: 4px;
  outline: 1px gray solid;
}

.password {
  width: 300px !important;
}

.password_label {
  display: inline-block;
  width: 150px !important;
}

.input_box button {
  box-sizing: border-box;
  border: 1px solid #00bcd4;
  line-height: 20px;
  display: block;
  background-color: #00bcd4;
  color: white;
  padding: 5px 20px;
  border-radius: 4px;
  font-weight: 700;
}

.input_box button:hover {
  line-height: 20px;
  display: block;
  background-color: white;
  color: #00bcd4;
}

/* .input_box button:disabled {
  border: 1px solid gray;
  line-height: 20px;
  display: block;
  background-color: #008a9c;
  color: #c0c0c0;
} */
.hiddenname {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  display: block !important;
  outline: none !important;
}

.cancel_box {
  text-align: left;
  width: calc(100% - 100px - 2px);
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 1.2em;
}

.cancel_dialog_return {
  font-size: 24px;
}

.config_alert {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
}

.config_alert>a {
  box-sizing: border-box;
  display: block;
  background: #00bcd4;
  color: white;
  text-decoration: none;
  margin: 30px 30px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 700;
  border-radius: 4px;
  width: calc(100% - 60px);
}

.alert_box {
  box-sizing: border-box;
  background-color: #ffb300;
  margin: 30px 30px;
  padding: 30px;
  width: calc(100% - 60px);
}

.alert_box>h2 {
  font-size: 30px;
}

.alert_box>p {
  font-size: 24px;
  font-weight: 700;
}

.alert_box>h2>img {
  position: relative;
  width: 32px;
  height: 32px;
  top: 5px;
  margin: 0 20px;
}

.alert_check_box {
  margin-bottom: 30px;
  font-size: 20px;
}

.alert_ok {
  box-sizing: border-box;
  border: 1px solid black;
  line-height: 32px;
  font-size: 24px;
  background-color: black;
  color: white;
  padding: 8px 30px;
  border-radius: 4px;
  font-weight: 700;
  margin-bottom: 30px;
}

.invoice {
  width: 100%;
  margin-top: 30px;
}

.invoice>table {
  width: min(100%, 800px);
  margin-bottom: 30px;
  border-spacing: 0;
}

.invoice>table>tr>td {
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  padding: 10px;
}

.invoice>table>tr>td:nth-child(1) {
  background-color: #d8f2f7;
  width: 25%;
}

.invoice>table>tr>td:not(:nth-child(1)) {
  text-align: center;
}

.detail_table>tr:nth-child(1)>td:nth-child(1) {
  background-color: inherit;
}

.other_table {
  border-top: solid 1px gray;
  border-left: solid 1px gray;
}

.invoice_select {
  width: 500px;
  font-size: 20px;
}

h2 {
  background-color: #88889e !important;
}

.loader {
  color: #fa9532;
  font-size: 10px;
  margin: 30px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  top: 150px;
  left: 150px;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
</style>