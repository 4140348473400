<template>
    <div class="dialog_area">
        <h1>自動返信メール設定</h1>
        <label class="dialog_sendmail_label">
            <input class="dialog_sendmail" type="checkbox" v-model="sendMail" />自動返信メールを送信する
        </label>
        <label class="dialog_from_name_label">送信者名</label>
        <input class="dialog_from_name" type="text" v-model="fromName" />
        <label class="dialog_from_address_label">送信者メールアドレス</label>
        <input class="dialog_from_address" type="text" v-model="fromAddress" />
        <label class="dialog_subject_label">件名</label>
        <input class="dialog_subject" type="text" v-model="subject" />
        <label class="dialog_input_label">本文</label>
        <div class="macro">
            <label class="macros_label">タグ挿入：
                <select v-model="selectedMacro" ref="macro">
                    <option v-for="(m, index) in macros" :key="index" :value="'[' + m + ']'">[{{ m }}]</option>
                </select>
            </label>
            <button class="btn_insert_macro" @click="insertMacro">追加</button>
        </div>
        <textarea class="dialog_input" v-model="text" ref="text"></textarea>
        <label class="notice">※迷惑メール回避のためSPF設定が必要です。送信者メールアドレスのドメインのDNSのTXTレコードに「v=spf1 ip4:157.112.176.11」を追加してください。</label>
        <button class="btn cancel" @click.stop="btnCancel">キャンセル</button>
        <button class="btn ok" @click.stop="btnOk">OK</button>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'dialogThanksMail',
    props: ['id'],
    components: {
    },
    data() {
        return {
            sendMail: false,
            fromName: '',
            fromAddress: '',
            subject: '',
            scenario: {},
            text: '',
            macros: [],
            selectedMacro: '',
        }
    },
    computed: {
        ...mapGetters({
        })
    },
    created: async function () {
        let response = await Api.scenarioGet(this, this.id)
        let s = response.data

        this.sendMail = s.thanksMail.sendMail
        this.fromName = s.thanksMail.fromName
        this.fromAddress = s.thanksMail.fromAddress
        this.subject = s.thanksMail.subject
        this.text = s.thanksMail.text
        let m = {}
        s.scenario.forEach(c => {
            switch (c.type) {
                case 'input_name':
                    if (c.data.company_display) {
                        m[c.data.company_subject] = 1
                    }
                    if (c.data.name_display) {
                        m[c.data.name_subject] = 1
                    }
                    if (c.data.kana_display) {
                        m[c.data.kana_subject] = 1
                    }
                    break
                case 'input_email_and_tel':
                    if (c.data.email_display) {
                        m[c.data.email_subject] = 1
                    }
                    if (c.data.tel_display) {
                        m[c.data.tel_subject] = 1
                    }
                    break
                case 'select_schedule':
                    m[c.data.schedule_subject] = 1
                    break
                case 'input_free_input':
                    m[c.data.free_input_subject] = 1
                    break
                case 'input_free_text':
                    m[c.data.free_text_subject] = 1
                    break
                case 'select_free_select':
                    m[c.data.free_select_subject] = 1
                    break
                case 'select_free_radio':
                    m[c.data.free_radio_subject] = 1
                    break
                case 'select_free_checkbox':
                    m[c.data.free_checkbox_subject] = 1
                    break
                case 'select_free_date':
                    m[c.data.free_date_subject] = 1
                    break
                case 'input_agreement':
                    m[c.data.agreement_subject] = 1
                    break
                default:
                    break
            }
        });
        this.macros = Object.keys(m)
    },

    mounted: async function () {
    },
    methods: {
        btnCancel() {
            this.$emit('submit', { 'method': 'cancel' })
        },
        async btnOk() {
            let response = await Api.updateThanksMail(this.$store, {
                'id': this.id,
                'sendMail': this.sendMail,
                'fromName': this.fromName,
                'fromAddress': this.fromAddress,
                'subject': this.subject,
                'text': this.text,
            })
            this.$emit('submit', { 'method': 'ok' })
        },
        insertMacro() {
            let start = this.$refs.text.selectionStart
            this.text = this.$refs.text.value.substr(0, start)
                + this.selectedMacro
                + this.$refs.text.value.substr(start);
            this.$refs.text.focus()
            this.$nextTick(() => {
                this.$refs.text.selectionStart = this.$refs.text.selectionEnd = start + this.selectedMacro.length
            })
        },
    },
}
</script>

<style scoped>
.dialog_area {
    position: fixed;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    min-width: 800px;
    top: 30px;
    left: 30px;
    background-color: #f6f6fa;
    overflow: auto;
    z-index: 3;
    padding: 0;
}

.dialog_area h1 {
    position: absolute;
    top: 20px;
    left: 20px;
}

.dialog_area label,
.dialog_area input[type="text"],
.dialog_area textarea {
    width: calc(100% - 46px);
    left: 20px;
    resize: none;
    font-size: 15px;
    position: absolute;
    text-align: left;
}

.btn {
    min-width: 90px;
    width: auto;
    min-height: 42px;
    visibility: visible;
    box-sizing: content-box;
    border: none;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    outline: 0;
    color: #ffffff;
    background-color: #fa9352;
    padding: 0px 14px 0px 14px;
    border-radius: 25px;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    background-image: none;
    overflow: visible;
}

.cancel {
    background-color: #f6f6fa;
    color: black;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    position: absolute;
    right: 148px;
    bottom: 15px;
}

.cancel:hover {
    background-color: #ffffff;
}

.ok {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.ok:hover {
    background-color: #ffa56d;
}


.dialog_sendmail_label {
    top: calc(20px + 60px);
}

.dialog_sendmail {
    top: 0;
}

.dialog_from_name_label {
    top: calc(20px + 80px);
}

.dialog_from_name {
    top: calc(20px + 100px);
}

.dialog_from_address_label {
    top: calc(20px + 120px);
}

.dialog_from_address {
    top: calc(20px + 140px);
}

.dialog_subject_label {
    top: calc(20px + 160px);
}

.dialog_subject {
    top: calc(20px + 180px);
}

.dialog_input_label {
    top: calc(20px + 200px);
}

.macro {
    position: absolute;
    top: calc(20px + 230px);
    width: 320px;
}

.dialog_input {
    top: calc(20px + 260px);
    height: calc(100% - 300px - 74px);
}

.notice {
    top: calc(20px + 180px + 100% - 210px - 74px);
}

.btn_insert_macro {
    position: absolute;
    right: 0;
}
</style>