<template>
    <div class="title_area">
        <h1>チャットボット編集</h1>
        <div class="button_list">
            <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/" target="_blank">マニュアル</a>
            <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/syoki_daikou/"
                target="_blank">初期設定代行サービス</a>
            <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/contact/"
                target="_blank">お問い合せ</a>
        </div>
        <div style="display:none">{{ scenario_id }}</div>
        <div class="scenario_name">
            <p @click="clickStatus(scenario_status)" :class="'status ' + scenario_status">{{strStatus(scenario_status)}}</p>
            <p v-if="name_mode == 'view'" class="scenario_name_div" @click="toEditName" title="変更する">{{ this.name }}</p>
            <input v-if="name_mode != 'view'" class="scenario_name_input" ref="scenario_name_input" @blur="toViewName"
                type="text" v-model="name" @keypress.enter="toViewName" />
        </div>
    </div>
</template>
  
<script>
import component_wrapper from '@/components/scenario/edit/component_wrapper.vue'
import header_base from '@/components/chat/components/header_base.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Api from '@/lib/Api.js'

const componentLabel = {
    'operator_talk': 'オペレータートーク',
    'input_name': '会社名・姓名',
    'input_email_and_tel': 'メール・Tel',
    'select_schedule': 'スケジュール選択',
    'confirm': '確認',
    'complete': '完了',
}

export default {
    name: 'TitleArea',
    props: [],
    components: {
        component_wrapper,
        header_base,
    },
    data() {
        return {
            mode: 'view',
            title: '',
            name: '',
            name_mode: 'view',
            status: '',
        }
    },
    computed: {
        ...mapGetters({
            scenario: 'scenario/scenario',
            scenario_title: 'scenario/title',
            scenario_name: 'scenario/name',
            scenario_id: 'scenario/id',
            scenario_dump: 'scenario/dump',
            scenario_status: 'scenario/status',
            scenario_saved: 'scenario/saved',
        }),
    },
    watch: {
        scenario_name: function () {
            this.name = this.scenario_name
        }
    },
    mounted: function () {
        this.name = this.scenario_name
        this.$store.watch(
            (state, getters) => getters['editView/status'],
            (newValue, oldValue) => {
                this.mode = newValue;
            }
        )
    },
    methods: {
        ...mapMutations({
            scenario_setStatus: 'scenario/setStatus'
        }),
        ...mapActions({
            // scenario_save: 'dummyBackend/save',
            // scenario_new: 'dummyBackend/saveNew',
        }),
        toEditName() {
            this.name_mode = 'edit'
            this.$nextTick(() => {
                this.$refs.scenario_name_input.focus()
            })
        },
        async toViewName() {
            this.name_mode = 'view'
            this.$store.commit('scenario/setName', { name: this.name })
            let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
            this.$store.commit('scenario/setScenario', s.data)
            this.$store.commit('scenario/setSaved', { "saved": true })
        },
        async save() {
            let s
            if (this.scenario_id == null) {
                s = await Api.scenarioNew(this.$store, this.scenario_dump)
            } else {
                s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
            }
            this.$store.commit('scenario/setScenario', s.data)
            this.$store.commit('scenario/setSaved', { "saved": true })
        },
        // async saveNew() {
        //     let id
        //     console.log(this.scenario_dump)
        //     id = await this.scenario_new(JSON.parse(JSON.stringify(this.scenario_dump)))
        //     this.$store.commit('scenario/setId', { "id": id })
        //     this.$store.commit('scenario/setSaved', { "saved": true })
        // },
        mouseOutAction() {
            this.$store.state.scenarioEditView_hoverId = null;
        },
        otherEditCancel() {
            if (this.mode == 'edit') {
                this.mode = 'view';
                this.$store.commit('editView/setStatus', { 'status': 'view' })
                this.$store.commit('editView/setHoverId', { 'hoverId': null })
            }
        },
        strStatus(status) {
            const st = {
                'draft': '非公開',
                'publish': '公開中',
            }
            return st[status] == undefined ? '不明' : st[status]
        },
        async clickStatus(status) {
            let nextStatus = ''
            switch (status) {
                case 'draft':
                    nextStatus = 'publish'
                    break
                case 'publish':
                    nextStatus = 'draft'
                    break
                default:
                    nextStatus = 'draft'
                    break
            }
            this.scenario_setStatus({ 'status': nextStatus })
            let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
            this.$store.commit('scenario/setScenario', s.data)
            this.$store.commit('scenario/setSaved', { "saved": true })
        },
        toPreview(id) {
            let s = this.scenario_dump
            this.$store.commit('scenarioPlay/setScenario', s)
            this.$store.commit('scenarioPlay/setBackTo', '/scenario/edit')
            this.$router.push({ path: '/scenario/preview' })
        },
    },
}
</script>

<style scoped>
.title_area {
    width: 100%;
    height: 100%;
    background-color: #eaeaf0;
    overflow: hidden;
    position: relative;
}

.title_area h1 {
    margin-top: 10px;
    margin-left: 20px;
}

.scenario_name {
    height: 36px;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
}

.scenario_name label {
    font-size: 18px;
}

.scenario_name_div,
.scenario_name_input {
    display: inline-block;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    height: 36px;
    margin: 0;
    /* margin-right: 20%; */
    font-size: 20px;
    line-height: 36px;
    padding: 2px;
    vertical-align: middle;
}

.scenario_name_div {
    border: 1px solid rgba(0, 0, 0, 0);
}

.scenario_name_div:hover,
.scenario_name_input,
.scenario_name_input:focus {
    border: 1px solid black !important;
    border-radius: 0;
    outline: none;
}

.status {
    display: inline-block;
    text-align: center;
    width: 80px;
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
    left: 20px;
    font-weight: 700;
    margin: 0 10px;
}

.publish {
    background-color: #ffc107;
    color: white;
}

.draft {
    background-color: gray;
    color: white;
}

.previewButton {
    position: absolute;
    width: 200px;
    left: calc((100% - 200px) / 2);
    bottom: 5px;
    height: 45px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    color: white;
    background-color: rgb(93, 124, 211);
}

.button_list {
    position: absolute;
    top: 20px;
    right: 20px;
}

.title_button {
    background-color: white;
    color: #00bcd4;
    text-decoration: none;
    margin: 0 10px;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 700;
    border-radius: 4px;
}

.title_button:hover {
    color: white;
    background: #00bcd4;
}
</style>