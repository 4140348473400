<template>
    <div class="edit_area" @mouseenter="mouseOutAction">
        <div v-if="mode == 'edit'" class="fachatbot_chatarea_wrapper_other_edit_mask" @click="otherEditCancel"></div>
        <div class="fachatbot_header_area">
            <header_base status="edit"></header_base>
        </div>
        <div class="fachatbot_chat_area" id="fachatbot_chat_area">
            <div v-if="mode == 'edit'" class="fachatbot_chatarea_wrapper_other_edit_mask" @click="otherEditCancel">
            </div>
            <TransitionGroup name="chat">
                <div class="chat-item" :key="c.key" v-for="(c, index) in scenario" draggable="true"
                    @dragstart="dragList($event, index)" @drop="dropList($event, index)"
                    @dragover="dragoverList($event, index)" @dragenter="dragenterList($event, index)">
                    <div class="insert_box">
                        <div v-if="c.type != 'complete'" class="insert_target"
                            :class="mode == 'dragNewItem' ? 'insert_target_enable' : ''" @drop="dropAdd($event, index)"
                            @dragover="dragoverAdd($event, index)" @dragenter="dragenterAdd($event, index)"></div>
                        <div v-if="mode == 'edit'" class="fachatbot_chatarea_wrapper_other_edit_mask"
                            @click="otherEditCancel"></div>
                    </div>
                    <component_wrapper :id="c.id" :index="index" :chat='c'> </component_wrapper>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>
  
<script>
import component_wrapper from '@/components/scenario/edit/component_wrapper.vue'
import header_base from '@/components/chat/components/header_base.vue'
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

const componentLabel = {
    'operator_talk': 'オペレータートーク',
    'input_name': '会社名・姓名',
    'input_email_and_tel': 'メール・Tel',
    'select_schedule': 'スケジュール選択',
    'confirm': '確認',
    'complete': '完了',
}

export default {
    name: 'EditArea',
    props: [],
    components: {
        component_wrapper,
        header_base,
    },
    data() {
        return {
            mode: 'view',
        }
    },
    computed: {
        ...mapGetters({
            scenario: 'scenario/scenario',
            scenario_dump: 'scenario/dump',
            title: 'scenario/title',
        })
    },
    mounted: function () {
        this.$store.watch(
            (state, getters) => getters['editView/status'],
            (newValue, oldValue) => {
                this.mode = newValue;
            }
        )
    },
    methods: {
        mouseOutAction() {
            this.$store.state.scenarioEditView_hoverId = null;
        },
        otherEditCancel() {
            if (this.mode == 'edit') {
                this.mode = 'view';
                this.$store.commit('editView/setStatus', { 'status': 'view' })
                this.$store.commit('editView/setHoverId', { 'hoverId': null })
            }
        },
        dragList(event, dragIndex) {
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.setData('drag-index', dragIndex)
            event.dataTransfer.setData('type_component_wrapper', 'component_wrapper')
        },
        dragoverList(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_component_wrapper') != -1 && this.scenario[dropIndex].type != 'confirm' && this.scenario[dropIndex].type != 'complete') {
                event.preventDefault()
            }
        },
        dragenterList(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_component_wrapper') != -1 && this.scenario[dropIndex].type != 'confirm' && this.scenario[dropIndex].type != 'complete') {
                event.preventDefault()
            }
        },
        async dropList(event, dropIndex) {
            const dragIndex = event.dataTransfer.getData('drag-index')
            const types = event.dataTransfer.types
            if (types.indexOf('type_component_wrapper') != -1) {
                this.$store.commit('scenario/move', { 'fromIndex': dragIndex, 'toIndex': dropIndex })
                let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
                this.$store.commit('scenario/setScenario', s.data)
                this.$store.commit('scenario/setSaved', { "saved": true })
            }
        },
        dragoverAdd(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_side_area_chat') != -1) {
                event.preventDefault()
            }
        },
        dragenterAdd(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_side_area_chat') != -1) {
                event.preventDefault()
            }
        },
        async dropAdd(event, dropIndex) {
            const types = event.dataTransfer.types
            let index = dropIndex == -1 ? this.$store.getters['scenario/length'] : dropIndex
            if (types.indexOf('type_side_area_chat') != -1) {
                const chat = JSON.parse(event.dataTransfer.getData('type_side_area_chat'))
                this.$store.commit('scenario/insertChat', { 'chat': chat, 'index': index })
                let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
                this.$store.commit('scenario/setScenario', s.data)
                this.$store.commit('scenario/setSaved', { "saved": true })
            }
        },
    },
}
</script>

<style scoped>
.edit_area {
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: white;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.fachatbot_header_area {
    color: #2c3e50;
    background-color: white;
    /* z-index: 9999; */
    position: absolute;
    width: 450px;
    left: calc((100% - 450px) / 2);
    height: 60px;
    opacity: 1;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.fachatbot_chat_area {
    color: #2c3e50;
    background-color: white;
    /* z-index: 9999; */
    position: absolute;
    width: 450px;
    left: calc((100% - 450px) / 2);
    height: calc(100% - 60px);
    top: 60px;

    opacity: 1;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.fachatbot_chatarea_wrapper_other_edit_mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: auto;
    color: rgba(255, 255, 255, 1);
}

::v-deep(*) {
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;
    margin: 0;
    border: none;
    padding: 0;
    text-align: left;
    line-height: 1.42857143;
    letter-spacing: 0;
    user-select: none;
}

.chat-move,
.chat-leave-active,
.chat-enter-active {
    transition: all 0.2s ease;
}

.chat-leave-to,
.chat-enter-from {
    opacity: 0;
}

.chat-leave-active {
    position: absolute;
    width: 100%;
}


.insert_box {
    position: relative;
    width: 100%;
    background-color: white;
    ;
}

.insert_target {
    position: relative;
    border-radius: 10px;
    width: 90%;
    left: 5%;
    height: 8px;
}

.insert_target_enable {
    background-color: rgb(196, 255, 255);
}

.insert_target_end {
    margin-bottom: 39px;
}
</style>