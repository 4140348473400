<template>
    <div class="dialog_area">
        <label class="notice">下記に設置したいCVタグを入力して保存ボタンを押してください。</label>
        <textarea class="dialog_input" v-model="text"></textarea>
        <button class="ok" @click.stop="btnOk">保存する</button>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'dialogThanksMail',
    props: ['id'],
    components: {
    },
    data() {
        return {
            scenario: {},
            text: '',
        }
    },
    computed: {
        ...mapGetters({
            scenario_id: 'scenario/id',
        })
    },
    created: async function () {
        let response = await Api.scenarioGet(this, this.scenario_id)
        let s = response.data

        this.text = s.cvTag
    },
    mounted: function () {
    },
    methods: {
        btnCancel() {
            this.$emit('submit', { 'method': 'cancel' })
        },
        async btnOk() {
            let response = await Api.updateCvTag(this.$store, {
                'id': this.scenario_id,
                'text': this.text,
            })
            if(response.login_status == "LOGGED_IN" && response.status == "OK") {
                alert('広告タグを保存しました')
            }
            this.$store.commit('scenario/setCvTag', { cvTag: this.text })
            this.$store.commit('scenario/setSaved', { "saved": true })

        },
    },
}
</script>

<style scoped>
.dialog_area {
    position: relative;
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: white;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 3;
    padding: 0;
}

.ok {
    position: absolute;
    right: auto;
    left: auto;
    top: calc(max(100%, 400px) - 70px);
    box-sizing: border-box;
    border: 1px solid #00bcd4;
    line-height: 20px;
    background-color: #00bcd4;
    color: white;
    padding: 5px 20px;
    border-radius: 4px;
    font-weight: 700;
}

.ok:hover {
    line-height: 20px;
    background-color: white;
    color: #00bcd4;
}

.ok:disabled {
    border: 1px solid gray;
    line-height: 20px;
    background-color: #008a9c;
    color: #c0c0c0;
}

.dialog_input {
    width: calc(100% - 46px);
    top: 30px;
    left: 20px;
    height: max(calc(100% - 150px), 250px);
    resize: none;
    font-size: 15px;
    position: absolute;
}

.notice {
    /* top: calc(20px + 180px + max(100%, 400px) - 210px - 74px); */
    position: absolute;
    top: 0;
    left: 20px;
}</style>