<template>
  <transition appear name="input_credit_add_account" v-on:after-enter="afterEnter">
    <div class="input_credit">
      <div v-if="data.payment_method == 'univapay'" class="input_body">
        <p class="title">{{ data.subject }}</p>
        <div v-if="univapay_token != '' && univapay_secret != ''">
          <iframe :id="'univapay_frame_' + id" :src="univapay_url" class="input_group"></iframe>
        </div>
        <div v-else>
          UnivaPayのトークン、シークレットが未設定です。
        </div>
        <!-- {{ data }} -->
      </div>
    </div>
  </transition>
</template>

<script>
//import axios from 'axios';
export default {
  name: 'input_credit_add_account',
  props: ['id', 'data', 'result', 'status'],
  components: {
  },
  data() {
    return {
      btn_text: '次へ',
      univapay_token: '',
      univapay_secret: '',
      univapay_url: '',
    }
  },
  watch: {
    card_number: async function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    card_month: async function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    card_year: async function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    street: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
  },
  mounted: function () {
    this.univapay_token = this.data.univapay_token
    this.univapay_secret = this.data.univapay_secret
    this.univapay_url = process.env.VUE_APP_API_URL + 'univapay.php?data-app-id=' + this.data.univapay_token + '&preview=yes&cid=' + this.id
    if (typeof this.result !== 'undefined') {
      if (typeof this.result.card_number !== 'undefined') {
        this.card_number = this.result.card_number;
      }
      if (typeof this.result.card_month !== 'undefined') {
        this.card_month = this.result.card_month;
      }
      if (typeof this.result.card_year !== 'undefined') {
        this.card_year = this.result.card_year;
      }
    }
    var url = new URL(process.env.VUE_APP_API_URL)
    window.addEventListener('message', this.changeFrameSize, false);
    // this.$nextTick(() => this.$refs.card_number.focus());
  },
  beforeUnmount: function () {
    window.removeEventListener('message', this.changeFrameSize, false);
  },
  methods: {
    afterEnter() {
      this.$emit("afterEnter");
    },
    submit() {
      let date = new Date();
      this.card_number = this.toHalfWidth(this.card_number.trim());
      this.err_card_number = '';
      this.err_addr = '';
      this.err_expire = '';
      if (this.card_number == '') {
        this.err_card_number = 'こちらは必須項目です。';
      } else if (isNaN(this.card_number)) {
        this.err_card_number = 'カード番号は数字で入力してください。';
      } else if (this.card_number.length != 16) {
        this.err_card_number = 'カード番号は16桁の数字で入力してください。';
      }
      if (this.card_month == '' || this.card_year == '') {
        this.err_expire = 'こちらは必須項目です。';
      } else if ((date.getFullYear() * 100 + date.getMonth() + 1) > ('20' + this.card_year + this.card_month)) {
        this.err_expire = '有効期限をご確認ください。';
      }
      if (this.err_card_number == '' && this.err_expire == '') {
        this.btn_text = 'OK';
        let data = { 'card_number': this.card_number, 'card_month': this.card_month, 'card_year': this.card_year };
        this.$emit("submit", this.id, data);
      }
    },
    /**
     * 文字列内にある全角を半角へ変換
     * @param {string} value 変換したい文字列
     * @return {string} 変換された文字列を返す
     */
    toHalfWidth(value) {
      if (!value) return value;

      return String(value).replace(/[！-～]/g, function (all) {
        return String.fromCharCode(all.charCodeAt(0) - 0xFEE0);
      });
    },
    changeFrameSize(event) {
      var url = new URL(process.env.VUE_APP_API_URL)

      // 送られてきたのが確実に「c-brains.co.jp」からである事を確認
      if (event.origin != url.origin) {
        return;
      }
      if (event.data.cid != this.id) {
        return;
      }
      var child_height = event.data.height;
      this.frameSize = "height:" + child_height + 'px'
      var target = document.getElementById('univapay_frame_' + this.id);
      // // 受け取った数値を高さに設定
      target.style.height = target.contentWindow.document.body.scrollHeight + 'px'
    }
  }
}
</script>

<style scoped>
.input_credit {
  padding: 3px 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.input_body {
  padding: 10px;
  background: #fcf9ce;
}

.input_group {
  width: 100%;
  word-break: break-word;
  margin-bottom: 10px;
}

.title {
  white-space: pre-line;
  padding: 3px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.input_box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.input {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 100%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 0 8px;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
}

.noinput {
  text-align: left;
  font-size: 16px;
  background-color: #e4e4e4;
  color: rgb(0, 0, 0);
  border-color: #575757;
  width: 100%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 0 8px;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
}

.input_half {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 48%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 0 8px;
  outline: 0;
  margin: 0 0 0 5px;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}

.input::placeholder,
.input_half::placeholder {
  color: #aaa;
}

.next_message {
  text-align: right;
  float: right;
  padding-right: 0px;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  overflow: visible;
}

.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}

button:not(.disabled) {
  -webkit-animation: bounce 2.6s infinite ease-in-out;
  animation: bounce 2.6s infinite ease-in-out;
}

@keyframes bounce {
  5% {
    -webkit-transform: scale(1.2, .8);
  }

  10% {
    -webkit-transform: scale(.8, 1.2) translateY(-5px);
  }

  15% {
    -webkit-transform: scale(1, 1);
  }
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.required {
  color: red;
  font-size: 12px;
}

.err {
  color: red;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.input_credit-enter-active {
  transition: right .38s .3s ease, opacity .38s .3s ease-in;
}

.input_credit-enter-to {
  opacity: 1;
  right: 0;
}

.input_credit-enter {
  opacity: 0;
  right: -100px;
}</style>
