export default {
    namespaced: true,
    state: {
        lastId: 0,
        lastKey: 0,
        scenario: [],
        status: 'draft',
        id: null,
        name: '',
        title: '',
        saved: true,





        thanksMail: {},
        cvTag: '',

        tag: '',

        systemRegist: false,
        completeOtherPageUrl: '',
        notificationAddress: [],
    },
    getters: {
        dump(state) {
            return JSON.parse(JSON.stringify(state))
        },
        scenario(state) { return state.scenario },
        lastId(state) { return state.lastId++ },
        lastKey(state) { return state.lastKey++ },
        status(state) { return state.status },
        id(state) { return state.id },
        name(state) { return state.name },
        title(state) { return state.title },
        saved(state) { return state.saved },
        systemRegist(state) { return state.systemRegist },
        completeOtherPageUrl(state) { return state.completeOtherPageUrl },
        notificationAddress(state) { return state.notificationAddress },
        thanksMail(state) { return state.thanksMail },
        cvTag(state) { return state.cvTag },
        tag(state) { return state.tag },
        getChat(state) {
            return (id) => {
                let c = state.scenario.find(c => c.id == id)
                if (c == undefined) { c = {} }
                return JSON.parse(JSON.stringify(c))
            }
        },
        length(state) {
            return state.scenario.length
        },
        confirmText(state) {
            let result = ''
            state.scenario.forEach(c => {
                switch (c.type) {
                    case 'input_name':
                        if (c.data.company_display) {
                            result += c.data.company_subject + '：　' + '\n'
                        }
                        if (c.data.name_display) {
                            result += c.data.name_subject + '：　' + '\n'
                        }
                        if (c.data.kana_display) {
                            result += c.data.kana_subject + '：　' + '\n'
                        }
                        break;
                    case 'input_email_and_tel':
                        if (c.data.email_display) {
                            result += c.data.email_subject + '：　' + '\n'
                        }
                        if (c.data.tel_display) {
                            result += c.data.tel_subject + '：　' + '\n'
                        }
                        break;
                    case 'select_schedule':
                        result += c.data.schedule_subject + '：　' + '\n'
                        break;
                    case 'input_free_input':
                        result += c.data.free_input_subject + '：　' + '\n'
                        break;
                    case 'input_free_text':
                        result += c.data.free_text_subject + '：　' + '\n'
                        break;
                    case 'select_free_select':
                        result += c.data.free_select_subject + '：　' + '\n'
                        break;
                    case 'select_free_radio':
                        result += c.data.free_radio_subject + '：　' + '\n'
                        break;
                    case 'select_free_checkbox':
                        result += '【' + c.data.free_checkbox_subject + '】\n'
                        break;
                    case 'select_free_date':
                        result += c.data.free_date_subject + '：　' + '\n'
                        break;
                    case 'input_agreement':
                        result += c.data.agreement_subject + '：　' + '\n'
                        break;
                    case 'input_credit':
                        result += 'お支払方法：　' + '\n'
                        result += 'お支払金額：　' + '\n'
                        break;
                    default:
                        break;
                }
            })
            return result
        },
    },
    mutations: {
        init: function (state, payload) {
            state.lastId = 2
            state.lastKey = 2
            state.scenario =
                [
                    {
                        "type": "confirm",
                        "data": {
                            "confirm_subject": "内容確認"
                        },
                        "key": 0,
                        "id": 0
                    },
                    {
                        "type": "complete",
                        "data": {
                            "complete_subject": "お申込みありがとうございました。",
                            "complete_text": "ご登録いただきましたメールアドレス宛てに案内メールをお送りしましたのでご確認下さい。\n※しばらくたってもご案内メールが見当たらない場合は、迷惑メールフォルダなどに振り分けられているか、間違ったアドレスでお手続きをされた可能性があります。\nその場合は、お手数ですが、再度メールアドレスをご確認の上、お申し込み手続きを行ってください。\n今後とも宜しくお願いいたします。"
                        },
                        "key": 1,
                        "id": 1
                    }
                ]
            state.status = 'publish'
            state.id = null
            state.name = '無題'
            state.systemRegist = false
            state.completeOtherPageUrl = ''
            state.notificationAddress = []
            state.title = 'タイトルを編集してください'
            state.saved = false
            state.thanksMail = {
                sendMail: false,
                fromName: '',
                fromAddress: '',
                subject: '',
                text: '',
            }
            state.cvTag = ''
        },
        //  payload: {
        //      template,
        //  }
        setScenarioTemplate: function (state, payload) {
            state.lastId = 0
            state.lastKey = 0
            payload.scenario.forEach(c => {
                c.id = state.lastId++
                c.key = state.lastKey++
            });
            state.scenario = payload.scenario
            state.status = 'publish'
            state.id = null
            state.name = payload.name
            state.systemRegist = false
            state.completeOtherPageUrl = ''
            state.notificationAddress = []
            state.title = payload.title
            state.thanksMail = payload.thanksMail
            state.saved = false
        },
        //  payload: {
        //      title,
        //  }
        setName: function (state, payload) {
            state.name = payload.name
            state.saved = false
        },
        //  payload: {
        //      title,
        //  }
        setSystemRegist: function (state, payload) {
            state.systemRegist = payload.systemRegist
            state.saved = false
        },
        //  payload: {
        //      title,
        //  }
        setCompleteOtherPageUrl: function (state, payload) {
            state.completeOtherPageUrl = payload.completeOtherPageUrl
            state.saved = false
        },
        //  payload: {
        //      title,
        //  }
        setNotificationAddress: function (state, payload) {
            state.notificationAddress = payload.notificationAddress
            state.saved = false
        },
        //  payload: {
        //      status,
        //  }
        setStatus: function (state, payload) {
            state.status = payload.status
            state.saved = false
        },
        //  payload: {
        //      id,
        //  }
        setId: function (state, payload) {
            state.id = payload.id
        },
        //  payload: {
        //      saved,
        //  }
        setSaved: function (state, payload) {
            state.saved = payload.saved
        },
        //  payload: {
        //      title,
        //  }
        setThanksMail: function (state, payload) {
            state.thanksMail = payload.thanksMail
            state.saved = false
        },
        //  payload: {
        //      title,
        //  }
        setCvTag: function (state, payload) {
            state.cvTag = payload.cvTag
            state.saved = false
        },
        //  payload: {
        //      title,
        //  }
        setTitle: function (state, payload) {
            state.title = payload.title
            state.saved = false
        },
        //  payload: {
        //      scenario,
        //      id,
        //      lastId,
        //      lastKey,
        //      title,
        //  }
        setScenario: function (state, payload) {
            state.scenario = payload.scenario
            state.id = payload.id
            state.lastId = payload.lastId
            state.lastKey = payload.lastKey
            state.status = payload.status
            state.name = payload.name
            state.systemRegist = payload.systemRegist
            state.completeOtherPageUrl = payload.completeOtherPageUrl
            state.notificationAddress = payload.notificationAddress
            state.title = payload.title
            state.saved = true
            state.cvTag = payload.cvTag
            state.tag = payload.tag
            state.thanksMail = payload.thanksMail
        },
        //  payload: {
        //      chat,
        //  }
        replaceChat: function (state, payload) {
            // payload.chat.key = state.lastKey++;
            let i = state.scenario.findIndex(c => c.id == payload.chat.id);
            if (i != -1) state.scenario.splice(i, 1, payload.chat);
            state.saved = false
        },
        //  payload: {
        //      chat,
        //      index,
        //  }
        insertChat(state, payload) {
            payload.chat.key = state.lastKey++
            payload.chat.id = state.lastId++
            state.scenario.splice(payload.index, 0, payload.chat);
            state.saved = false
        },
        //  payload: {
        //      id,
        //  }
        moveUp(state, payload) {
            let i = state.scenario.findIndex(c => c.id == payload.id);
            if (i > 0) {
                let tmp = state.scenario.splice(i, 1);
                state.scenario.splice(i - 1, 0, tmp[0]);
            }
            state.saved = false
        },
        //  payload: {
        //      id,
        //  }
        moveDown(state, payload) {
            let i = state.scenario.findIndex(c => c.id == payload.id);
            if (i != -1) {
                let tmp = state.scenario.splice(i, 1);
                state.scenario.splice(i + 1, 0, tmp[0]);
            }
            state.saved = false
        },
        //  payload: {
        //      fromIndex,
        //      toIndex,
        //  }
        move(state, payload) {
            const deleteList = state.scenario.splice(payload.fromIndex, 1);
            state.scenario.splice(payload.toIndex, 0, deleteList[0]);
            state.saved = false
        },
        //  payload: {
        //      id,
        //  }
        remove(state, payload) {
            let i = state.scenario.findIndex(c => c.id == payload.id);
            if (i != -1) {
                state.scenario.splice(i, 1);
            }
            state.saved = false
        },
    },
    actions: {
    },
}
