<template>
    <div class="dialog_area">
        <label class="notice">※迷惑メール回避のためSPF設定が必要です。詳しい設定方法は<a
                href="https://webinar-master.com/webinartatsujin/manual/spf_susume/" target="_blank">こちら</a>。</label>
        <label class="dialog_sendmail_label">
            <input class="dialog_sendmail" type="checkbox" v-model="sendMail" />自動返信メールを送信する
        </label>
        <label class="dialog_from_name_label">送信者名</label>
        <input class="dialog_from_name" type="text" v-model="fromName" />
        <label class="dialog_from_address_label">送信者メールアドレス</label>
        <input class="dialog_from_address" type="text" v-model="fromAddress" />
        <label class="dialog_subject_label">件名</label>
        <input class="dialog_subject" type="text" v-model="subject" />
        <label class="dialog_input_label">本文</label>
        <div class="macro">
            <label class="macros_label">タグ挿入：
                <select v-model="selectedMacro" ref="macro">
                    <option v-for="(m, index) in macros" :key="index" :value="'[' + m + ']'">[{{ m }}]</option>
                </select>
            </label>
            <button class="btn_insert_macro" @click="insertMacro">追加</button>
        </div>
        <textarea class="dialog_input" v-model="text" ref="text"></textarea>
        <button class="ok" @click.stop="btnOk">保存する</button>
        <div v-if="visibleConfigAlert" class="config_alert">
            <a href="https://webinar-master.com/webinartatsujin/manual/spf_susume/" target="_blank">SPF設定の詳細はこちらをクリック</a>
            <div class="alert_box">
                <h2><img src="@/assets/system-icon/warn.png" />重要<img src="@/assets/system-icon/warn.png" /></h2>
                <p>本システムをご利用いただくにあたり、まず初めに【SPF設定】をしてください。</p>
                <p>※外部環境からメールを送信する場合、メールの到達率を高めるために、「送信はなりすまし」の対策として、SPFは基本となる重要な設定となります。</p>
                <p>※迷惑メール回避のためSPF設定が必要です。送信者メールアドレスのドメインのDNSのTXTレコードに「v=spf1 ip4:157.112.176.11」を追加してください。</p>
            </div>
            <div class="alert_check_box">
                <input type="checkbox" v-model="dialogConfigAlert" />今後このメッセージを表示しない
            </div>
            <button class="alert_ok" @click="configAlertDialogSubmit">閉じる</button>
        </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'dialogThanksMail',
    props: ['id'],
    components: {
    },
    data() {
        return {
            sendMail: false,
            fromName: '',
            fromAddress: '',
            subject: '',
            scenario: {},
            text: '',
            macros: [],
            selectedMacro: '',
            configAlert: false,
            visibleConfigAlert: false,
            dialogConfigAlert: true,
        }
    },
    computed: {
        ...mapGetters({
            scenario_id: 'scenario/id',
        })
    },
    created: async function () {
        let response = await Api.scenarioGet(this, this.scenario_id)
        let s = response.data

        this.sendMail = s.thanksMail.sendMail
        this.fromName = s.thanksMail.fromName
        this.fromAddress = s.thanksMail.fromAddress
        this.subject = s.thanksMail.subject
        this.text = s.thanksMail.text
        this.configAlert = s.thanksMail.configAlert == undefined ? false : s.thanksMail.configAlert
        let m = {}
        s.scenario.forEach(c => {
            switch (c.type) {
                case 'input_name':
                    if (c.data.company_display) {
                        m[c.data.company_subject] = 1
                    }
                    if (c.data.name_display) {
                        m[c.data.name_subject] = 1
                    }
                    if (c.data.kana_display) {
                        m[c.data.kana_subject] = 1
                    }
                    break
                case 'input_email_and_tel':
                    if (c.data.email_display) {
                        m[c.data.email_subject] = 1
                    }
                    if (c.data.tel_display) {
                        m[c.data.tel_subject] = 1
                    }
                    break
                case 'select_schedule':
                    m[c.data.schedule_subject] = 1
                    break
                case 'input_free_input':
                    m[c.data.free_input_subject] = 1
                    break
                case 'input_free_text':
                    m[c.data.free_text_subject] = 1
                    break
                case 'select_free_select':
                    m[c.data.free_select_subject] = 1
                    break
                case 'select_free_radio':
                    m[c.data.free_radio_subject] = 1
                    break
                case 'select_free_checkbox':
                    m[c.data.free_checkbox_subject] = 1
                    break
                case 'select_free_date':
                    m[c.data.free_date_subject] = 1
                    break
                case 'input_agreement':
                    m[c.data.agreement_subject] = 1
                    break
                case 'input_credit':
                    m['お支払方法'] = 1
                    m['お支払金額'] = 1
                    break
                default:
                    break
            }
        });
        this.macros = Object.keys(m)
        if (s.systemRegist) {
            this.macros.push('パスワード')
        }
    },

    mounted: async function () {
    },
    methods: {
        btnCancel() {
            this.$emit('submit', { 'method': 'cancel' })
        },
        async btnOk() {
            let response = await Api.updateThanksMail(this.$store, {
                'id': this.scenario_id,
                'sendMail': this.sendMail,
                'fromName': this.fromName,
                'fromAddress': this.fromAddress,
                'subject': this.subject,
                'text': this.text,
                'configAlert': this.configAlert,
            })
            if (response.login_status == "LOGGED_IN" && response.status == "OK") {
                if (this.configAlert == false) {
                    this.dialogConfigAlert = false
                    this.visibleConfigAlert = true
                } else {
                    alert("自動返信メールを保存しました")
                }
            }
            this.$store.commit('scenario/setThanksMail', {
                thanksMail: {
                    'sendMail': this.sendMail,
                    'fromName': this.fromName,
                    'fromAddress': this.fromAddress,
                    'subject': this.subject,
                    'text': this.text,
                }
            })
            this.$store.commit('scenario/setSaved', { "saved": true })


        },
        insertMacro() {
            let start = this.$refs.text.selectionStart
            this.text = this.$refs.text.value.substr(0, start)
                + this.selectedMacro
                + this.$refs.text.value.substr(start);
            this.$refs.text.focus()
            this.$nextTick(() => {
                this.$refs.text.selectionStart = this.$refs.text.selectionEnd = start + this.selectedMacro.length
            })
        },
        async configAlertDialogSubmit() {
            if (this.dialogConfigAlert == true) {
                this.configAlert = this.dialogConfigAlert
                let response = await Api.updateThanksMail(this.$store, {
                    'id': this.scenario_id,
                    'sendMail': this.sendMail,
                    'fromName': this.fromName,
                    'fromAddress': this.fromAddress,
                    'subject': this.subject,
                    'text': this.text,
                    'configAlert': this.configAlert,
                })
            }
            this.visibleConfigAlert = false
        }
    },
}
</script>

<style scoped>
.dialog_area {
    position: relative;
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: white;
    height: 100%;
    overflow: auto;
    z-index: 3;
    padding: 0;
}

.dialog_area h1 {
    position: absolute;
    top: 20px;
    left: 20px;
}

.dialog_area label,
.dialog_area input[type="text"],
.dialog_area textarea {
    width: calc(100% - 46px);
    left: 20px;
    resize: none;
    font-size: 15px;
    position: absolute;
    text-align: left;
}

.btn {
    min-width: 90px;
    width: auto;
    min-height: 42px;
    visibility: visible;
    box-sizing: content-box;
    border: none;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    outline: 0;
    color: #ffffff;
    background-color: #fa9352;
    padding: 0px 14px 0px 14px;
    border-radius: 25px;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    background-image: none;
    overflow: visible;
}

.cancel {
    background-color: #f6f6fa;
    color: black;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    position: absolute;
    right: 148px;
    bottom: 15px;
}

.ok {
    position: absolute;
    right: auto;
    left: auto;
    top: calc(max(100%, 400px) - 70px);
    box-sizing: border-box;
    border: 1px solid #00bcd4;
    line-height: 20px;
    background-color: #00bcd4;
    color: white;
    padding: 5px 20px;
    border-radius: 4px;
    font-weight: 700;
}

.ok:hover {
    line-height: 20px;
    background-color: white;
    color: #00bcd4;
}

.ok:disabled {
    border: 1px solid gray;
    line-height: 20px;
    background-color: #008a9c;
    color: #c0c0c0;
}

.dialog_sendmail_label {
    top: calc(20px + 10px);
}

.dialog_sendmail {
    top: calc(20px + 10px);
}

.dialog_from_name_label {
    top: calc(20px + 30px);
}

.dialog_from_name {
    top: calc(20px + 50px);
}

.dialog_from_address_label {
    top: calc(20px + 70px);
}

.dialog_from_address {
    top: calc(20px + 90px);
}

.dialog_subject_label {
    top: calc(20px + 110px);
}

.dialog_subject {
    top: calc(20px + 130px);
}

.dialog_input_label {
    top: calc(20px + 150px);
}

.macro {
    position: absolute;
    top: calc(20px + 180px);
    width: 320px;
}

.dialog_input {
    top: calc(20px + 210px);
    height: calc(max(100%, 400px) - 250px - 74px);
}

.notice {
    /* top: calc(20px + 180px + max(100%, 400px) - 210px - 74px); */
    top: 0;
}

.btn_insert_macro {
    position: absolute;
    right: 0;
}

.config_alert {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
}

.config_alert>a {
    box-sizing: border-box;
    display: block;
    background: #00bcd4;
    color: white;
    text-decoration: none;
    margin: 30px 30px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 700;
    border-radius: 4px;
    width: calc(100% - 60px);
}

.alert_box {
    box-sizing: border-box;
    background-color: #ffb300;
    margin: 30px 30px;
    padding: 30px;
    width: calc(100% - 60px);
}

.alert_box>h2 {
    font-size: 30px;
}

.alert_box>h2>img {
    position: relative;
    width: 32px;
    height: 32px;
    top: 5px;
    margin: 0 20px;
}

.alert_check_box {
    margin-bottom: 30px;
}

.alert_ok {
    box-sizing: border-box;
    border: 1px solid black;
    line-height: 32px;
    font-size: 24px;
    background-color: black;
    color: white;
    padding: 8px 30px;
    border-radius: 4px;
    font-weight: 700;
}</style>