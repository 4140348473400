<template>
    <div class="control_area">
        <div class="list_area">
            <table class="list_table">
                <tbody v-if="scenarioLoaded">
                    <TransitionGroup name="list">
                        <tr v-for="(s, index) in scenarioList" :key="s.id" draggable="true"
                            @dragstart="dragList($event, index)" @drop="dropList($event, index)"
                            @dragover="dragoverList($event, index)" @dragenter="dragenterList($event, index)">
                            <td class="list_item" @click="toEdit(s.id)">
                                <div>
                                    <div @click.stop="clickStatus(s.id, s.status)" :class="'status ' + s.status">
                                        {{ strStatus(s.status) }}
                                    </div>
                                    <p>{{ s.name }}</p>
                                </div>
                                <div class="list_icon_group">
                                    <div class="list_icon_box" @click.stop="toPreview(s.id)" title="プレビュー">
                                        <span class="material-symbols-outlined icon-adj">live_tv</span>プレビュー
                                    </div>
                                    <div class="list_icon_box" @click.stop="copy(s.id)" title="複製">
                                        <span class="material-symbols-outlined icon-adj">file_copy</span>複製
                                    </div>
                                    <div class="list_icon_box" @click.stop="toComplete(s.id)" title="申込完了ページ設定">
                                        <span class="material-symbols-outlined icon-adj">mail</span>申込完了ページ設定
                                    </div>
                                    <div class="list_icon_box" @click.stop="toThanksMail(s.id)" title="自動返信メール設定">
                                        <span class="material-symbols-outlined icon-adj">mail</span>自動返信メール設定
                                    </div>
                                    <div class="list_icon_box" @click.stop="toNotification(s.id)" title="通知設定">
                                        <span class="material-symbols-outlined icon-adj">mail</span>通知設定
                                    </div>
                                    <div class="list_icon_box" @click.stop="toCvTag(s.id)" title="広告タグ設定">
                                        <span class="material-symbols-outlined icon-adj">code_blocks</span>広告タグ設定
                                    </div>
                                    <div class="list_icon_box" @click.stop="toChatbottag(s.id)" title="利用開始">
                                        <span class="material-symbols-outlined icon-adj">wysiwyg</span>利用開始
                                    </div>
                                    <div class="list_icon_box" @click.stop="toAnswerlist(s.id)" title="申込者">
                                        <span class="material-symbols-outlined icon-adj">list_alt</span>申込者
                                    </div>
                                    <div class="list_icon_box" @click.stop="sDelete(s.id)" title="削除">
                                        <span class="material-symbols-outlined icon-adj" style="color:red;">delete</span>削除
                                    </div>


                                    <!-- <span class="scenario_length">シナリオ長:{{ s.length }}</span> -->
                                </div>
                            </td>
                        </tr>
                        <tr v-if="scenarioList.length == 0">
                            <td>
                                <div>シナリオが登録されていません</div>
                            </td>
                        </tr>
                    </TransitionGroup>
                </tbody>
            </table>
            <div v-if="dialog != ''" class="dialog_mask" @click.stop="clickDialogMask"></div>
            <component v-if="dialog != ''" :is="dialog" @submit="submitDialog" :id="dialogId"></component>
        </div>
    </div>
</template>
  
<script>
import component_wrapper from '@/components/scenario/edit/component_wrapper.vue'
import header_base from '@/components/chat/components/header_base.vue'
import dialogThanksMail from '@/components/scenario/control/dialogThanksMail.vue'
import dialogCvTag from '@/components/scenario/control/dialogCvTag.vue'
import dialogRename from '@/components/scenario/control/dialogRename.vue'
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

const componentLabel = {
    'operator_talk': 'オペレータートーク',
    'input_name': '会社名・姓名',
    'input_email_and_tel': 'メール・Tel',
    'select_schedule': 'スケジュール選択',
    'confirm': '確認',
    'complete': '完了',
}

export default {
    name: 'ControlArea',
    props: [],
    components: {
        component_wrapper,
        header_base,
        dialogThanksMail,
        dialogCvTag,
        dialogRename,
    },
    data() {
        return {
            renameId: null,
            renameData: '',
            dialog: '',
            dialogId: null,
            scenarioList: [],
            scenarioLoaded: false,
        }
    },
    computed: {
        ...mapGetters({
            scenario_dump: 'scenario/dump',
        })
    },
    beforeMount: async function () {
        let response = await Api.scenarioGetList(this)
        if (response.count == 0) {
            this.$store.commit(
                'scenario/setScenarioTemplate',
                Api.getTemplate(0)
            )
            let s = await Api.scenarioNew(this.$store, this.scenario_dump)
            response = await Api.scenarioGetList(this)
        }
        this.scenarioList = response.data
        this.scenarioLoaded = true
    },
    mounted: async function () {
    },
    methods: {
        dragList(event, dragIndex) {
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.setData('type_scenario_list', dragIndex)
        },
        async dropList(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_scenario_list') != -1) {
                const dragIndex = event.dataTransfer.getData('type_scenario_list')
                let response = await Api.move(this.$store, {
                    'fromId': this.scenarioList[dragIndex]['id'],
                    'fromIndex': dragIndex,
                    'toIndex': dropIndex
                })
                // response = await Api.scenarioGetList(this)
                this.scenarioList = response.data

            }
        },
        dragoverList(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_scenario_list') != -1) {
                event.preventDefault()
            }
        },
        dragenterList(event, dropIndex) {
            const types = event.dataTransfer.types
            if (types.indexOf('type_scenario_list') != -1) {
                event.preventDefault()
            }
        },
        strStatus(status) {
            const st = {
                'draft': '非公開',
                'publish': '公開中',
            }
            return st[status] == undefined ? '不明' : st[status]
        },
        async clickStatus(id, status) {
            let nextStatus = ''
            switch (status) {
                case 'draft':
                    nextStatus = 'publish'
                    break
                case 'publish':
                    nextStatus = 'draft'
                    break
                default:
                    nextStatus = 'draft'
                    break
            }
            let response = await Api.updateStatus(this.$store, { 'id': id, 'status': nextStatus })
            response = await Api.scenarioGetList(this)
            this.scenarioList = response.data
        },
        async toEdit(id) {
            let response = await Api.scenarioGet(this, id)
            let s = response.data
            this.$store.commit('editView/setMenu', { 'menu': 'EditArea' })
            this.$store.commit('scenario/setScenario', s)
            this.$router.push({ path: '/scenario/edit' })
        },
        async sDelete(id) {
            if (confirm("削除したシナリオは復活できません。\nシナリオID" + id + "を削除しますか？")) {
                let response = await Api.deleteScenario(this.$store, id)
                response = await Api.scenarioGetList(this)
                this.scenarioList = response.data
            }
        },
        async copy(id) {
            let response = await Api.copy(this.$store, { 'id': id })
            this.scenarioList = response.data
        },
        async toPreview(id) {
            let response = await Api.scenarioGet(this, id)
            let s = response.data
            this.$store.commit('editView/setMenu', { 'menu': 'PreviewArea' })
            this.$store.commit('scenario/setScenario', s)
            this.$router.push({ path: '/scenario/edit' })
        },
        clickDialogMask() {
            this.dialog = ''
        },
        toRename(id) {
            this.dialogId = id
            this.dialog = 'dialogRename'
        },
        async toThanksMail(id) {
            let response = await Api.scenarioGet(this, id)
            let s = response.data
            this.$store.commit('editView/setMenu', { 'menu': 'ThanksmailArea' })
            this.$store.commit('scenario/setScenario', s)
            this.$router.push({ path: '/scenario/edit' })
        },
        async toComplete(id) {
            let response = await Api.scenarioGet(this, id)
            let s = response.data
            this.$store.commit('editView/setMenu', { 'menu': 'CompleteArea' })
            this.$store.commit('scenario/setScenario', s)
            this.$router.push({ path: '/scenario/edit' })
        },
        async toNotification(id) {
            let response = await Api.scenarioGet(this, id)
            let s = response.data
            this.$store.commit('editView/setMenu', { 'menu': 'NotificationArea' })
            this.$store.commit('scenario/setScenario', s)
            this.$router.push({ path: '/scenario/edit' })
        },
        async toCvTag(id) {
            let response = await Api.scenarioGet(this, id)
            let s = response.data
            this.$store.commit('editView/setMenu', { 'menu': 'CvtagArea' })
            this.$store.commit('scenario/setScenario', s)
            this.$router.push({ path: '/scenario/edit' })
        },
        async toChatbottag(id) {
            let response = await Api.scenarioGet(this, id)
            let s = response.data
            this.$store.commit('editView/setMenu', { 'menu': 'ChatbottagArea' })
            this.$store.commit('scenario/setScenario', s)
            this.$router.push({ path: '/scenario/edit' })
        },
        async toAnswerlist(id) {
            let response = await Api.scenarioGet(this, id)
            let s = response.data
            this.$store.commit('editView/setMenu', { 'menu': 'AnswerlistArea' })
            this.$store.commit('scenario/setScenario', s)
            this.$router.push({ path: '/scenario/edit' })
        },
        async submitDialog(payload) {
            this.dialog = ''
            let response = await Api.scenarioGetList(this)
            this.scenarioList = response.data
        },
    },
}
</script>

<style scoped>
.control_area {
    width: 100%;
    height: 100%;
    background-color: #f6f6fa;
    overflow: hidden;
    position: relative;
}

.list_area {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: auto;
}

.list_table {
    position: absolute;
    margin: 0;
    border-spacing: 0 30px;
    top: 0;
    left: 50px;
    width: calc(100% - 100px);
}

.list_item {
    text-align: left;
    cursor: pointer;
}

.list_item>div:first-child {
    margin-bottom: 2px;
}

.list_item>div {
    background-color: white;
    padding: 15px;
}

.list_item>div>p {
    display: inline-block;
    margin: 0 0 2px 0;
    white-space: normal;
    word-break: break-all;
    font-weight: 700;
    font-size: 1.2em;
    line-height: 36px;
    color: #00bcd4;
}

.status {
    display: inline-block;
    text-align: center;
    width: 80px;
    line-height: 36px;
    border-radius: 5px;
    left: 20px;
    font-weight: 700;
    margin: 0 10px;
}

.publish {
    background-color: #ffc107;
    color: white;
}

.draft {
    background-color: gray;
    color: white;
}

.list-move,
.list-leave-active,
.list-enter-active {
    transition: all 0.2s ease;
}

.list-leave-to,
.list-enter-from {
    opacity: 0;
}

.list-leave-active {
    position: absolute;
    width: 100%;
}

.icon-adj {
    position: relative;
    font-size: 20px;
    top: 4px;
    margin-left: 5px;
}

.scenario_length {
    position: absolute;
    bottom: 3px;
    right: 5px;
}

.dialog_mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
}

.list_icon_group {
    display: block;
    position: static;
    overflow: visible;
    word-wrap: break-all;
    text-align: left;
}

.list_icon_box {
    display: inline-block;
}

.list_icon_box:hover {
    color: #00bcd4;
    font-weight: 700;
}
</style>