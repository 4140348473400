<template>
  <div v-if="initialized" class="login">
    <div class="main_box">
      <div class="contents">
        <ForgotArea></ForgotArea>
      </div>
    </div>
  </div>
</template>
  
<script>
import ForgotArea from '@/components/forgot/ForgotArea.vue'
import Api from '@/lib/Api.js'

const defSCHEDULE = [

];

export default {
  name: 'ForgotView',
  components: {
    ForgotArea,
  },
  data() {
    return {
      initialized: false,
    }
  },
  created() {
  },
  async mounted() {
    this.status = await Api.getStatus(this)
    if (this.status.login_status == "LOGGED_IN") {
      this.$router.push("/")
    }
    this.initialized = true
  },
  methods: {
  },
}
</script>

<style scoped>

</style>