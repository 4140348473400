<template>
  <div class="edit_box">
    <div class="title_box">
      <div class="edit_title">同意画面</div>
      <div class="right">
        <div class="edit_submit" @click="submit">項目を保存</div>
      </div>
    </div>
    <div>
      <div class="input_group">
        <div class="input_box">
          <label for="agreement_subject">タイトル</label>
          <input id="agreement_subject" type="text" v-model="edit_data.agreement_subject" />
        </div>
        <div class="input_box">
          <label for="agreement_text">同意文書</label>
          <textarea id="agreement_text" type="text" v-model="edit_data.agreement_text"></textarea>
        </div>
        <div class="input_box">
          <label for="agreement_display_lines">表示行数</label>
          <input class="number" id="agreement_display_lines" type="number" v-model="edit_data.agreement_display_lines" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditInputAgreement',
  props: ['chat'],
  components: {
  },
  data() {
    return {
      edit_data: {},
    }
  },
  watch: {
  },
  mounted: function () {
    this.edit_data = this.chat.data;
  },
  methods: {
    submit: function () {
      let data = this.edit_data;
      this.$emit("submit", data);
    },
  }
}
</script>

<style scoped>
.edit_box {
  margin: 20px;
}

.title_box {
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
}

.edit_title {
  position: absolute;
  color: white;
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
}

.right {
  position: absolute;
  height: 30px;
  right: 0;
}

.edit_submit {
  background-color: #42B5D1;
  border-color: #42B5D1;
  color: white;
  line-height: 24px;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 8px;
}

.input_group {
  overflow: hidden;
  border: 0 solid #ffb300;
  text-align: left;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  margin-bottom: 20px;
}

.input_box {
  border: 1px solid #ffb300;
  padding: 10px 5px;
  position: relative;
}

.input_box label {
  width: 100%;
  cursor: pointer;
}

.input_box input[type="text"],
.input_box input[type="number"] {
  width: calc(100% - 10px);
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}
.input_box input[type="number"] {
  text-align: right;
}
.input_box textarea {
  width: calc(100% - 10px);
  font-size: 16px;
  line-height: 1.2em;
  height: calc(1.2em * 10);
  margin-top: 10px;
  margin-bottom: 10px;
}
.subject {
  color: white;
}
</style>
