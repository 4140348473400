<template>
  <div v-if="initialized" class="edit">
    <div class="side_menu">
      <SideArea></SideArea>
    </div>
    <div class="main_box">
      <div class="head_area">
        <UserProfile></UserProfile>
      </div>
      <div class="title_area">
        <TitleArea></TitleArea>
      </div>
      <div class="contents">
        <ControlArea></ControlArea>
      </div>
    </div>
  </div>
  <div v-else>
    読み込み中
  </div>
</template>

<script>
import ControlArea from '@/components/payment/detail/EditArea.vue'
import TitleArea from '@/components/payment/detail/TitleArea.vue'
import SideArea from '@/components/payment/detail/SideArea.vue'
import Api from '@/lib/Api.js'
import UserProfile from '@/components/global/UserProfile.vue'

export default {
  name: 'editView',
  components: {
    ControlArea,
    TitleArea,
    SideArea,
    UserProfile,
  },
  data() {
    return {
      status: null,
      initialized: false,
    }
  },
  created() {
  },
  mounted: async function () {
    this.status = await Api.getStatus(this)
    if (this.status.login_status != "LOGGED_IN") {
      this.$router.push("/login")
    }
    this.$store.watch(
      (state, getters) => getters['customer/customer_id'],
      (newValue, oldValue) => {
        if (newValue == null) {
          this.$router.push("/login")
        }
      }
    );
    this.initialized = true
  },
  methods: {
  },
}
</script>
  
<style scoped>
.edit {
  position: absolute;
  width: 100%;
  height: 100%;
}

.side_menu {
  position: absolute;
  border: none;
  border-width: 1px;
  border-color: black;
  left: 0;
  top: 0;
  width: 320px;
  height: 100%;
  margin: 0;
  padding: 0;
}

.main_box {
  position: absolute;
  border: none;
  border-width: 1px;
  border-color: black;
  left: 320px;
  width: max(calc(100% - 320px), 700px);
  height: 100%;
  margin: 0;
  padding: 0;
}

.head_area {
  width: 100%;
  height: 50px;
}
.title_area {
  width: 100%;
  height: 100px;
}

.contents {
  position: absolute;
  border: none;
  border-width: 1px;
  border-color: black;
  width: 100%;
  height: calc(100% - 150px);
  margin: 0;
  padding: 0;
  /* min-width: 500px; */
}
</style>
