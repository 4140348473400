<template>
  <div class="edit_area">
    <div v-if="mode == 'normal'" class="input_group">
      <h2>基本情報</h2>
      <div class="input_box error">{{ error_profile }}</div>
      <div class="input_box notice">{{ notice_profile }}</div>
      <div class="input_box">
        <label>お名前<div class="error">{{ error_name }}</div><input type="text" v-model="name"
            autocomplete="name" /></label>
      </div>
      <div class="input_box">

        <label>メールアドレス<div class="error">{{ error_email }}</div><input type="text" v-model="email"
            autocomplete="username" /></label>
      </div>
      <div class="input_box">

        <label>お電話番号<div class="error">{{ error_tel }}</div><input type="text" v-model="tel" autocomplete="tel" /></label>
      </div>
      <div class="input_box">
        <div class="input_box error">{{ error_profile }}</div>
        <button v-if="profileSavable" disabled @click="updateProfile">保存する</button>
        <button v-else @click="updateProfile">保存する</button>
      </div>

      <h2>パスワード変更</h2>
      <div class="input_box">
        <div v-if="error_password != ''" class="input_box error">{{ error_password }}</div>
        <div v-if="notice_password != ''" class="input_box notice">{{ notice_password }}</div>
        <div v-if="passwordVisible">
          <input type="text" :value="email" name="username" autocomplete="username" class="hiddenname" />
          <div style="margin-bottom:20px;">
            <label class="password_label">パスワード：</label><input class="password" ref="pw" type="password"
              v-model="password" autocomplete="new-password" />
          </div>
          <div style="margin-bottom:20px;">
            <label class="password_label">パスワード(確認)：</label>
            <input class="password" type="password" v-model="passwordConfirm" autocomplete="new-password" />
            <label>{{ error_passwordConfirm }}</label>
          </div>
        </div>
        <div>
          <button v-if="passwordUpdatable" @click="updatePassword">パスワードを更新する</button>
          <button v-else disabled @click="updatePassword">パスワードを更新する</button>
        </div>
        <div>
          <div class="notice">※8文字以上で指定してください</div>
          <div class="notice">※半角文字の小文字(a～z), 大文字(A～Z), 数字(0～9)のみで、アルファベットと数字を2文字以上入れてください</div>
        </div>
      </div>
    </div>
    <div v-if="mode == 'normal'" class="cancel_box"><a @click="customer_cancel" style="cursor: pointer;">利用解約はこちら</a>
    </div>
    <div v-if="mode == 'cancel_dialog'" class="config_alert">
      <div class="alert_box">
        <h2><img src="@/assets/system-icon/warn.png" />注意事項<img src="@/assets/system-icon/warn.png" /></h2>
        <p>解約すると、ログインは不可能になります。</p>
        <p>全てのデータが完全に削除されます。</p>
        <p>元に戻すことはできません。</p>
        <p>本当に解約手続きを行いますか？</p>
      </div>
      <div class="alert_check_box">
        <label><input type="checkbox" v-model="cancelDialogCancelAgree" />上記のことに同意したうえで解約します。</label>
      </div>
      <button class="alert_ok" @click="cancelDialogSubmit">解約する</button>
      <div @click="mode = 'normal'" class="cancel_dialog_return">戻る</div>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
  name: 'EditArea',
  props: [],
  components: {
  },
  data() {
    return {
      login_name: '',
      error_login_name: '',
      name: '',
      error_name: '',
      email: '',
      error_email: '',
      tel: '',
      error_tel: '',
      password: '',
      passwordConfirm: '',
      error_password: '',
      error_passwordConfirm: '',
      notice_password: '',
      passwordVisible: true,
      passwordUpdatable: false,
      error_profile: '',
      notice_profile: '',
      profileSavable: false,
      mode: 'normal',
      cancelDialogCancelAgree: false,
    }
  },
  watch: {
    login_name: function () {
      this.profileValidation()
    },
    name: function () {
      this.error_name = ''
      if (this.name.length == 0) {
        this.error_name = 'お名前を入力してください'
      }
      this.profileValidation()
    },
    email: function () {
      this.error_email = ''
      if (this.email.length == 0) {
        this.error_email = 'メールアドレスを入力してください'
      }
      this.profileValidation()
    },
    tel: function () {
      this.error_tel = ''
      if (this.tel.length == 0) {
        this.error_tel = '電話番号を入力してください'
      }
      this.profileValidation()
    },
    password: function () {
      this.error_password = ''
      if (this.password == '') {
        this.passwordUpdatable = false
        this.notice_password = ''
      } else if (this.password.length < 8) {
        this.passwordUpdatable = false
        this.error_password = '8文字以上にしてください。'
        this.notice_password = ''
      } else if (this.password.match(/[^a-zA-Z0-9]/) != null) {
        this.passwordUpdatable = false
        this.error_password = '半角英数のみで指定してください'
        this.notice_password = ''
      } else if (this.password.length > 14) {
        this.passwordUpdatable = true
        this.error_password = ''
        this.notice_password = ''
      } else if (this.password.match(/[0-9]/g) == null || this.password.match(/[0-9]/g).length < 2) {
        this.passwordUpdatable = false
        this.error_password = '数字を2文字以上入れてください'
        this.notice_password = ''
      } else if (this.password.match(/[a-zA-Z]/g) == null || this.password.match(/[a-zA-Z]/g).length < 2) {
        this.passwordUpdatable = false
        this.error_password = 'アルファベットを2文字以上入れてください'
        this.notice_password = ''
      } else {
        this.passwordUpdatable = true
        this.notice_password = ''
      }

      if (this.password == this.passwordConfirm) {
        this.error_passwordConfirm = ''
      } else {
        this.error_passwordConfirm = 'パスワードが一致しません'
        this.passwordUpdatable = false
      }
    },
    passwordConfirm: function () {
      this.error_password = ''
      if (this.password == '') {
        this.passwordUpdatable = false
        this.notice_password = ''
      } else if (this.password.length < 8) {
        this.passwordUpdatable = false
        this.error_password = '8文字以上にしてください。'
        this.notice_password = ''
      } else if (this.password.match(/[^a-zA-Z0-9]/) != null) {
        this.passwordUpdatable = false
        this.error_password = '半角英数のみで指定してください'
        this.notice_password = ''
      } else if (this.password.length > 14) {
        this.passwordUpdatable = true
        this.error_password = ''
        this.notice_password = ''
      } else if (this.password.match(/[0-9]/g) == null || this.password.match(/[0-9]/g).length < 2) {
        this.passwordUpdatable = false
        this.error_password = '数字を2文字以上入れてください'
        this.notice_password = ''
      } else if (this.password.match(/[a-zA-Z]/g) == null || this.password.match(/[a-zA-Z]/g).length < 2) {
        this.passwordUpdatable = false
        this.error_password = 'アルファベットを2文字以上入れてください'
        this.notice_password = ''
      } else {
        this.passwordUpdatable = true
        this.notice_password = ''
      }

      if (this.password == this.passwordConfirm) {
        this.error_passwordConfirm = ''
      } else {
        this.error_passwordConfirm = 'パスワードが一致しません'
        this.passwordUpdatable = false
      }
    },
  },
  computed: {
    ...mapGetters({
      customer_dump: 'customer/dump',
    }),
  },
  beforeMount: async function () {
  },
  mounted: async function () {
    let c = this.customer_dump
    this.login_name = c.login_name
    this.name = c.name
    this.email = c.email
    this.tel = c.tel
    this.profileSavable = false
  },
  methods: {
    async updatePassword() {
      let response = await Api.updateCustomerPassword(this, { 'password': this.password })
      this.password = ''
      this.passwordConfirm = ''
      if (response.status == 'OK') {
        this.passwordVisible = false
        this.$nextTick(() => {
          this.notice_password = 'パスワードを更新しました'
          this.error_password = ''
          this.passwordVisible = true
          this.$nextTick(() => {
            this.$refs.pw.focus()
          })
        })
      } else {
        this.$nextTick(() => {
          this.notice_password = ''
          this.error_password = 'パスワードの更新に失敗しました'
        })
      }
    },
    async updateProfile() {
      let payload = {
        login_name: this.login_name,
        name: this.name,
        email: this.email,
        tel: this.tel,
      }
      let response = await Api.updateCustomer(this, payload)
      if (response.status == 'OK') {
        this.$nextTick(() => {
          this.notice_profile = 'ユーザ情報を更新しました'
          this.error_profile = ''
          this.profileSavable = true
        })
      } else {
        this.$nextTick(() => {
          this.notice_profile = ''
          this.error_profile = 'ユーザ情報の更新に失敗しました'
          this.profileSavable = true
        })
      }
    },
    profileValidation() {
      this.notice_profile = ''
      let c = this.customer_dump
      if (
        this.login_name.trim() == c.login_name
        && this.name.trim() == c.name
        && this.email.trim() == c.email
        && this.tel.trim() == c.tel
        || (
          this.error_login_name != ''
          || this.error_name != ''
          || this.error_email != ''
          || this.error_tel != ''
        )
      ) {
        this.profileSavable = true
      } else {
        this.profileSavable = false
      }
    },
    customer_cancel() {
      this.mode = 'cancel_dialog'
    },
    async cancelDialogSubmit() {
      if (this.cancelDialogCancelAgree == false) return;
      if (confirm('最終確認です。\n本当に解約しますか？')) {
        let response = await Api.cancelCustomer(this)
        this.$router.push({ path: '/' })
      }
    },
  },
}
</script>

<style scoped>
.edit_area {
  width: 100%;
  height: 100%;
  background-color: #f6f6fa;
  overflow: auto;
  position: relative;
}

.input_group {
  text-align: left;
  width: calc(100% - 100px - 60px - 2px);
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: 50px;
  border: 1px solid gray;
  padding: 30px;
  border-radius: 20px;
  background-color: white;
}

.input_group>h2 {
  background-color: #455A64;
  color: white;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 15px;
  margin-top: 0;
}

.notice {
  font-size: 0.8em;
  font-weight: 700;
}

.error {
  color: red;
}

.input_box {
  margin-bottom: 10px;
}

.input_box>label {
  /* margin-left: 15px; */
  width: 100%;
  display: inline-block;
}

.input_box input[type="text"],
.input_box input[type="password"],
.input_box input[type="text"]:focus,
.input_box input[type="password"]:focus {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  font-size: 18px;
  padding: 1px 10px;
  line-height: 30px;
  border: none;
  border-radius: 4px;
  outline: 1px gray solid;
}

.password {
  width: 300px !important;
}

.password_label {
  display: inline-block;
  width: 150px !important;
}

.input_box button {
  box-sizing: border-box;
  border: 1px solid #00bcd4;
  line-height: 20px;
  display: block;
  background-color: #00bcd4;
  color: white;
  padding: 5px 20px;
  border-radius: 4px;
  font-weight: 700;
}

.input_box button:hover {
  line-height: 20px;
  display: block;
  background-color: white;
  color: #00bcd4;
}

/* .input_box button:disabled {
  border: 1px solid gray;
  line-height: 20px;
  display: block;
  background-color: #008a9c;
  color: #c0c0c0;
} */
.hiddenname {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  display: block !important;
  outline: none !important;
}

.cancel_box {
  text-align: left;
  width: calc(100% - 100px - 2px);
  margin-bottom: 30px;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 1.2em;
}

.cancel_dialog_return {
  font-size: 24px;
}

.config_alert {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
}

.config_alert>a {
  box-sizing: border-box;
  display: block;
  background: #00bcd4;
  color: white;
  text-decoration: none;
  margin: 30px 30px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 700;
  border-radius: 4px;
  width: calc(100% - 60px);
}

.alert_box {
  box-sizing: border-box;
  background-color: #ffb300;
  margin: 30px 30px;
  padding: 30px;
  width: calc(100% - 60px);
}

.alert_box>h2 {
  font-size: 30px;
}

.alert_box>p {
  font-size: 24px;
  font-weight: 700;
}

.alert_box>h2>img {
  position: relative;
  width: 32px;
  height: 32px;
  top: 5px;
  margin: 0 20px;
}

.alert_check_box {
  margin-bottom: 30px;
  font-size: 20px;
}

.alert_ok {
  box-sizing: border-box;
  border: 1px solid black;
  line-height: 32px;
  font-size: 24px;
  background-color: black;
  color: white;
  padding: 8px 30px;
  border-radius: 4px;
  font-weight: 700;
  margin-bottom: 30px;
}
</style>