<template>
    <div class="preview_area">
        <BaseFrame ref="baseframe" />
    </div>
</template>
  
<script>
import BaseFrame from '@/components/chat/components/base_frame.vue'
import { mapGetters } from 'vuex';

export default {
    name: 'PreviewArea',
    props: [],
    components: {
        BaseFrame,
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            visible: 'scenarioPlay/visible',
        })
    },
    created: function () {

    },
    mounted: function () {
        this.$store.commit('scenarioPlay/setChatbot', false)
        this.$nextTick(() => {
            this.$store.commit('scenarioPlay/setVisible', true)
        })
    },
    methods: {
    },
}
</script>

<style scoped>
::v-deep(*) {
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-family: "Hiragino Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;
    margin: 0;
    border: none;
    padding: 0;
    text-align: left;
    line-height: 1.42857143;
    letter-spacing: 0;
    user-select: none;
}
.preview_area {
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: white;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.list_area {
    position: absolute;
    left: calc(min(10%, 50px));
    width: 80%;
    max-width: 800px;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
}

.list_table {
    width: 100%;
    margin: 0;
    border-spacing: 0;
}

.list_table td {
    white-space: nowrap;
    border-right: 1px solid #999;
    border-bottom: 1px solid #999;
    /* background: #f6f6fa; */
    /* padding: 5px; */
}

.list_table th {
    white-space: nowrap;
    border-right: 1px solid #999;
    border-bottom: 1px solid #999;
    color: white;
    background: #808080;
    position: sticky;
    top: 0;
    left: 0;
}

.list_table tr:first-child th {
    border-top: 1px solid #999;
}

.list_table tr td:first-child {
    border-left: 1px solid #999;
}

.list_table tr th:first-child {
    border-left: 1px solid #999;
}

.list_table th {
    z-index: 1;
}

.list_table tr:hover {
    background-color: rgb(255, 194, 176);
}

.th1,
.col1 {
    width: 80px;
    min-width: 40px;
}

.th2,
.col2 {
    min-width: 30px;
    max-width: 580px;
}

.th3 {
    width: 120px;
    min-width: 120px;
}

.col1,
.col2 {
    text-decoration: underline;
    cursor: pointer;
    position: relative;
}

.col2>p {
    margin: 0;
    width: calc(100% - 24px);
    white-space: normal;
    word-break: break-all;
}

.col3 {
    position: relative;
}

.status {
    position: relative;
    width: 80px;
    border-radius: 10px;
    left: 20px;
}

.publish {
    background-color: blue;
    color: white;
}

.draft {
    background-color: red;
    color: white;
}

.list-move,
.list-leave-active,
.list-enter-active {
    transition: all 0.2s ease;
}

.list-leave-to,
.list-enter-from {
    opacity: 0;
}

.list-leave-active {
    position: absolute;
    width: 100%;
}
</style>