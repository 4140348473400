import axios from "axios";

async function login(page, login_name, password) {
    let apiResponse = null
    await axios
        .post(process.env.VUE_APP_API_URL, {
            method: 'login',
            data: {
                login_name: login_name,
                password: password,
            },
        },
            {
                headers: {
                    'content-type': 'application/json',
                }
            })
        .then(response => {
            if (response.status === 200) {
                if (response.data.login_status == 'LOGGED_IN') {
                    page.$store.commit('customer/customer_data', response.data.data)
                } else {
                    page.$store.commit('customer/clear')
                }
                apiResponse = response.data
            }
        })
    return apiResponse
}

async function passwordReset(page, login_name) {
    let apiResponse = null
    await axios
        .post(process.env.VUE_APP_API_URL, {
            method: 'passwordReset',
            data: {
                login_name: login_name,
            },
        },
            {
                headers: {
                    'content-type': 'application/json',
                }
            })
        .then(response => {
            if (response.status === 200) {
                if (response.data.login_status == 'LOGGED_IN') {
                    page.$store.commit('customer/customer_data', response.data.data)
                } else {
                    page.$store.commit('customer/clear')
                }
                apiResponse = response.data
            }
        })
    return apiResponse
}

async function getStatus(page) {
    let apiResponse = null
    await axios
        .post(process.env.VUE_APP_API_URL, {
            method: 'getStatus',
        },
            {
                headers: {
                    'content-type': 'application/json',
                }
            })
        .then(response => {
            if (response.status === 200) {
                if (response.data.login_status == 'LOGGED_IN') {
                    page.$store.commit('customer/customer_data', response.data.data)
                } else {
                    page.$store.commit('customer/clear')
                }
                apiResponse = response.data
            }
        })
    return apiResponse
}

async function logout(page) {
    let apiResponse = null
    await axios
        .post(process.env.VUE_APP_API_URL, {
            method: 'logout',
            data: {
            },
        },
            {
                headers: {
                    'content-type': 'application/json',
                }
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function scenarioGetList(page) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'getList',
        },
            {
                headers: {
                    'content-type': 'application/json',
                }
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function scenarioGet(page, id) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'get',
            data: {
                'id': id,
            },
        },
            {
                headers: {
                    'content-type': 'application/json',
                }
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function scenarioNew(page, scenario) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'new',
            data: scenario,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function scenarioUpdate(page, scenario) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'update',
            data: scenario,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function deleteScenario(page, id) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'delete',
            data: {
                'id': id,
            },
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function updateThanksMail(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'updateThanksMail',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function updateCvTag(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'updateCvTag',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function updateComplete(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'updateComplete',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function updateNotification(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'updateNotification',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function updateStatus(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'updateStatus',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function updateName(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'updateName',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function move(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'move',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function copy(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'scenario/', {
            method: 'copy',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function updateCustomerPassword(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'customer/', {
            method: 'updatePassword',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function updateCustomer(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'customer/', {
            method: 'update',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                if (response.data.login_status == 'LOGGED_IN') {
                    page.$store.commit('customer/customer_data', response.data.data)
                } else {
                    page.$store.commit('customer/clear')
                }
                apiResponse = response.data
            }
        })
    return apiResponse
}

async function cancelCustomer(page) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'customer/', {
            method: 'cancel',
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                if (response.data.login_status == 'LOGGED_IN') {
                    page.$store.commit('customer/customer_data', response.data.data)
                } else {
                    page.$store.commit('customer/clear')
                }
                apiResponse = response.data
            }
        })
    return apiResponse
}

async function answerGetListAll(page, id) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'answer/', {
            method: 'getListAll',
            data: {
                'id': id,
            },
        },
            {
                headers: {
                    'content-type': 'application/json',
                }
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

function getTemplate(id) {
    return templateList[id]
}

const templateList = [
    {
        'name': 'セミナー参加申し込みテンプレート',
        'title': 'セミナー申し込み',
        'thanksMail': {
            "text": "[お名前]　様\n\nこの度は、○○に\nお申込み頂き誠に有難うございます。\n\n○○でございます。\n\n○○へのお申込みを受け付けましたので、\nご連絡申し上げます。\n\n＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝\n◆お申し込み情報について\n下記情報にてお申し込みを承りました。\n\n○○ 様 お申し込み情報\n\n・お名前　　　　：[お名前]　様\n・フリガナ　　　：[フリガナ]\n・メールアドレス：[メールアドレス]\n・電話番号　　　：[電話番号]\n・ご参加日時　　：[日程]\n\n※ご参加方法につきましては\nお日にちが近くなりましたら別途ご連絡させていただきます。\n＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝\n\n万が一、お申し込み内容に不備等ございましたら\nご連絡いただけますと幸いです。\n\n＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝\n■メールが届かない方について【よくあるご質問】\n＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝\nメールが届かない方は迷惑メールに\n振り分けられている場合がございます。\n\n一度受け取ったことがあるメールでも\n稀に、迷惑メールフォルダなどに\n振り分けられることもございますため\nお手数をおかけいたしますが、\n【○○@○○】を\n受信できるよう設定をお願い致します。\n\n※ご利用環境を精査してのご案内はできかねますので、\nご了承くださいますようお願いいたします。\n\n引き続き宜しくお願い申し上げます。\n\n　\n◆----------------------------------------------------------◆\n○○\n◆----------------------------------------------------------◆\n・運営者：\n○○\nEmail○○\n◆----------------------------------------------------------◆\n",
            "subject": "【重要】[お名前]　様へ　○○へお申込みありがとうございます。",
            "fromName": "",
            "sendMail": true,
            "fromAddress": "",
            "configAlert": false,
          },
        'scenario': [
            {
                "type": "operator_talk",
                "data": {
                    "text": "まずはじめに、会社名とお名前をご入力ください。"
                },
                "id": 0,
                "key": 0
            },
            {
                "type": "input_name",
                "data": {
                    "company_display": true,
                    "name_display": true,
                    "kana_display": true,
                    "separate": true,
                    "company_required": true,
                    "name_required": true,
                    "kana_required": true,
                    "company_subject": "会社名",
                    "name_subject": "お名前",
                    "kana_subject": "フリガナ",
                    "placeholder_company": "株式会社○○商事",
                    "placeholder_name": "山田　花子",
                    "placeholder_kana": "ヤマダ　ハナコ",
                    "placeholder_name1": "山田",
                    "placeholder_name2": "花子",
                    "placeholder_kana1": "ヤマダ",
                    "placeholder_kana2": "ハナコ"
                },
                "id": 1,
                "key": 1
            },
            {
                "type": "operator_talk",
                "data": {
                    "text": "メールアドレスと電話番号をご入力ください。"
                },
                "id": 2,
                "key": 2
            },
            {
                "type": "input_email_and_tel",
                "data": {
                    "email_display": true,
                    "email_required": true,
                    "email_subject": "メールアドレス",
                    "placeholder_email": "email@example.com",
                    "tel_display": true,
                    "tel_required": true,
                    "tel_subject": "電話番号",
                    "placeholder_tel": "09012345678"
                },
                "id": 3,
                "key": 3
            },
            {
                "type": "operator_talk",
                "data": {
                    "text": "セミナーの日程をご選択ください。"
                },
                "id": 4,
                "key": 4
            },
            {
                "type": "select_schedule",
                "data": {
                    "schedule_required": true,
                    "schedule_subject": "日程",
                    "placeholder_schedule": "日程を選択してください",
                    "full_subject": "【満員御礼】",
                    "schedules": [
                        {
                            "id": 0,
                            "full_flg": true,
                            "year": 2022,
                            "month": 3,
                            "day": 4,
                            "start_hour": 13,
                            "start_minutes": 0,
                            "end_hour": 14,
                            "end_minutes": 0
                        },
                        {
                            "id": 1,
                            "full_flg": false,
                            "year": 2022,
                            "month": 3,
                            "day": 4,
                            "start_hour": 14,
                            "start_minutes": 0,
                            "end_hour": 15,
                            "end_minutes": 0
                        },
                        {
                            "id": 2,
                            "full_flg": false,
                            "year": 2022,
                            "month": 3,
                            "day": 5,
                            "start_hour": 14,
                            "start_minutes": 0,
                            "end_hour": 15,
                            "end_minutes": 0
                        }
                    ]
                },
                "id": 5,
                "key": 5
            },
            {
                "type": "operator_talk",
                "data": {
                    "text": "ご入力ありがとうございます。\n以下の内容でお間違いないかご確認ください。"
                },
                "id": 6,
                "key": 6
            },
            {
                "type": "confirm",
                "data": {
                    "confirm_subject": "内容確認"
                },
                "id": 7,
                "key": 7
            },
            {
                "type": "complete",
                "data": {
                    "complete_subject": "お申込みありがとうございました。",
                    "complete_text": "ご登録いただきましたメールアドレス宛てに案内メールをお送りしましたのでご確認下さい。\n※しばらくたってもご案内メールが見当たらない場合は、迷惑メールフォルダなどに振り分けられているか、間違ったアドレスでお手続きをされた可能性があります。\nその場合は、お手数ですが、再度メールアドレスをご確認の上、お申し込み手続きを行ってください。\n今後とも宜しくお願いいたします。"
                },
                "id": 8,
                "key": 8
            }
        ],
    },

]

async function univapayGetTokenInfo(page, data) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'chatbot/', {
            method: 'univapayGetTokenInfo',
            data: data,
        },
            {
                headers: {
                    'content-type': 'application/json',
                }
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
            }
        })
    return apiResponse
}

async function getPaymentInfoPubkey(page) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'payment/', {
            method: 'getInfoPubkey',
            data: {},
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function getPaymentInfo(page) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'payment/', {
            method: 'getInfo',
            data: {},
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}

async function updatePaymentInfo(page, payload) {
    let apiResponse = []
    await axios
        .post(process.env.VUE_APP_API_URL + 'payment/', {
            method: 'updateInfo',
            data: payload,
        },
            {
                headers: {
                    'content-type': 'application/json',
                },
            })
        .then(response => {
            if (response.status === 200) {
                apiResponse = response.data
                if (response.data.login_status == 'NOT_LOGGED_IN') {
                    page.$store.commit('customer/clear')
                }
            }
        })
    return apiResponse
}
export default {
    login,
    passwordReset,
    getStatus,
    logout,
    scenarioGetList,
    scenarioGet,
    scenarioNew,
    scenarioUpdate,
    deleteScenario,
    updateThanksMail,
    updateCvTag,
    updateComplete,
    updateNotification,
    updateStatus,
    updateName,
    move,
    copy,
    updateCustomerPassword,
    updateCustomer,
    cancelCustomer,
    answerGetListAll,
    getTemplate,
    univapayGetTokenInfo,
    getPaymentInfoPubkey,
    getPaymentInfo,
    updatePaymentInfo,
}
