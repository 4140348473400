<template>
  <transition appear :name="status == 'input' ? 'fachatbot_input_name' : ''">
    <div class="input_name">
      <div class="input_body">
        <div class="input_group" v-show="data['company_display']">
          <p class="title">{{ data.company_subject }}
            <span @click="status == 'edit' ? toggleRequire('company_required') : ''" v-if="data['company_required']"
              class="required">※必須</span>
            <span @click="status == 'edit' ? toggleRequire('company_required') : ''" v-if="!data['company_required']"
              class="optional">任意</span>
          </p>
          <div class="input_box">
            <input ref="company" v-if="status === 'input'" type="text" :id="id + '_company'" class="input"
              :placeholder="data.placeholder_company" v-model="company">
            <input v-if="status !== 'input'" type="text" :id="id + '_company'" class="input" disabled
              :placeholder="data.placeholder_company" v-model="company">
          </div>
          <label v-if="err_company != ''" class="err">{{ err_company }}</label>
        </div>
        <div class="input_group" v-show="!data['separate'] && data['name_display']">
          <p class="title">{{ data.name_subject }}
            <span @click="status == 'edit' ? toggleRequire('name_required') : ''" v-if="data['name_required']"
              class="required">※必須</span>
            <span @click="status == 'edit' ? toggleRequire('name_required') : ''" v-if="!data['name_required']"
              class="optional">任意</span>
          </p>
          <div class="input_box">
            <input ref="name" v-if="status === 'input'" type="text" :id="id + '_name'" class="input"
              :placeholder="data.placeholder_name" @input="handleNameInput" v-model="name">
            <input v-if="status !== 'input'" type="text" :id="id + '_name'" class="input" disabled
              :placeholder="data.placeholder_name" @input="handleNameInput" v-model="name">
          </div>
          <label v-if="err_name != ''" class="err">{{ err_name }}</label>
        </div>
        <div class="input_group" v-show="data['separate'] && data['name_display']">
          <p class="title">{{ data.name_subject }}
            <span @click="status == 'edit' ? toggleRequire('name_required') : ''" v-if="data['name_required']"
              class="required">※必須</span>
            <span @click="status == 'edit' ? toggleRequire('name_required') : ''" v-if="!data['name_required']"
              class="optional">任意</span>
          </p>
          <div class="input_box">
            <input ref="name" v-if="status === 'input'" type="text" :id="id + '_name1'" class="input_half"
              :placeholder="data.placeholder_name1" @input="handleNameInput1" v-model="name1">
            <input v-if="status !== 'input'" type="text" :id="id + '_name1'" class="input_half" disabled
              :placeholder="data.placeholder_name1" @input="handleNameInput1" v-model="name1">
            <input v-if="status === 'input'" type="text" :id="id + '_name2'" class="input_half"
              :placeholder="data.placeholder_name2" @input="handleNameInput2" v-model="name2">
            <input v-if="status !== 'input'" type="text" :id="id + '_name2'" class="input_half" disabled
              :placeholder="data.placeholder_name2" @input="handleNameInput2" v-model="name2">
          </div>
          <label v-if="err_name != ''" class="err">{{ err_name }}</label>
        </div>
        <div class="input_group" v-show="!data['separate'] && data['kana_display']">
          <p class="title">{{ data.kana_subject }}
            <span @click="status == 'edit' ? toggleRequire('kana_required') : ''" v-if="data['kana_required']"
              class="required">※必須</span>
            <span @click="status == 'edit' ? toggleRequire('kana_required') : ''" v-if="!data['kana_required']"
              class="optional">任意</span>
          </p>
          <div class="input_box">
            <input ref="kana" v-if="status === 'input'" type="text" :id="id + '_kana'" class="input"
              :placeholder="data.placeholder_kana" v-model="kana">
            <input v-if="status !== 'input'" type="text" :id="id + '_kana'" class="input" disabled
              :placeholder="data.placeholder_kana" v-model="kana">
          </div>
          <label v-if="err_kana != ''" class="err">{{ err_kana }}</label>
        </div>
        <div class="input_group" v-show="data['separate'] && data['kana_display']">
          <p class="title">{{ data.kana_subject }}
            <span @click="status == 'edit' ? toggleRequire('kana_required') : ''" v-if="data['kana_required']"
              class="required">※必須</span>
            <span @click="status == 'edit' ? toggleRequire('kana_required') : ''" v-if="!data['kana_required']"
              class="optional">任意</span>
          </p>
          <div class="input_box">
            <input ref="kana" v-if="status === 'input'" type="text" :id="id + '_kana1'" class="input_half"
              :placeholder="data.placeholder_kana1" v-model="kana1">
            <input v-if="status !== 'input'" type="text" :id="id + '_kana1'" class="input_half" disabled
              :placeholder="data.placeholder_kana1" v-model="kana1">
            <input v-if="status === 'input'" type="text" :id="id + '_kana2'" class="input_half"
              :placeholder="data.placeholder_kana2" v-model="kana2">
            <input v-if="status !== 'input'" type="text" :id="id + '_kana2'" class="input_half" disabled
              :placeholder="data.placeholder_kana2" v-model="kana2">
          </div>
          <label v-if="err_kana != ''" class="err">{{ err_kana }}</label>
        </div>
        <div class="next_message">
          <button v-if="status === 'input' && (btn_text == '次へ' || btn_text == '更新')" type="button" class="btn"
            @click="submit">
            {{ btn_text }}
          </button>
          <button v-if="status !== 'input' || btn_text == 'OK'" type="button" class="btn disabled" disabled>
            {{ btn_text }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import * as AutoKana from 'vanilla-autokana';
import * as AutoKana1 from 'vanilla-autokana';
import * as AutoKana2 from 'vanilla-autokana';
let autokana;
let autokana1;
let autokana2;

export default {
  name: 'InputName',
  props: ['id', 'data', 'status'],
  components: {
  },
  data() {
    return {
      company: '',
      name: '',
      name1: '',
      name2: '',
      kana: '',
      kana1: '',
      kana2: '',
      err_company: '',
      err_name: '',
      err_kana: '',
      btn_text: '次へ',
      edit_data: {},
      result: {},
    }
  },
  watch: {
    company: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    name: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    name1: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    name2: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    kana: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    kana1: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
    kana2: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
  },
  created: function () {
  },
  mounted: function () {
    autokana = AutoKana.bind('#' + this.id + '_name', '#' + this.id + '_kana', { katakana: true });
    autokana1 = AutoKana1.bind('#' + this.id + '_name1', '#' + this.id + '_kana1', { katakana: true });
    autokana2 = AutoKana2.bind('#' + this.id + '_name2', '#' + this.id + '_kana2', { katakana: true });
    if (this.status == 'input') {
      this.result = this.$store.getters['scenarioPlay/result'](this.id)
    }

    if (typeof this.result !== 'undefined') {
      if (typeof this.result.company !== 'undefined') {
        this.company = this.result.company;
      }
      if (typeof this.result.name !== 'undefined') {
        this.name = this.result.name;
      }
      if (typeof this.result.name1 !== 'undefined') {
        this.name1 = this.result.name1;
      }
      if (typeof this.result.name2 !== 'undefined') {
        this.name2 = this.result.name2;
      }
      if (typeof this.result.kana !== 'undefined') {
        this.kana = this.result.kana;
      }
      if (typeof this.result.kana1 !== 'undefined') {
        this.kana1 = this.result.kana1;
      }
      if (typeof this.result.kana2 !== 'undefined') {
        this.kana2 = this.result.kana2;
      }
    }
    if (this.status == 'input') {
      this.$nextTick(() => {
        if (this.data.company_display) {
          this.$refs.company.focus()
        } else if (this.data.name_display) {
          this.$refs.name.focus()
        } else if (this.data.kana_display) {
          this.$refs.kana.focus()
        }
      })
    }
  },
  methods: {
    afterEnter() {
      this.$emit("afterEnter");
    },
    toggleRequire(require) {
      this.$emit("toggleRequire", require);
    },
    submit() {
      this.company = this.company.trim();
      this.name = this.name.trim();
      this.name1 = this.name1.trim();
      this.name2 = this.name2.trim();
      this.kana = this.kana.trim();
      this.kana1 = this.kana1.trim();
      this.kana2 = this.kana2.trim();
      this.err_company = '';
      this.err_name = '';
      this.err_kana = '';
      if (this.data['company_display'] && this.data['company_required'] && this.company == '') {
        this.err_company = 'こちらは必須項目です。';
      }
      if (this.data['name_display']) {
        if (this.data['separate']) {
          if (this.data['name_required'] && this.name1 == '') {
            this.err_name = 'こちらは必須項目です。';
          } else if (this.data['name_required'] && this.name2 == '') {
            this.err_name = 'こちらは必須項目です。';
          }
        } else {
          if (this.data['name_required'] && this.name == '') {
            this.err_name = 'こちらは必須項目です。';
          }
        }
      }
      if (this.data['kana_display']) {
        if (this.data['separate']) {
          if (this.data['kana_required'] && this.kana1 == '') {
            this.err_kana = 'こちらは必須項目です。';
          } else if (this.data['kana_required'] && this.kana2 == '') {
            this.err_kana = 'こちらは必須項目です。';
          } else if (!this.kana1.match(/^[ァ-ヶー　]*$/)) {
            this.err_kana = '全角カタカナで入力してください。';
          } else if (!this.kana2.match(/^[ァ-ヶー　]*$/)) {
            this.err_kana = '全角カタカナで入力してください。';
          }
        } else {
          if (this.data['kana_required'] && this.kana == '') {
            this.err_kana = 'こちらは必須項目です。';
          } else if (!this.kana.match(/^[ァ-ヶー　]*$/)) {
            this.err_kana = '全角カタカナで入力してください。';
          }
        }
      }
      if (this.err_company == '' && this.err_name == '' && this.err_kana == '') {
        this.btn_text = 'OK';
        let data = { 'company': this.company, 'name': this.name, 'name1': this.name1, 'name2': this.name2, 'kana': this.kana, 'kana1': this.kana1, 'kana2': this.kana2 };
        this.$emit("submit", this.id, data);
      }
    },
    handleNameInput() {
      this.kana = autokana.getFurigana();
    },
    handleNameInput1() {
      this.kana1 = autokana1.getFurigana();
    },
    handleNameInput2() {
      this.kana2 = autokana2.getFurigana();
    },
  }
}
</script>

<style scoped>
.input_name {
  padding: 3px 0;
  overflow: visible;
  position: relative;
  width: 100%;
  z-index: 1;
}

.input_body {
  padding: 10px;
  background: #fcf9ce;
}

.input_group {
  width: 100%;
  word-break: break-word;
  margin-bottom: 10px;
}

.title {
  white-space: pre-line;
  padding: 3px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.input_box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.input {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 100%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
}

.input_half {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 48%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  outline: 0;
  margin: 0 0 0 5px;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: inline-block;
}

.input::placeholder {
  color: #aaa;
}

.input_half::placeholder {
  color: #aaa;
}

.next_message {
  text-align: right;
  float: right;
  padding-right: 0px;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  overflow: visible;
}

.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}

button:not(.disabled) {
  -webkit-animation: bounce 2.6s infinite ease-in-out;
  animation: bounce 2.6s infinite ease-in-out;
}

@keyframes bounce {
  5% {
    -webkit-transform: scale(1.2, .8);
  }

  10% {
    -webkit-transform: scale(.8, 1.2) translateY(-5px);
  }

  15% {
    -webkit-transform: scale(1, 1);
  }
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.required {
  color: red;
  font-size: 12px;
}

.optional {
  color: gray;
  font-size: 12px;
}

.err {
  color: red;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.fachatbot_input_name-enter-active {
  transition: right .38s .3s ease, opacity .38s .3s ease-in;
}

.fachatbot_input_name-enter-to {
  opacity: 1;
  right: 0;
}

.fachatbot_input_name-enter-from {
  opacity: 0;
  right: -100px;
}
</style>
