<template>
  <div class="fachatbot_component_wrapper">
    <div class="fachatbot_component_wrapper_component" @mouseenter="mouseEnterAction">
      <component v-if="mode != 'edit'" ref="component" :id="id" :is='chat.type' :data='chat.data' status="view">
      </component>
      <component v-if="mode == 'edit'" ref="component" :id="id" :is='editChat.type' :data='editChat.data' status="edit"
        @toggleRequire="toggleRequire"></component>
    </div>
    <div v-if="mode == 'mouseOver'" class="fachatbot_component_wrapper_edit_mask" @mouseleave="mouseOutAction"
      @click="toEdit">
      <p>クリックして編集する</p>
      <div class="icon-base moveup" v-if="chat.type != 'confirm' && chat.type != 'complete' && index != 0"
        @click.stop="moveUp">
        <div class="moveup-adj"><i class="icon-arrow-top"></i></div>
      </div>
      <div class="icon-base movedown"
        v-if="chat.type != 'confirm' && chat.type != 'complete' && index < $store.getters['scenario/length'] - 3"
        @click.stop="moveDown">
        <div class="movedown-adj"><i class="icon-arrow-bottom"></i></div>
      </div>
      <div class="icon-base remove" v-if="chat.type != 'confirm' && chat.type != 'complete'" @click.stop="remove">
        <div class="remove-adj"><i class="icon-close"></i></div>
      </div>

    </div>
    <div v-if="mode == 'otherEdit'" class="fachatbot_component_wrapper_other_edit_mask" @click="otherEditCancel"></div>
  </div>
</template>

<script>
import operator_talk from '@/components/chat/components/operator_talk.vue'
import input_name from '@/components/chat/components/input_name.vue'
import input_email_and_tel from '@/components/chat/components/input_email_and_tel.vue'
import select_schedule from '@/components/chat/components/select_schedule.vue'
import input_free_input from '@/components/chat/components/input_free_input.vue'
import input_free_text from '@/components/chat/components/input_free_text.vue'
import select_free_select from '@/components/chat/components/select_free_select.vue'
import select_free_date from '@/components/chat/components/select_free_date.vue'
import select_free_radio from '@/components/chat/components/select_free_radio.vue'
import select_free_checkbox from '@/components/chat/components/select_free_checkbox.vue'
import input_agreement from '@/components/chat/components/input_agreement.vue'
import confirm from '@/components/chat/components/confirm.vue'
import complete from '@/components/chat/components/complete.vue'
import input_credit_add_account from '@/components/chat/components/input_credit_add_account.vue'
import input_credit from '@/components/chat/components/input_credit.vue'
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
  name: "ComponentWrapper",
  props: ["id", "index", "chat"],
  components: {
    operator_talk,
    input_name,
    input_email_and_tel,
    select_schedule,
    input_free_input,
    input_free_text,
    select_free_select,
    select_free_date,
    select_free_radio,
    select_free_checkbox,
    input_agreement,
    confirm,
    complete,
    input_credit_add_account,
    input_credit,
  },
  data() {
    return {
      mode: 'view',
    };
  },
  computed: {
    ...mapGetters({
      editChat: 'editView/editChat',
      scenario_dump: 'scenario/dump',
    })
  },
  watch: {},
  mounted: function () {
    this.$store.watch(
      (state, getters) => getters['editView/status'],
      (newValue, oldValue) => {
        if (newValue == 'edit') {
          if (this.$store.getters['editView/editId'] == this.id) {
            this.mode = 'edit';
          } else {
            this.mode = 'otherEdit';
          }
        } else if (newValue == 'view') {
          this.mode = 'view';
        }
      }
    );
    this.$store.watch(
      (state, getters) => getters['editView/hoverId'],
      (newValue, oldValue) => {
        if (this.mode == 'view' || this.mode == 'mouseOver') {
          if (newValue == this.id) {
            this.mode = 'mouseOver';
          } else {
            this.mode = 'view';
          }
        }
      }
    );
  },
  methods: {
    async mouseEnterAction() {
      await this.$store.commit('editView/setHoverId', { 'hoverId': this.id });
    },
    async mouseOutAction() {
      await this.$store.commit('editView/setHoverId', { 'hoverId': null });
    },
    toEdit() {
      if (this.mode == 'mouseOver') {
        this.$store.dispatch('editView/toEdit', { 'editId': this.id })
      }
    },
    otherEditCancel() {
      if (this.mode == 'otherEdit') {
        this.$store.commit('editView/setStatus', { 'status': 'view' })
      }
    },
    async moveUp() {
      this.$store.commit('scenario/moveUp', { 'id': this.id });
      let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
      this.$store.commit('scenario/setScenario', s.data)
      this.$store.commit('scenario/setSaved', { "saved": true })
    },
    async moveDown() {
      this.$store.commit('scenario/moveDown', { 'id': this.id });
      let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
      this.$store.commit('scenario/setScenario', s.data)
      this.$store.commit('scenario/setSaved', { "saved": true })
    },
    async remove() {
      if (window.confirm("削除した要素は戻せません。よろしいですか？")) {
        this.$store.commit('scenario/remove', { 'id': this.id });
        let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
        this.$store.commit('scenario/setScenario', s.data)
        this.$store.commit('scenario/setSaved', { "saved": true })
      }
    },
    toggleRequire(require) {
      this.$store.commit('editView/toggleRequire', { 'require': require });
    },
  },
};
</script>

<style scoped>
.fachatbot_component_wrapper {
  margin: 0;
  padding: 0;
  padding-left: 5px;
  padding-right: 10px;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  clear: both;
  background-color: white;
}

.fachatbot_component_wrapper_component {
  position: relative;
  height: auto;
}

.fachatbot_component_wrapper_edit_mask {
  position: absolute;
  margin: 0;
  border: 3px solid rgba(61, 150, 214, 1);
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(61, 150, 214, 0.4);
  pointer-events: auto;
  color: rgba(255, 255, 255, 1);
  z-index: 2;
}

.fachatbot_component_wrapper_edit_mask p {
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  top: calc(50% - 1em);
  /* background-color: rgb(128, 128, 128, 0.7); */
  font-weight: 700;
  cursor: pointer;
}

.icon-base {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
}

.icon-base:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.moveup {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 78px;
  width: 24px;
  height: 24px;
}

.moveup-adj {
  position: relative;
  overflow: visible;
  top: 6px;
  left: 3px;
}

.movedown {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 44px;
  width: 24px;
  height: 24px;
}

.movedown-adj {
  position: relative;
  overflow: visible;
  top: 0;
  left: 3px;
}

.remove {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
}

.remove-adj {
  position: relative;
  overflow: visible;
  top: -2px;
}

.fachatbot_component_wrapper_other_edit_mask {
  position: absolute;
  display: inline-table;
  margin: 0;
  border: none;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  color: rgba(255, 255, 255, 1);
  /* vertical-align: middle; */
  z-index: 2;
}

.icon-close {
  width: 24px;
  height: 24px;
  position: relative;
}

.icon-close:before,
.icon-close:after {
  content: "";
  position: absolute;
  top: 12px;
  width: 24px;
  height: 4px;
  background-color: black;
}

.icon-close:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.icon-close:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.icon-arrow-right,
.icon-arrow-top,
.icon-arrow-bottom {
  display: inline-block;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-style: solid;
  border-width: 4px 4px 0 0;
  border-color: black;
  transform: rotate(45deg);
}

.icon-arrow-right {
  transform: rotate(45deg);
}

.icon-arrow-top {
  transform: rotate(-45deg);
}

.icon-arrow-bottom {
  transform: rotate(135deg);
}
</style>
