<template>
    <div class="dialog_area">
        <div>下記のコードを&lt;head&gt;タグ内に設置してください。</div>
        <button @click="copyTag" :disabled="!btnEnable">クリップボードにコピーする</button>
        <div v-if="visible" class="tag_area">
            <pre ref="tag_box" class="tag_box">{{ js }}</pre>
            <transition name="fadeout">
                <div v-show="viewResult" class="result_base" :class="resultClass">{{ resultText }}</div>
            </transition>
        </div>
        <div>チャットボットを起動するには、下記を例にjavascriptを実行してください。</div>
        <div v-if="visible" class="tag_area">
            <div>
                <pre class="tag_box">{{ openJs }}</pre>
            </div>
        </div>
        <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/riyoukaishi/" target="_blank">利用開始の設定方法はこちらから</a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'dialogChatbottag',
    props: ['id'],
    components: {
    },
    data() {
        return {
            scenario: {},
            js: '',
            js1: '',
            js2: '',
            js3: '',
            css1: '',
            btnEnable: true,
            viewResult: false,
            resultText: '',
            resultClass: '',
            visible: false,
        }
    },
    computed: {
        ...mapGetters({
            customer_id: 'customer/customer_id',
            scenario_id: 'scenario/id',
            scenario_tag: 'scenario/tag',
        })
    },
    created: async function () {
        let response = await Api.scenarioGet(this, this.scenario_id)
        let s = response.data

        this.text = s.cvTag
    },
    mounted: function () {
        // eslint-disable-next-line
        this.js = `<script src="${process.env.VUE_APP_CHATBOT_URL}js/fachatbot.js"></\script>\n`
        // eslint-disable-next-line
        this.js += `<script defer="defer" src="${process.env.VUE_APP_CHATBOT_URL}js/chunk-vendors.js"></\script>\n`
        // eslint-disable-next-line
        this.js += `<script defer="defer" src="${process.env.VUE_APP_CHATBOT_URL}js/app.js?id=${this.customer_id}&tag=${this.scenario_tag}"></\script>\n`
        // eslint-disable-next-line
        this.js += `<link href=\"${process.env.VUE_APP_CHATBOT_URL}css/app.css\" rel="stylesheet">\n`
        this.openJs = `例）　<button onclick="openChatbot(); return false;">開く</button>\n`
        this.openJs += `例）　<a onclick="openChatbot(); return false;">開く</a>\n`
        this.openJs += `例）　<img onclick="openChatbot(); return false;" src="" />\n`
        this.visible = true
    },
    methods: {
        async copyTag() {
            if (navigator.clipboard) {
                //クリップボードにコピーを実行
                try {
                    await navigator.clipboard.writeText(this.$refs.tag_box.innerText)
                    this.btnEnable = false
                    this.viewResult = true
                    this.resultText = 'タグをコピーしました'
                    this.$nextTick(() => {
                        this.btnEnable = true
                        this.viewResult = false
                    })
                } catch (e) {
                    this.btnEnable = false
                    this.viewResult = true
                    this.resultClass = 'result_error'
                    this.resultText = 'コピーに失敗しました'
                    this.$nextTick(() => {
                        this.btnEnable = true
                        this.viewResult = false
                    })
                }
            } else if (document.execCommand) {
                window.getSelection().removeAllRanges()
                let range = document.createRange()
                range.selectNodeContents(this.$refs.tag_box)
                window.getSelection().addRange(range)
                if (document.execCommand('copy')) {
                    this.btnEnable = false
                    this.viewResult = true
                    this.resultClass = ''
                    this.resultText = 'タグをコピーしました'
                    this.$nextTick(() => {
                        this.btnEnable = true
                        this.viewResult = false
                    })
                } else {
                    this.btnEnable = false
                    this.viewResult = true
                    this.resultClass = 'result_error'
                    this.resultText = 'コピーに失敗しました'
                    this.$nextTick(() => {
                        this.btnEnable = true
                        this.viewResult = false
                    })
                }
            } else {
                this.btnEnable = false
                this.viewResult = true
                this.resultClass = 'result_cant'
                this.resultText = 'クリップボードにアクセスできません'
                this.$nextTick(() => {
                    this.btnEnable = true
                    this.viewResult = false
                })
            }

        },
    },
}
</script>

<style scoped>
.dialog_area {
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: white;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    overflow: hidden;
    z-index: 3;
    padding: 0;
    overflow-y: auto;
}

.ok {
    position: absolute;
    right: 20px;
    bottom: 25px;
}

.dialog_input {
    left: 20px;
    resize: none;
    font-size: 15px;
    position: relative;
    text-align: left;
    user-select: text;
}

.tag_area {
    box-sizing: border-box;
    width: 100%;
    height: calc(132px + 20px);
    padding: 10px 30px;
    position: relative;
    margin-bottom: 30px;
}

.tag_box {
    font-size: 12px;
    line-height: 18px;
    width: calc(100% - 100px);
    height: 72px;
    border: 1px solid black;
    text-align: left;
    user-select: text;
    padding: 30px 50px;
    margin: 0;
    overflow: auto;
}

.result_base {
    position: absolute;
    width: 180px;
    height: 40px;
    line-height: 40px;
    top: calc(100% / 2 - 40px / 2);
    left: calc(100% / 2 - 180px / 2);
    text-align: center;
    background-color: #fcfcfc;
    border-radius: 20px;
}

.result_error {
    background-color: red;
    color: white;
}

.result_cant {
    background-color: red;
    color: white;
    width: 300px;
    left: calc(100% / 2 - 300px / 2);
}
.dialog_area > button {
    margin-top: 20px;
    margin-bottom: 20px;
    outline: none;
    border: none;
    color: white;
    background-color: #a5a4a4;
    padding: 5px 40px;
    border-radius: 4px;
    cursor: pointer;
}
.dialog_area > button:hover {
    background-color: #858484;;
}
.title_button {
    display: block;
    box-sizing: border-box;
    background: #00bcd4;
    color: white;
    text-decoration: none;
    margin: 0 30px;
    padding: 10px;
    font-weight: 700;
    border-radius: 4px;
    width: calc(100% - 60px);
}

.fadeout-leave-active {
    transition: all .3s .5s ease;
}

.fadeout-leave-from {
    opacity: 1;
}

.fadeout-leave-to {
    opacity: 0;
}
</style>