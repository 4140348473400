<template>
  <div class="edit_box">
    <div class="title_box">
      <div class="edit_title">年月日</div>
      <div class="right">
        <div class="edit_submit" @click="submit">項目を保存</div>
      </div>
    </div>

    <div class="input_group">
      <div class="input_box">
        <label for="free_date_required">
          <input type="checkbox" v-model="edit_data.free_date_required" />年月日を必須とする
        </label>


      </div>
      <div class="input_box">
        <label>タイプ
          <select v-model="edit_data.dateType">
            <option value="year_month_day">年月日</option>
            <option value="month_day">月日</option>
            <option value="year_month">年月</option>
            <option value="year">年のみ</option>
            <option value="month">月のみ</option>
            <option value="day">日のみ</option>
          </select>
        </label>
      </div>
      <div class="input_box">
        <label>タイトル
          <input type="text" v-model="edit_data.free_date_subject" /></label>
      </div>
      <div class="input_box" v-show="edit_data.dateType.match(/year/)">
        <label>未選択時・年
          <input type="text" v-model="edit_data.placeholder_free_date_year" /></label>
      </div>
      <div class="input_box" v-show="edit_data.dateType.match(/month/)">
        <label>未選択時・月
          <input type="text" v-model="edit_data.placeholder_free_date_month" /></label>
      </div>
      <div class="input_box" v-show="edit_data.dateType.match(/day/)">
        <label>未選択時・日
          <input type="text" v-model="edit_data.placeholder_free_date_day" /></label>
      </div>
    </div>

    <div class="input_group" v-show="edit_data.dateType.match(/year/)">
      <div class="input_box">
        <label>年の範囲</label>
      </div>
      <div class="input_box">
        <input v-show="edit_data.yearRange.startYear.type != 'now'" type="text" v-model="edit_data.yearRange.startYear.value" />
        <select v-model="edit_data.yearRange.startYear.type">
          <option value="year">年</option>
          <option value="now">今年</option>
          <!-- <option value="before">年前</option> -->
          <!-- <option value="after">年後</option> -->
        </select>
        <label>から</label>
      </div>
      <div class="input_box">
        <input v-show="edit_data.yearRange.endYear.type != 'now'" type="text" v-model="edit_data.yearRange.endYear.value" />
        <select v-model="edit_data.yearRange.endYear.type">
          <option value="year">年</option>
          <option value="now">今年</option>
          <!-- <option value="before">年前</option> -->
          <!-- <option value="after">年後</option> -->
        </select>
        <label>まで</label>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'EditSelectFreeSelect',
  props: ['chat'],
  components: {
  },
  data() {
    return {
      edit_data: {},
      lastKey: 0,
      text: '',
      hoverId: null,
    }
  },
  watch: {
  },
  beforeMount: function () {
    this.edit_data = this.chat.data;
    if (typeof this.edit_data.yearRange == 'undefined') {
      this.edit_data.dateType = 'year_month_day'
      this.edit_data.yearRange = {
        startYear: { value: 1900, type: 'year', },
        endYear: { value: 2100, type: 'year', },
      }
    }
  },
  mounted: function () {
  },
  methods: {
    async mouseEnterAction(id) {
      this.hoverId = id
    },
    async mouseOutAction() {
      this.hoverId = null
    },
    submit: function () {
      let data = this.edit_data;
      this.$emit("submit", data);
    },
    moveUp(id) {
      let i = this.edit_data.free_dates.findIndex(c => c.id == id);
      if (i > 0) {
        let tmp = this.edit_data.free_dates.splice(i, 1);
        this.edit_data.free_dates.splice(i - 1, 0, tmp[0]);
      }
    },
    moveDown(id) {
      let i = this.edit_data.free_dates.findIndex(c => c.id == id);
      if (i != -1) {
        let tmp = this.edit_data.free_dates.splice(i, 1);
        this.edit_data.free_dates.splice(i + 1, 0, tmp[0]);
      }
    },
    remove(id) {
      if (window.confirm("削除した要素は戻せません。よろしいですか？")) {
        let i = this.edit_data.free_dates.findIndex(c => c.id == id);
        if (i != -1) {
          this.edit_data.free_dates.splice(i, 1);
        }
      }
    },
    add: function () {
      if (this.text != '') {
        let tmp = {
          'id': this.edit_data.lastId++,
          'key': this.lastKey++,
          'text': this.text,
        }
        this.edit_data.free_dates.push(tmp);
        this.text = ''
      } else {
        alert('空の選択肢は追加できません')
      }
      this.$refs.input.focus()
    },
    dragList(event, dragIndex) {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('drag-index', dragIndex)
      event.dataTransfer.setData('type_select_free_date', 'select_free_date')
    },
    dragoverList(event, dropIndex) {
      const types = event.dataTransfer.types
      if (types.indexOf('type_select_free_date') != -1) {
        event.preventDefault()
      }
    },
    dragenterList(event, dropIndex) {
      const types = event.dataTransfer.types
      if (types.indexOf('type_select_free_date') != -1) {
        event.preventDefault()
      }
    },
    dropList(event, dropIndex) {
      const dragIndex = event.dataTransfer.getData('drag-index')
      const types = event.dataTransfer.types
      if (types.indexOf('type_select_free_date') != -1) {
        const deleteList = this.edit_data.free_dates.splice(dragIndex, 1);
        this.edit_data.free_dates.splice(dropIndex, 0, deleteList[0]);
      }
    },
  }
}
</script>

<style scoped>
.edit_box {
  margin: 20px;
}

.title_box {
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
}

.edit_title {
  position: absolute;
  color: white;
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
}

.right {
  position: absolute;
  height: 30px;
  right: 0;
}

.edit_submit {
  background-color: #42B5D1;
  border-color: #42B5D1;
  color: white;
  line-height: 24px;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 8px;
}

.input_group {
  overflow: hidden;
  border: 0 solid #ffb300;
  text-align: left;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  margin-bottom: 20px;
}

.input_box {
  border: 1px solid #ffb300;
  padding: 10px 10px;
  position: relative;
}

.input_box label {
  margin-bottom: 5px;
}

.input_box input[type="text"] {
  width: calc(100% - 10px);
  font-size: 18px;
}

.text {
  word-break: break-all;
}

.subject {
  color: white;
}

.free_date_box {
  width: 100%;
}

.free_date_btn {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  font-weight: 700;
  background-color: gray;
  color: white;
}

.free_date_btn:hover {
  background-color: #C0C0C0;
}

.free_date_up {
  top: 10px;
  right: 80px;
}

.free_date_down {
  top: 10px;
  right: 50px;
}

.free_date_remove {
  top: 10px;
  right: 20px;
}

.select-move,
/* 移動する要素にトランジションを適用 */
.select-enter-active,
.select-leave-active {
  transition: all 0.5s ease;
}

.select-leave-active {
  position: absolute;
  width: 100%;
  opacity: 0;
}
</style>
