<template>
    <div class="side_area">
        <div class="back_to_control" @click="backToControl">
            <span class="material-symbols-outlined back_icon">undo</span><span>戻る</span>
        </div>
        <h1>プレビュー操作</h1>
        <div class="menu_item" @click="open">
            <span class="material-symbols-outlined menu_icon">open_in_browser</span>
            <span>チャットオープン</span>
        </div>
        <div class="menu_item" @click="reset">
            <span class="material-symbols-outlined menu_icon">restart_alt</span>
            <span>リセット</span>
        </div>

        <!-- <pre style="color:white;text-align: left;user-select:text;">{{ $store.state.scenarioPlay.results }}</pre> -->
        <!-- <pre style="color:white;text-align: left;user-select:text;">{{ $store.state.scenarioPlay }}</pre> -->
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'SideArea',
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
    },
    mounted: function () {
    },
    methods: {
        backToControl() {
            let path = this.$store.getters['scenarioPlay/backTo']
            this.$router.push({ path })
        },
        open () {
            this.$store.commit('scenarioPlay/setVisible', true)
        },
        reset () {
            this.$store.commit('scenarioPlay/clear')
            this.$store.commit('scenarioPlay/setVisible', false)
        },
    },
}
</script>

<style scoped>
.side_area {
    width: 100%;
    height: 100%;
    background-color: #455A64;
    user-select: none;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.side_area h1 {
    color: white;
}
.edit_window {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #ffb300;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.edit_window-enter-active,
.edit_window-leave-active {
    transition: left .25s ease;
}

.edit_window-enter-to,
.edit_window-leave-from {
    left: 0;
}

.edit_window-enter-from,
.edit_window-leave-to {
    left: -100%;
}

.menu_item {
    position: relative;
    text-align: left;
    width: 70%;
    left: 10%;
    background-color: white;
    padding: 9px 19px;
    margin: 10px 0;
    border-radius: 5px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
}

.menu_item:hover {
    color: aqua;
    border-color: aqua;
}

.back_to_control {
    font-size: 36px;
    text-align: left;
    color: white;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.material-symbols-outlined {
    padding: 10px;
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

.back_icon {
    font-size: 36px;
}

.menu_icon {
    position: relative;
    top:4px;
    font-size: 20px;
    padding: 0;
    line-height: 18px;
}
</style>