export default {
    namespaced: true,
    state: {


        scenario: [],
        status: 'draft',
        id: null,
        name: '',
        title: '',

        questions: 0,
        progress: 0,
        results: {},
        mode: 'input',
        visible: false,


        backTo: '',

        chatbot: true,
        customerId: null,
    },
    getters: {
        scenario(state) { return state.scenario },
        lastId(state) { return state.lastId++ },
        lastKey(state) { return state.lastKey++ },
        status(state) { return state.status },
        id(state) { return state.id },
        customerId(state) { return state.customerId },
        name(state) { return state.name },
        title(state) { return state.title },
        saved(state) { return state.saved },
        backTo(state) { return state.backTo },
        questions(state) { return state.questions },
        result(state) {
            return (id) => {
                return state.results[id] == undefined ? {} : JSON.parse(JSON.stringify(state.results[id].result))
            }
        },
        results(state) { return state.results },
        resultsProcessed(state) {
            let result = []
            let r, s, dow, result_text
            state.scenario.forEach(c => {
                r = state.results[c.id]
                switch (c.type) {
                    case 'input_name':
                        if (c.data.company_display) {
                            result.push({ 'id': c.id, 'type': 'input_name', 'item': 'company', 'subject': c.data.company_subject, 'result': r.result.company, 'result_text': r.result.company.trim() })
                        }
                        if (c.data.name_display && c.data.separate == true) {
                            result.push({ 'id': c.id, 'type': 'input_name', 'item': 'name', 'subject': c.data.name_subject, 'result': { 'name1': r.result.name1, 'name2': r.result.name2 }, 'result_text': (r.result.name1 + '　' + r.result.name2).trim() })
                        }
                        if (c.data.name_display && c.data.separate == false) {
                            result.push({ 'id': c.id, 'type': 'input_name', 'item': 'name', 'subject': c.data.name_subject, 'result': r.result.name, 'result_text': r.result.name.trim() })
                        }
                        if (c.data.kana_display && c.data.separate == true) {
                            result.push({ 'id': c.id, 'type': 'input_name', 'item': 'kana', 'subject': c.data.kana_subject, 'result': { 'kana1': r.result.kana1, 'kana2': r.result.kana2 }, 'result_text': (r.result.kana1 + '　' + r.result.kana2).trim() })
                        }
                        if (c.data.kana_display && c.data.separate == false) {
                            result.push({ 'id': c.id, 'type': 'input_name', 'item': 'kana', 'subject': c.data.kana_subject, 'result': r.result.kana, 'result_text': r.result.kana.trim() })
                        }
                        break;
                    case 'input_email_and_tel':
                        if (c.data.email_display) {
                            result.push({ 'id': c.id, 'type': 'input_email_and_tel', 'item': 'email', 'subject': c.data.email_subject, 'result': r.result.email, 'result_text': r.result.email.trim() })
                        }
                        if (c.data.tel_display) {
                            result.push({ 'id': c.id, 'type': 'input_email_and_tel', 'item': 'tel', 'subject': c.data.tel_subject, 'result': r.result.tel, 'result_text': r.result.tel.trim() })
                        }
                        break;
                    case 'select_schedule':
                        s = r.result.date
                        dow = ["日", "月", "火", "水", "木", "金", "土"][(new Date(s.year, s.month - 1, s.day)).getDay()]
                        result_text = (s.year + '/' + s.month + '/' + s.day + '(' + dow + ') '
                            + ('0' + s.start_hour).slice(-2) + ':' + ('0' + s.start_minutes).slice(-2) + ' - '
                            + ('0' + s.end_hour).slice(-2) + ':' + ('0' + s.end_minutes).slice(-2))
                        result.push({
                            'id': c.id, 'type': 'select_schedule', 'item': 'date', 'subject': c.data.schedule_subject, 'result': r.result.date.year == undefined ? null : {
                                year: r.result.date.year,
                                month: r.result.date.month,
                                day: r.result.date.day,
                                start_hour: r.result.date.start_hour,
                                start_minutes: r.result.date.start_minutes,
                                end_hour: r.result.date.end_hour,
                                end_minutes: r.result.date.end_minutes,
                                dow: r.result.dow,
                            }, 'result_text': result_text.trim()
                        })
                        break;
                    case 'input_free_input':
                        result.push({ 'id': c.id, 'type': 'input_free_input', 'item': 'free_input', 'subject': c.data.free_input_subject, 'result': r.result.free_input, 'result_text': r.result.free_input.trim() })
                        break;
                    case 'input_free_text':
                        result.push({ 'id': c.id, 'type': 'input_free_text', 'item': 'free_text', 'subject': c.data.free_text_subject, 'result': r.result.free_text, 'result_text': r.result.free_text.trim() })
                        break;
                    case 'select_free_select':
                        result.push({ 'id': c.id, 'type': 'select_free_select', 'item': 'free_select', 'subject': c.data.free_select_subject, 'result': r.result.free_select.text == undefined ? null : r.result.free_select.text, 'result_text': r.result.free_select.text == undefined ? '' : r.result.free_select.text.trim() })
                        break;
                    case 'select_free_radio':
                        result.push({ 'id': c.id, 'type': 'select_free_radio', 'item': 'free_radios', 'subject': c.data.free_radio_subject, 'result': r.result.free_radios.text == undefined ? null : r.result.free_radios.text, 'result_text': r.result.free_radios.text == undefined ? '' : r.result.free_radios.text.trim() })
                        break;
                    case 'select_free_checkbox':
                        result.push({ 'id': c.id, 'type': 'select_free_checkbox', 'item': 'free_checkboxs', 'subject': c.data.free_checkbox_subject, 'result': r.result.free_checkboxs.map((obj) => obj.text), 'result_text': r.result.free_checkboxs.map((obj) => obj.text) })
                        break;
                    case 'select_free_date':
                        if (r.result.free_date_year == '' && r.result.free_date_month == '' && r.result.free_date_day == '') {
                            result_text = ''
                        } else {
                            s = r.result
                            switch (r.result.free_date_type) {
                                case 'month_day':
                                    result_text = (s.free_date_month.text + '/' + s.free_date_day.text)
                                    break
                                case 'year_month':
                                    result_text = (s.free_date_year.text + '/' + s.free_date_month.text)
                                    break
                                case 'year':
                                    result_text = (s.free_date_year.text)
                                    break
                                case 'month':
                                    result_text = (s.free_date_month.text)
                                    break
                                case 'day':
                                    result_text = (s.free_date_day.text)
                                    break
                                case 'year_month_day':
                                default:
                                    dow = ["日", "月", "火", "水", "木", "金", "土"][(new Date(s.free_date_year.text, s.free_date_month.text - 1, s.free_date_day.text)).getDay()]
                                    result_text = (s.free_date_year.text + '/' + s.free_date_month.text + '/' + s.free_date_day.text + '(' + dow + ')')
                                    break
                            }
                        }
                        result.push({
                            'id': c.id, 'type': 'select_free_date', 'item': 'free_date', 'subject': c.data.free_date_subject, 'result': r.result.free_date_day == '' ? null : {
                                year: r.result.free_date_year.text,
                                month: r.result.free_date_month.text,
                                day: r.result.free_date_day.text,
                            }, 'result_text': String(result_text).trim()
                        })
                        break;
                    case 'input_agreement':
                        result.push({ 'id': c.id, 'type': 'input_agreement', 'item': 'agreement', 'subject': c.data.agreement_subject, 'result': r.result.agreement, 'result_text': r.result.agreement == true ? '同意する' : '同意しない' })
                        break;
                    case 'input_credit':
                        result.push({ 'id': c.id, 'type': 'input_credit', 'item': 'payment_method', 'subject': 'お支払方法', 'result': r.result.payment_method, 'result_text': 'クレジットカード' })
                        result.push({ 'id': c.id, 'type': 'input_credit', 'item': 'card_number', 'subject': 'カード番号末尾4桁', 'result': r.result.card_number, 'result_text': r.result.card_number })
                        result.push({ 'id': c.id, 'type': 'input_credit', 'item': 'amount', 'subject': 'お支払金額', 'result': r.result.amount, 'result_text': Number(r.result.amount).toLocaleString() + ' 円' })
                        result.push({ 'id': c.id, 'type': 'input_credit', 'item': 'univapay_transaction_token', 'subject': 'トランザクショントークン', 'result': r.result.univapay_transaction_token, 'result_text': '' })
                        break;
                    default:
                        break;
                }
            })
            return result
        },
        progress(state) { return state.progress },
        mode(state) { return state.mode },
        visible(state) { return state.visible },
        getChat(state) {
            return (id) => {
                let c = state.scenario.find(c => c.id == id)
                if (c == undefined) { c = {} }
                return JSON.parse(JSON.stringify(c))
            }
        },
        length(state) {
            return state.scenario.length
        },
        chatbot(state) { return state.chatbot },
    },
    mutations: {
        clear: function (state, payload) {
            state.results = {}
        },
        setVisible: function (state, visible) {
            state.visible = visible
        },
        setBackTo: function (state, backTo) {
            state.backTo = backTo
        },
        setProgress: function (state, progress) {
            state.progress = progress
        },
        setResult: function (state, payload) {
            state.results[payload.id] = payload
        },
        //  payload: {
        //      scenario,
        //      id,
        //      lastId,
        //      lastKey,
        //      title,
        //  }
        setScenario: function (state, payload) {
            state.scenario = payload.scenario
            state.id = payload.id
            state.lastId = payload.lastId
            state.lastKey = payload.lastKey
            state.status = payload.status
            state.name = payload.name
            state.title = payload.title
            state.results = {}
            state.mode = 'input'
            state.visible = false

            let q = 0
            state.questions = 0
            state.scenario.forEach(c => {
                switch (c.type) {
                    default:
                        q++
                        state.questions++
                        break
                    case 'confirm':
                    case 'complete':
                    case 'operator_talk':
                        break
                }
                c.progress = q
            });
            state.progress = 0
        },
        setChatbot: function (state, payload) {
            state.chatbot = payload
        },
        setCustomerId: function (state, payload) {
            state.customerId = payload
        },
    },
    actions: {
    },
}
