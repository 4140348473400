<template>
  <transition appear :name="status == 'input' ? 'fachatbot_operator_talk' : ''" v-on:after-enter="afterEnter">
    <div class="fachatbot_operator_talk">
      <div class="fachatbot_operator_talk_avater">
        <img src="/chat_avater.png" />
      </div>
      <div class="fachatbot_operator_talk_msg-group">
        <p class="fachatbot_operator_talk_msg">{{ data.text }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'OperatorTalk',
  props: ['id', 'data', 'status'],
  components: {
  },
  data() {
    return {
      index: 1,
    }
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    afterEnter() {
      this.$emit("afterEnter");
      this.$emit("submit", this.id, {});
    },
  }
}
</script>

<style scoped>
.fachatbot_operator_talk {
  transform-origin: left bottom;
  padding: 3px 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%;
  clear: both;
  z-index: 1;
}

.fachatbot_operator_talk::after {
  content: "";
  clear: both;
  display: block;
}

.fachatbot_operator_talk_avater {
  float: left;
  width: 42px;
  height: 42px;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.fachatbot_operator_talk_avater img {
  width: 42px;
  height: 42px;
}

.fachatbot_operator_talk_msg-group {
  overflow: visible;
  width: calc(100% - 46px);
  float: right;
  font-size: 13px;
  font-weight: 400;
}

.fachatbot_operator_talk_msg::before {
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  left: -5px;
  width: 12px;
  height: 18px;
  background: url(@/assets/talk_before.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.fachatbot_operator_talk_msg {
  overflow: visible;
  background-color: #d1e49d;
  border-radius: 20px;
  position: relative;
  display: inline-block;
  white-space: pre-line;
  padding: 8px;
  font-size: 16px;
  line-height: 1.4;
  -webkit-tap-highlight-color: transparent;
  word-break: break-word;
}

.fachatbot_operator_talk-enter-active {
  transition: opacity 1.5s, transform 1s;
}

.fachatbot_operator_talk-enter-to {
  opacity: 1;
  transform: scale(1, 1);
}

.fachatbot_operator_talk-enter-from {
  opacity: 0;
  transform: scale(0.5, 0.5);
}
</style>
