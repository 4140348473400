<template>
    <div class="side_area">
        <div class="back_to_control" @click="backToControl"><span
                class="material-symbols-outlined">arrow_back_ios</span><span>戻る</span></div>
        <div v-if="menu == 'EditArea'">
            <div v-for="(item, index) in menu_items" :key="index">
                <div v-if="item.display" class="menu_item" @click="add(item.type)" draggable="true"
                    @dragstart="dragList($event, item.type)" @dragend="dragendList">
                    <div><img :src="require(`@/assets/system-icon/${item.icon}`)" class="item_icon" /> {{ item.subject }}
                    </div>
                </div>
            </div>
            <div v-if="admin">
                <hr />
                <h2>管理者機能</h2>
                <div>
                    <div class="menu_item" @click.prevent="toggleSystemRegist">
                        <div>
                            <label>
                                <input type="checkbox" v-model="systemRegist" @click.stop="toggleSystemRegist"/>
                                このチャットボットでシステムユーザーを登録する
                            </label>
                        </div>
                    </div>
                </div>
                <div v-for="(item, index) in menu_items_admin" :key="index">
                    <div v-if="item.display" class="menu_item" @click="add(item.type)" draggable="true"
                        @dragstart="dragList($event, item.type)" @dragend="dragendList">
                        <div>￥ {{ item.subject }}</div>
                    </div>
                </div>
            </div>
            <transition name="edit_window">
                <div v-if="mode == 'edit'" class="edit_window">
                    <component :is='editChat.type' :chat='editChat' @submit="submit"></component>
                </div>
            </transition>
        </div>


        <div v-if="false">
            <hr />
            <h1 style="color:gray;">開発補助機能</h1>
            <div class="menu_item" @click="clearTemplate">
                <div>テンプレートクリア</div>
            </div>
            <div class="menu_item" @click="setTemplate">
                <div>テンプレートから設定</div>
            </div>
            <div class="menu_item" @click="setScenario">
                <div>保存済みシナリオ読み込み</div>
            </div>
            <div class="menu_item" @click="scenarioGet">
                <div>シナリオダンプ</div>
            </div>
        </div>
        <!-- <pre style="color:white;text-align: left;user-select:text;">{{ $store.state.editView }}</pre> -->
    </div>
</template>
  
<script>
import operator_talk from '@/components/scenario/edit/operator_talk.vue';
import input_name from '@/components/scenario/edit/input_name.vue';
import input_email_and_tel from '@/components/scenario/edit/input_email_and_tel.vue';
import select_schedule from '@/components/scenario/edit/select_schedule.vue';
import select_free_select from '@/components/scenario/edit/select_free_select.vue';
import select_free_date from '@/components/scenario/edit/select_free_date.vue';
import select_free_radio from '@/components/scenario/edit/select_free_radio.vue';
import select_free_checkbox from '@/components/scenario/edit/select_free_checkbox.vue';
import input_free_input from '@/components/scenario/edit/input_free_input.vue';
import input_free_text from '@/components/scenario/edit/input_free_text.vue';
import header_base from '@/components/scenario/edit/header_base.vue';
import input_agreement from '@/components/scenario/edit/input_agreement.vue';
import confirm from '@/components/scenario/edit/confirm.vue';
import complete from '@/components/scenario/edit/complete.vue';
import input_credit_add_account from '@/components/scenario/edit/input_credit_add_account.vue';
import input_credit from '@/components/scenario/edit/input_credit.vue';
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

const componentLabel = {
    'operator_talk': 'オペレータートーク',
    'input_name': '会社名・姓名',
    'input_email_and_tel': 'メール・Tel',
    'select_schedule': 'スケジュール選択',
    'confirm': '確認',
    'complete': '完了',
}

export default {
    name: 'SideArea',
    components: {
        operator_talk,
        input_name,
        input_email_and_tel,
        select_schedule,
        select_free_select,
        select_free_date,
        select_free_radio,
        select_free_checkbox,
        input_free_input,
        input_free_text,
        header_base,
        input_agreement,
        confirm,
        complete,
        input_credit_add_account,
        input_credit,
    },
    data() {
        return {
            mode: 'view',
            menu_items: [
                { display: true, type: 'operator_talk', subject: 'オペレーター', icon: 'purson.png' },
                { display: true, type: 'input_name', subject: 'お名前', icon: 'purson.png' },
                { display: true, type: 'input_email_and_tel', subject: 'メール・電話', icon: 'mail.png' },
                { display: true, type: 'select_schedule', subject: '日程', icon: 'calendar.png' },
                { display: true, type: 'input_free_input', subject: '一行テキスト', icon: 'textbox.png' },
                { display: true, type: 'input_free_text', subject: '複数行テキスト', icon: 'list.png' },
                { display: true, type: 'select_free_select', subject: 'プルダウン', icon: 'pulldown.png' },
                { display: true, type: 'select_free_radio', subject: 'ラジオボタン', icon: 'radio.png' },
                { display: true, type: 'select_free_checkbox', subject: 'チェックボックス', icon: 'checkbox_on.png' },
                { display: true, type: 'select_free_date', subject: '年月日', icon: 'calendar.png' },
                { display: true, type: 'input_agreement', subject: '同意画面', icon: 'checkbox_on.png' },
                { display: true, type: 'input_credit', subject: 'カード情報入力', icon: 'currency_yen.png' },
                { display: false, type: 'confirm', subject: '確認画面', icon: '' },
                { display: false, type: 'complete', subject: '完了画面', icon: '' },
            ],
            menu_items_admin: [
                // { display: true, type: 'input_credit_add_account', subject: 'カード情報入力（アカウント追加）', icon: 'currency_yen' },
            ],
            systemRegist: false,
        }
    },
    computed: {
        ...mapGetters({
            editChat: 'editView/editChat',
            menu: 'editView/menu',
            scenario_dump: 'scenario/dump',
            scenario_systemRegist: 'scenario/systemRegist',
            admin: 'customer/admin',
        })
    },
    mounted: function () {
        this.$store.watch(
            (state, getters) => getters['editView/status'],
            (newValue, oldValue) => {
                if (newValue == 'edit') {
                    let id = this.$store.getters['editView/editId']
                    if (id == -1) {
                        this.chat = {
                            type: 'header_base',
                        }
                    } else {
                        this.chat = this.$store.getters['scenario/getChat'](id)
                    }
                }
                this.mode = newValue
            }
        );
        this.systemRegist = this.scenario_systemRegist == 0 ? false : true
    },
    methods: {
        submit: async function (data) {
            this.editChat.data = data;
            if (this.$store.getters['editView/editId'] == -1) {
                this.$store.commit('scenario/setTitle', { title: data.title })
            } else {
                this.$store.commit('scenario/replaceChat', { 'chat': this.editChat })
            }
            this.$store.commit('editView/setStatus', { 'status': 'view' })
            this.$store.commit('editView/setHoverId', { 'hoverId': null })
            let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
            this.$store.commit('scenario/setScenario', s.data)
            this.$store.commit('scenario/setSaved', { "saved": true })
        },
        getDefaultChat(type) {
            let chat = {}
            switch (type) {
                case 'operator_talk':
                    chat = {
                        type: "operator_talk",
                        data: {
                            text: "メッセージを編集してください。"
                        },
                    }
                    break
                case 'input_name':
                    chat = {
                        type: "input_name",
                        data: {
                            company_display: true,
                            name_display: true,
                            kana_display: true,
                            separate: true,
                            company_required: true,
                            name_required: true,
                            kana_required: true,
                            company_subject: "会社名",
                            name_subject: "お名前",
                            kana_subject: "フリガナ",
                            placeholder_company: "株式会社○○商事",
                            placeholder_name: "山田　花子",
                            placeholder_kana: "ヤマダ　ハナコ",
                            placeholder_name1: "山田",
                            placeholder_name2: "花子",
                            placeholder_kana1: "ヤマダ",
                            placeholder_kana2: "ハナコ"
                        },
                    }
                    break
                case 'input_email_and_tel':
                    chat = {
                        type: "input_email_and_tel",
                        data: {
                            email_display: true,
                            email_required: true,
                            email_subject: "メールアドレス",
                            placeholder_email: "email@example.com",
                            tel_display: true,
                            tel_required: true,
                            tel_subject: "電話番号",
                            placeholder_tel: "09012345678"
                        },
                    }
                    break
                case 'select_schedule':
                    chat = {
                        type: "select_schedule",
                        data: {
                            schedule_required: true,
                            schedule_subject: "日程",
                            placeholder_schedule: "日程を選択してください",
                            full_subject: "【満員御礼】",
                            schedules: [],
                            lastId: 0,
                        },
                    }
                    break
                case 'input_free_input':
                    chat = {
                        type: "input_free_input",
                        data: {
                            free_input_required: true,
                            free_input_subject: "一行テキスト",
                            placeholder_free_input: "入力例",
                        },
                    }
                    break
                case 'input_free_text':
                    chat = {
                        type: "input_free_text",
                        data: {
                            free_text_required: true,
                            free_text_subject: "複数行テキスト",
                            placeholder_free_text: "入力例",
                            free_text_display_lines: 5,
                        },
                    }
                    break
                case 'select_free_select':
                    chat = {
                        type: "select_free_select",
                        data: {
                            free_select_required: true,
                            free_select_subject: "プルダウン",
                            placeholder_free_select: "選択してください",
                            free_selects: [],
                            lastId: 0,
                        },
                    }
                    break
                case 'select_free_date':
                    chat = {
                        type: "select_free_date",
                        data: {
                            free_date_required: true,
                            free_date_subject: "年月日",
                            placeholder_free_date_year: "年",
                            placeholder_free_date_month: "月",
                            placeholder_free_date_day: "日",
                            free_date_year: '',
                            free_date_month: '',
                            free_date_day: '',
                            dateType: 'year_month_day',
                            yearRange: {
                                startYear: { value: 1930, type: 'year', },
                                endYear: { value: new Date().getFullYear(), type: 'year', },
                            },
                        },
                    }
                    break
                case 'select_free_radio':
                    chat = {
                        type: "select_free_radio",
                        data: {
                            free_radio_required: true,
                            free_radio_subject: "ラジオボタン",
                            free_radios: [],
                            lastId: 0,
                        },
                    }
                    break
                case 'select_free_checkbox':
                    chat = {
                        type: "select_free_checkbox",
                        data: {
                            free_checkbox_required: true,
                            free_checkbox_subject: "チェックボックス",
                            free_checkboxs: [],
                            lastId: 0,
                        },
                    }
                    break
                case 'input_agreement':
                    chat = {
                        type: "input_agreement",
                        data: {
                            agreement_subject: "項目名",
                            agreement_text: "同意文章",
                            agreement_label: "上記に同意する",
                            agreement_display_lines: 10,
                            lastId: 0,
                        },
                    }
                    break
                case 'confirm':
                    chat = {
                        type: "confirm",
                        data: {
                            confirm_subject: '内容確認',
                        },
                    }
                    break
                case 'complete':
                    chat = {
                        type: "complete",
                        data: {
                            complete_subject: 'お申込みありがとうございました。',
                            complete_text: 'ご登録いただきましたメールアドレス宛てに案内メールをお送りしましたのでご確認下さい。\n'
                                + '※しばらくたってもご案内メールが見当たらない場合は、迷惑メールフォルダなどに振り分けられているか、間違ったアドレスでお手続きをされた可能性があります。\n'
                                + 'その場合は、お手数ですが、再度メールアドレスをご確認の上、お申し込み手続きを行ってください。\n'
                                + '今後とも宜しくお願いいたします。',
                        },
                    }
                    break
                case 'input_credit_add_account':
                    chat = {
                        type: "input_credit_add_account",
                        data: {
                            required: true,
                            subject: "クレジットカード情報",
                            payment_method: "univapay",
                            univapay_token: '',
                            univapay_secret: '',
                            univapay_checkout: 'token',
                            univapay_tokentype: 'recurring',
                        },
                    }
                    break
                case 'input_credit':
                    chat = {
                        type: "input_credit",
                        data: {
                            required: true,
                            subject: "クレジットカード情報",
                            payment_method: "univapay",
                            univapay_token: '',
                            univapay_secret: '',
                            univapay_checkout: 'token',
                            univapay_tokentype: 'one_time',
                            amount: 100,
                        },
                    }
                    break
                default:
                    break
            }
            return chat
        },
        add: async function (type) {
            let chat = this.getDefaultChat(type)
            this.$store.commit('scenario/insertChat',
                {
                    'chat': chat,
                    // クリック時に先頭に追加するよう修正
                    // 'index': this.$store.getters['scenario/length'] - 2,
                    'index': 0,
                }
            )
            let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
            this.$store.commit('scenario/setScenario', s.data)
            this.$store.commit('scenario/setSaved', { "saved": true })
        },
        dragList(event, type) {
            this.$store.commit('editView/setStatus', { status: 'dragNewItem' })
            event.dataTransfer.effectAllowed = 'copy'
            event.dataTransfer.dropEffect = 'copy'
            event.dataTransfer.setData('type_side_area_chat', JSON.stringify(this.getDefaultChat(type)))
        },
        dragendList(event, type) {
            this.$store.commit('editView/setStatus', { status: 'view' })
        },
        backToControl() {
            if (this.$store.getters['scenario/saved']) {
                this.$router.push({ path: '/scenario/control' })
            } else {
                if (window.confirm('シナリオが保存されていません。\n編集を破棄して戻りますか？')) {
                    this.$router.push({ path: '/scenario/control' })
                }
            }
        },

        // デバッグ機能
        scenarioGet: function () {
        },
        getChat: function () {
        },
        clearTemplate: async function () {
            this.$store.commit('scenario/init')
        },
        setTemplate: async function () {
            this.$store.commit(
                'scenario/setScenarioTemplate',
                {
                    'name': 'テンプレートから読み込んだシナリオ',
                    'title': 'セミナー申し込み',
                    'scenario': [
                        {
                            "type": "operator_talk",
                            "data": {
                                "text": "まずはじめに、会社名とお名前をご入力ください。"
                            },
                            "id": 0,
                            "key": 0
                        },
                        {
                            "type": "input_name",
                            "data": {
                                "company_display": true,
                                "name_display": true,
                                "kana_display": true,
                                "separate": true,
                                "company_required": true,
                                "name_required": true,
                                "kana_required": true,
                                "company_subject": "会社名",
                                "name_subject": "お名前",
                                "kana_subject": "フリガナ",
                                "placeholder_company": "株式会社○○商事",
                                "placeholder_name": "山田　花子",
                                "placeholder_kana": "ヤマダ　ハナコ",
                                "placeholder_name1": "山田",
                                "placeholder_name2": "花子",
                                "placeholder_kana1": "ヤマダ",
                                "placeholder_kana2": "ハナコ"
                            },
                            "id": 1,
                            "key": 1
                        },
                        {
                            "type": "operator_talk",
                            "data": {
                                "text": "メールアドレスと電話番号をご入力ください。"
                            },
                            "id": 2,
                            "key": 2
                        },
                        {
                            "type": "input_email_and_tel",
                            "data": {
                                "email_display": true,
                                "email_required": true,
                                "email_subject": "メールアドレス",
                                "placeholder_email": "email@example.com",
                                "tel_display": true,
                                "tel_required": true,
                                "tel_subject": "電話番号",
                                "placeholder_tel": "09012345678"
                            },
                            "id": 3,
                            "key": 3
                        },
                        {
                            "type": "operator_talk",
                            "data": {
                                "text": "セミナーの日程をご選択ください。"
                            },
                            "id": 4,
                            "key": 4
                        },
                        {
                            "type": "select_schedule",
                            "data": {
                                "schedule_required": true,
                                "schedule_subject": "日程",
                                "placeholder_schedule": "日程を選択してください",
                                "full_subject": "【満員御礼】",
                                "schedules": [
                                    {
                                        "id": 0,
                                        "full_flg": true,
                                        "year": 2022,
                                        "month": 3,
                                        "day": 4,
                                        "start_hour": 13,
                                        "start_minutes": 0,
                                        "end_hour": 14,
                                        "end_minutes": 0
                                    },
                                    {
                                        "id": 1,
                                        "full_flg": false,
                                        "year": 2022,
                                        "month": 3,
                                        "day": 4,
                                        "start_hour": 14,
                                        "start_minutes": 0,
                                        "end_hour": 15,
                                        "end_minutes": 0
                                    },
                                    {
                                        "id": 2,
                                        "full_flg": false,
                                        "year": 2022,
                                        "month": 3,
                                        "day": 5,
                                        "start_hour": 14,
                                        "start_minutes": 0,
                                        "end_hour": 15,
                                        "end_minutes": 0
                                    }
                                ]
                            },
                            "id": 5,
                            "key": 5
                        },
                        {
                            "type": "operator_talk",
                            "data": {
                                "text": "ご入力ありがとうございます。\n以下の内容でお間違いないかご確認ください。"
                            },
                            "id": 6,
                            "key": 6
                        },
                        {
                            "type": "confirm",
                            "data": {
                                "confirm_subject": "内容確認"
                            },
                            "id": 7,
                            "key": 7
                        },
                        {
                            "type": "complete",
                            "data": {
                                "complete_subject": "お申込みありがとうございました。",
                                "complete_text": "ご登録いただきましたメールアドレス宛てに案内メールをお送りしましたのでご確認下さい。\n※しばらくたってもご案内メールが見当たらない場合は、迷惑メールフォルダなどに振り分けられているか、間違ったアドレスでお手続きをされた可能性があります。\nその場合は、お手数ですが、再度メールアドレスをご確認の上、お申し込み手続きを行ってください。\n今後とも宜しくお願いいたします。"
                            },
                            "id": 8,
                            "key": 8
                        }
                    ],
                }
            )
        },

        // デバッグ機能
        setScenario: async function () {
            this.$store.commit(
                'scenario/setScenario',
                {
                    id: 5,
                    lastId: 1200,
                    lastKey: 123456,
                    status: 'publish',
                    name: '保存済みから読み込んだシナリオ',
                    title: '保存されたシナリオ',
                    scenario: [
                        {
                            type: "operator_talk",
                            data: {
                                "text": "まずはじめに、会社名とお名前をご入力ください。"
                            },
                            key: 0,
                            id: 0
                        },
                        {
                            "type": "input_name",
                            "data": {
                                "company_display": true,
                                "name_display": true,
                                "kana_display": true,
                                "separate": true,
                                "company_required": true,
                                "name_required": true,
                                "kana_required": true,
                                "company_subject": "会社名",
                                "name_subject": "お名前",
                                "kana_subject": "フリガナ",
                                "placeholder_company": "株式会社○○商事",
                                "placeholder_name": "山田　花子",
                                "placeholder_kana": "ヤマダ　ハナコ",
                                "placeholder_name1": "山田",
                                "placeholder_name2": "花子",
                                "placeholder_kana1": "ヤマダ",
                                "placeholder_kana2": "ハナコ"
                            },
                            "key": 1,
                            "id": 1
                        },
                        {
                            "type": "operator_talk",
                            "data": {
                                "text": "メールアドレスと電話番号をご入力ください。"
                            },
                            "key": 2,
                            "id": 2
                        },
                        {
                            "type": "input_email_and_tel",
                            "data": {
                                "email_display": true,
                                "email_required": true,
                                "email_subject": "メールアドレス",
                                "placeholder_email": "email@example.com",
                                "tel_display": true,
                                "tel_required": true,
                                "tel_subject": "電話番号",
                                "placeholder_tel": "09012345678"
                            },
                            "key": 3,
                            "id": 3
                        },
                        {
                            "type": "operator_talk",
                            "data": {
                                "text": "セミナーの日程をご選択ください。"
                            },
                            "key": 4,
                            "id": 4
                        },
                        {
                            "type": "select_schedule",
                            "data": {
                                "schedule_required": true,
                                "schedule_subject": "日程",
                                "placeholder_schedule": "日程を選択してください",
                                "full_subject": "【満員御礼】",
                                "schedules": [
                                    {
                                        "id": 0,
                                        "full_flg": true,
                                        "year": 2022,
                                        "month": 3,
                                        "day": 4,
                                        "start_hour": 13,
                                        "start_minutes": 0,
                                        "end_hour": 14,
                                        "end_minutes": 0,
                                        "key": 0
                                    },
                                    {
                                        "id": 1,
                                        "full_flg": false,
                                        "year": 2022,
                                        "month": 3,
                                        "day": 4,
                                        "start_hour": 14,
                                        "start_minutes": 0,
                                        "end_hour": 15,
                                        "end_minutes": 0,
                                        "key": 1
                                    },
                                    {
                                        "id": 2,
                                        "full_flg": false,
                                        "year": 2022,
                                        "month": 3,
                                        "day": 5,
                                        "start_hour": 14,
                                        "start_minutes": 0,
                                        "end_hour": 15,
                                        "end_minutes": 0,
                                        "key": 2
                                    },
                                    {
                                        "id": 3,
                                        "key": 3,
                                        "year": 2022,
                                        "month": 11,
                                        "day": 23,
                                        "start_hour": 0,
                                        "start_minutes": 0,
                                        "end_hour": 0,
                                        "end_minutes": 0
                                    },
                                    {
                                        "id": 4,
                                        "key": 4,
                                        "year": 2022,
                                        "month": 12,
                                        "day": 23,
                                        "start_hour": 14,
                                        "start_minutes": 0,
                                        "end_hour": 14,
                                        "end_minutes": 30
                                    }
                                ]
                            },
                            "key": 8,
                            "id": 5
                        },
                        {
                            "type": "operator_talk",
                            "data": {
                                "text": "ご入力ありがとうございます。\n以下の内容でお間違いないかご確認ください。"
                            },
                            "key": 6,
                            "id": 6
                        }
                    ],
                }
            )
        },
        toggleSystemRegist: async function () {
            if(this.scenario_systemRegist) {
                if(window.confirm("このチャットボットの登録者がシステムに登録されなくなります。\nよろしいですか？")) {
                    this.$store.commit('scenario/setSystemRegist', {systemRegist: false})
                    this.systemRegist = false
                }
            } else {
                if(window.confirm("このチャットボットの登録者がシステムに登録されます。\nよろしいですか？")) {
                    this.$store.commit('scenario/setSystemRegist', {systemRegist: true})
                    this.systemRegist = true
                }
            }
            let s = await Api.scenarioUpdate(this.$store, this.scenario_dump)
            this.$store.commit('scenario/setScenario', s.data)
            this.$store.commit('scenario/setSaved', { "saved": true })
        },
    },
}
</script>

<style scoped>
.side_area {
    width: 100%;
    height: 100%;
    background-color: #455A64;
    user-select: none;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.edit_window {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #ffb300;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.edit_window-enter-active,
.edit_window-leave-active {
    transition: left .25s ease;
}

.edit_window-enter-to,
.edit_window-leave-from {
    left: 0;
}

.edit_window-enter-from,
.edit_window-leave-to {
    left: -100%;
}

.menu_item {
    position: relative;
    text-align: left;
    width: 70%;
    left: 10%;
    background-color: white;
    padding: 9px 19px;
    margin: 10px 0;
    border-radius: 5px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
}

.menu_item:hover {
    color: aqua;
    border-color: aqua;
}

.back_to_control {
    text-align: left;
    color: black;
    background-color: white;
    cursor: pointer;
    height: 50px;
    padding-left: 30px;
}

.back_to_control:hover {
    color: aqua;
}

.back_to_control>span {
    font-size: 28px;
    line-height: 28px;
}

.material-symbols-outlined {
    font-size: 36px;
    padding: 10px;
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

.tabMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
}

.item_icon {
    width: 20px;
    height: 20px;
    position: relative;
    top: 3px;
}

.side_area h2 {
    color: white;
}
</style>