<template>
  <div class="header_base-pc" @mouseenter="mouseEnterAction">
    <img class="header_avater" src="/chat_avater.png" />
    <div class="header_title_box">
      <div v-if="mode != 'edit'" class="header_title" v-html="title"></div>
      <div v-else class="header_title" v-html="edit_chat.data.title"></div>
    </div>
    <div v-if="status != 'play'">
      <div v-if="mode == 'mouseOver'" class="fachatbot_component_wrapper_edit_mask" @mouseleave="mouseOutAction"
      @click="toEdit">
      <p>クリックして編集する</p>
    </div>
    <div v-if="mode == 'otherEdit'" class="fachatbot_component_wrapper_other_edit_mask" @click="otherEditCancel"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'HeaderBase',
  props: ['status'],
  data() {
    return {
      class_headerbase: '',
      device_type: '',
      id: -1,
      mode: 'view',
    }
  },
  computed: {
        ...mapGetters({
            title: 'scenario/title',
            edit_chat: 'editView/editChat',
        })
    },
  watch: {
  },
  mounted: function () {
    this.device_type = 'sp';
    this.class_headerbase = 'header_base-' + this.device_type;
    this.$store.watch(
      (state, getters) => getters['editView/status'],
      (newValue, oldValue) => {
        if (newValue == 'edit') {
          if (this.$store.getters['editView/editId'] == this.id) {
            this.mode = 'edit';
          } else {
            this.mode = 'otherEdit';
          }
        } else if (newValue == 'view') {
          this.mode = 'view';
        }
      }
    );
    this.$store.watch(
      (state, getters) => getters['editView/hoverId'],
      (newValue, oldValue) => {
        if (this.mode == 'view' || this.mode == 'mouseOver') {
          if (newValue == this.id) {
            this.mode = 'mouseOver';
          } else {
            this.mode = 'view';
          }
        }
      }
    );
  },
  methods: {
    async mouseEnterAction() {
      await this.$store.commit('editView/setHoverId', { 'hoverId': this.id });
    },
    async mouseOutAction() {
      await this.$store.commit('editView/setHoverId', { 'hoverId': null });
    },
    async toEdit() {
      if (this.mode == 'mouseOver') {
        await this.$store.dispatch('editView/toEdit', { 'editId': this.id })
      }
    },
    otherEditCancel() {
      if (this.mode == 'otherEdit') {
        this.$store.commit('editView/setStatus', {'status': 'view'})
        this.$store.commit('editView/setHoverId', { 'hoverId': null })
      }
    },
  }
}
</script>

<style scoped>
.header_base-pc {
  position: absolute;
  align-items: center;
  display: flex;
  margin: 0;
  border: none;
  padding: 3px 0;
  height: 60px;
  width: 100%;
  background: #fa9352;
  transition: .5s;
  letter-spacing: .5px;
}

.header_base-pc:hover {
    opacity: .85;
}

.header_base-sp {
  position: absolute;
  align-items: center;
  display: flex;
  margin: 0;
  border: none;
  padding: 3px 0;
  height: 60px;
  width: 100%;
  background: #fa9352;
  letter-spacing: .5px;
}

.header_avater {
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 50px;
  height: 50px;
  flex: 0 0 50px;
  float: left;
  margin: 0 6px 0 10px;
}

.header_title_box {
  display: block;
  width: calc(100% - 105px);
  flex: 0 0 calc(100% - 105px);
}

.header_title {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 0 10px;
  border-radius: 20px;
  position: relative;
  visibility: visible;
  text-align: center;
  overflow-wrap: normal;
}

.header_title_main {
  font-weight: 700;
  max-height: 100%;
  background: 0 0;
}

.header_base-pc::after,
.header_base-sp::after {
  width: 20px;
  height: 20px;
  margin: 0 0 0 10.5px;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
  background-image: url(@/assets/header_arrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
  right: 8px;
  top: calc(50% - 10px);
  content: '';
  display: inline-block;
}

.fachatbot_component_wrapper_edit_mask {
  position: absolute;
  margin: 0;
  border: 3px solid rgba(61, 150, 214, 1);
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(61, 150, 214, 0.4);
  pointer-events: auto;
  color: rgba(255, 255, 255, 1);
  z-index: 2;
}

.fachatbot_component_wrapper_edit_mask p {
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  top: calc(50% - 1em);
  /* background-color: rgb(128, 128, 128, 0.7); */
  font-weight: 700;
  cursor: pointer;
}
.fachatbot_component_wrapper_other_edit_mask {
  position: absolute;
  display: inline-table;
  margin: 0;
  border: none;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  color: rgba(255, 255, 255, 1);
  /* vertical-align: middle; */
  z-index: 2;
}
</style>
