<template>
  <div class="edit_box">
    <div class="title_box">
      <div class="edit_title">プルダウン</div>
      <div class="right">
        <div class="edit_submit" @click="submit">項目を保存</div>
      </div>
    </div>

    <div class="input_group">
      <div class="input_box">
        <input id="free_select_required" type="checkbox" v-model="edit_data.free_select_required" />
        <label for="free_select_required">プルダウンを必須とする</label>

      </div>
      <div class="input_box">
        <label for="free_select_subject">タイトル</label>
        <input id="free_select_subject" type="text" v-model="edit_data.free_select_subject" />
      </div>
      <div class="input_box">
        <label for="placeholder_free_select">未選択時</label>
        <input id="placeholder_free_select" type="text" v-model="edit_data.placeholder_free_select" />
      </div>
    </div>

    <div class="subject">選択肢</div>
    <div class="input_group">
      <div class="input_box" v-if="edit_data.free_selects && edit_data.free_selects.length == 0">
        選択肢がありません。
      </div>
      <TransitionGroup name="select">
        <div class="input_box" v-for="(s, index) in edit_data.free_selects" :key="s.key"
          @mouseenter="mouseEnterAction(s.id)" @mouseleave="mouseOutAction" draggable="true"
          @dragstart="dragList($event, index)" @drop="dropList($event, index)" @dragover="dragoverList($event, Index)"
          @dragenter="dragenterList($event, Index)">
          <div v-if="hoverId == s.id">
            <div v-if="index > 0" class="free_select_btn free_select_up" @click.stop="moveUp(s.id)">上</div>
            <div v-if="index < edit_data.free_selects.length - 1" class="free_select_btn free_select_down"
              @click.stop="moveDown(s.id)">下</div>
            <div class="free_select_btn free_select_remove" @click="remove(s.id)">×</div>
          </div>
          <div class="text">
            {{ s.text }}
          </div>
        </div>
      </TransitionGroup>
    </div>

    <div class="subject">選択肢追加</div>
    <div class="input_group">
      <div class="input_box">
        <input ref="input" type="text" v-model="text" @keypress.enter="add" />
        <button @click="add">追加</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'EditSelectFreeSelect',
  props: ['chat'],
  components: {
  },
  data() {
    return {
      edit_data: {},
      lastKey: 0,
      text: '',
      startHour: 0,
      startMinute: 0,
      endHour: 0,
      endMinute: 0,
      hoverId: null,
    }
  },
  watch: {
  },
  mounted: function () {
    this.edit_data = this.chat.data;
    this.edit_data.free_selects.forEach(element => {
      element.key = this.lastKey++
    });
  },
  methods: {
    async mouseEnterAction(id) {
      this.hoverId = id
    },
    async mouseOutAction() {
      this.hoverId = null
    },
    submit: function () {
      let data = this.edit_data;
      this.$emit("submit", data);
    },
    moveUp(id) {
      let i = this.edit_data.free_selects.findIndex(c => c.id == id);
      if (i > 0) {
        let tmp = this.edit_data.free_selects.splice(i, 1);
        this.edit_data.free_selects.splice(i - 1, 0, tmp[0]);
      }
    },
    moveDown(id) {
      let i = this.edit_data.free_selects.findIndex(c => c.id == id);
      if (i != -1) {
        let tmp = this.edit_data.free_selects.splice(i, 1);
        this.edit_data.free_selects.splice(i + 1, 0, tmp[0]);
      }
    },
    remove(id) {
      if (window.confirm("削除した要素は戻せません。よろしいですか？")) {
        let i = this.edit_data.free_selects.findIndex(c => c.id == id);
        if (i != -1) {
          this.edit_data.free_selects.splice(i, 1);
        }
      }
    },
    add: function () {
      if (this.text != '') {
        let tmp = {
          'id': this.edit_data.lastId++,
          'key': this.lastKey++,
          'text': this.text,
        }
        this.edit_data.free_selects.push(tmp);
        this.text = ''
      } else {
        alert('空の選択肢は追加できません')
      }
      this.$refs.input.focus()
    },
    dragList(event, dragIndex) {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('drag-index', dragIndex)
      event.dataTransfer.setData('type_select_free_select', 'select_free_select')
    },
    dragoverList(event, dropIndex) {
      const types = event.dataTransfer.types
      if (types.indexOf('type_select_free_select') != -1) {
        event.preventDefault()
      }
    },
    dragenterList(event, dropIndex) {
      const types = event.dataTransfer.types
      if (types.indexOf('type_select_free_select') != -1) {
        event.preventDefault()
      }
    },
    dropList(event, dropIndex) {
      const dragIndex = event.dataTransfer.getData('drag-index')
      const types = event.dataTransfer.types
      if (types.indexOf('type_select_free_select') != -1) {
        const deleteList = this.edit_data.free_selects.splice(dragIndex, 1);
        this.edit_data.free_selects.splice(dropIndex, 0, deleteList[0]);
      }
    },
  }
}
</script>

<style scoped>
.edit_box {
  margin: 20px;
}

.title_box {
  position: relative;
  width: 100%;
  height: 30px;
  margin-bottom: 20px;
}

.edit_title {
  position: absolute;
  color: white;
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
}

.right {
  position: absolute;
  height: 30px;
  right: 0;
}

.edit_submit {
  background-color: #42B5D1;
  border-color: #42B5D1;
  color: white;
  line-height: 24px;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 8px;
}

.input_group {
  overflow: hidden;
  border: 0 solid #ffb300;
  text-align: left;
  background-color: white;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  margin-bottom: 20px;
}

.input_box {
  border: 1px solid #ffb300;
  padding: 10px 10px;
  position: relative;
}

.input_box label {
  margin-bottom: 5px;
}

.input_box input[type="text"] {
  width: calc(100% - 10px);
  font-size: 18px;
}

.text {
  word-break: break-all;
}

.subject {
  color: white;
}

.free_select_box {
  width: 100%;
}

.free_select_btn {
  border: 1px solid black;
  position: absolute;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  font-weight: 700;
  background-color: gray;
  color: white;
}

.free_select_btn:hover {
  background-color: #C0C0C0;
}

.free_select_up {
  top: 10px;
  right: 80px;
}

.free_select_down {
  top: 10px;
  right: 50px;
}

.free_select_remove {
  top: 10px;
  right: 20px;
}

.select-move,
/* 移動する要素にトランジションを適用 */
.select-enter-active,
.select-leave-active {
  transition: all 0.5s ease;
}

.select-leave-active {
  position: absolute;
  width: 100%;
  opacity: 0;
}
</style>
