<template>
  <div class="complete">
    <transition appear :name="status == 'freeze' ? 'fachatbot_complete' : ''" v-on:after-enter="afterEnter">
      <div v-if="progress == 'complete'" class="input_body">
        <div class="input_group">
          <p class="title">{{ data.complete_subject }}</p>
          <pre>{{ data.complete_text }}</pre>
          <div v-if="cal_enable" class="next_message">
            <a :href="google_cal_url" target="_blank"><button class="btn">Googleカレンダーに予定を追加</button></a>
          </div>
          <div v-if="cal_enable" class="next_message">
            <a :href="ical_url"><button class="btn" target="_blank">iOSカレンダーに予定を追加(iCAL形式)</button></a>
          </div>
        </div>
      </div>
    </transition>
    <transition appear :name="status == 'freeze' ? 'fachatbot_complete' : ''" v-on:after-enter="afterEnter">
      <div v-if="progress == 'error'" class="input_body">
        <div class="input_group">
          <p class="title">お申込処理に失敗しました。</p>
          <p>{{ err_msg }}</p>
        </div>
      </div>
    </transition>
    <transition appear :name="status == 'freeze' ? 'fachatbot_complete' : ''" v-on:after-enter="afterEnter">
      <div v-if="progress == 'in_progress'" class="in_progress">
        <div class="loader_title">登録処理実行中</div>
        <div class="loader"></div>
      </div>
    </transition>
    <div ref="iframeBase" class="hidden"></div>
  </div>
</template>

<script>
import Api from '@/lib/Api.js'

export default {
  name: "ChatComplete",
  props: ["id", "data", "status"],
  components: {},
  data() {
    return {
      progress: "complete",
      err_msg: "",
      ical_url: null,
      google_cal_url: null,
      cal_enable: false,
    };
  },
  watch: {},
  mounted: async function () {
    if (this.status == 'freeze' && (this.$store.getters['scenarioPlay/chatbot'])) {
      let result = this.$store.getters['scenarioPlay/resultsProcessed'];
      let data = result.find(r => r.type == 'select_schedule')
      let response = await Api.scenarioGet(this, this.scenario_id)
      let s = response.data
      if (data != undefined) {
        this.cal_enable = true
      }
      // axios
      //   .get(process.env.VUE_APP_API_URL + '&data=' + (encodeURIComponent(JSON.stringify(data))))
      //   .then(response => {
      //     if (response.status === 200) {
      //       if (response.data.status === 'OK') {
      //         this.progress = 'complete';
      //         this.ical_url = process.env.VUE_APP_API_URL + 'ical.php?text=' + encodeURIComponent(this.data.complete_cal_subject) + '&year=' + data.result.year + '&month=' + data.result.month + '&day=' + data.result.day
      //           + '&start_hour=' + ('0' + data.result.start_hour).slice(-2) + '&start_minutes=' + ('0' + data.result.start_minutes).slice(-2)
      //           + '&end_hour=' + ('0' + data.result.end_hour).slice(-2) + '&end_minutes=' + ('0' + data.result.end_minutes).slice(-2);
      //         this.google_cal_url = 'https://www.google.com/calendar/render?action=TEMPLATE&text=' + encodeURIComponent(this.data.complete_cal_subject)
      //           + '&dates=' + data.result.year + ('0' + data.result.month).slice(-2) + ('0' + data.result.day).slice(-2) + 'T' + ('0' + data.result.start_hour).slice(-2) + ('0' + data.result.start_minutes).slice(-2) + '00/'
      //           + data.result.year + ('0' + data.result.month).slice(-2) + ('0' + data.result.day).slice(-2) + 'T' + ('0' + data.result.end_hour).slice(-2) + ('0' + data.result.end_minutes).slice(-2) + '00';
      //         this.submit();
      //         let scriptMetaPixelElement = document.createElement('script');
      //         scriptMetaPixelElement.innerHTML = this.scriptMetaPixel;
      //         document.body.appendChild(scriptMetaPixelElement);
      //       } else {
      //         this.progress = 'error';
      //         this.$emit('unfreeze');
      //       }
      //     }
      //   })
    }
    this.$nextTick(() => {
      this.$emit("afterEnter");
    })
  },
  beforeUnmount: function () {
  },
  methods: {
    sleep(time) {
      return new Promise((resolve) => {
        setTimeout(resolve, time);
      });
    },
    afterEnter() {
      this.$emit("afterEnter");
    },
    submit() {
      this.btn_flg = false;
      this.$emit("submit", this.id, this.output);
    },
  },
};
</script>

<style scoped>
.hidden {
  height: 0;
  width: 0;
  margin: 0;
  border: 0;
  overflow: hidden;
}

.complete {
  padding: 3px 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.input_body {
  padding: 10px;
  background: #fcf9ce;
}

.input_group {
  width: 100%;
  word-break: break-word;
  margin-bottom: 10px;
}

.input_group pre {
  white-space: pre-wrap;
}

.title {
  white-space: pre-line;
  padding: 3px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.input_box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.input {
  font-size: 16px;
  background-color: white;
  color: #333;
  border-color: #e7661c;
  width: 100%;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
}

.input::placeholder {
  color: #aaa;
}

.next_message {
  text-align: right;
  float: right;
  padding-right: 0px;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  overflow: visible;
  margin-top: 10px;
}

.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}

.required {
  color: red;
  font-size: 12px;
}

.err {
  color: red;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.loader_title {
  text-align: center;
  color: #fa9532;
  font-size: 20px;
}

.loader {
  color: #fa9532;
  font-size: 10px;
  margin: 30px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.fachatbot_complete-enter-active {
  transition: opacity 0.38s;
}

.fachatbot_complete-enter-to {
  opacity: 1;
}

.fachatbot_complete-enter-from {
  opacity: 0;
}
</style>
