<template>
    <div class="menu_area">
        <div class="tab_area">
        </div>
        <div class="white_blank">
            <a class="tab edit_tab" :class="menu == 'EditArea' ? 'selected' : ''" @click="toEdit">チャットボット編集</a>
            <a class="tab complete_tab" :class="menu == 'CompleteArea' ? 'selected' : ''" @click="toComplete">申込完了ページ設定</a>
            <a class="tab preview_tab" :class="menu == 'PreviewArea' ? 'selected' : ''" @click="toPreview">プレビュー</a>
            <a class="tab thanksmail_tab" :class="menu == 'ThanksmailArea' ? 'selected' : ''" @click="toThanksmail">自動返信メール設定</a>
            <a class="tab notification_tab" :class="menu == 'NotificationArea' ? 'selected' : ''" @click="toNotification">通知設定</a>
            <a class="tab cvtag_tab" :class="menu == 'CvtagArea' ? 'selected' : ''" @click="toCvtag">広告タグ設定</a>
            <a class="tab chatbottag_tab" :class="menu == 'ChatbottagArea' ? 'selected' : ''" @click="toChatbottag">利用開始</a>
            <a class="tab answerlist_tab" :class="menu == 'AnswerlistArea' ? 'selected' : ''"
                @click="toAnswerlist">申込者リスト</a>
        </div>
        <div v-if="menu != 'PreviewArea'" class="component_area">
            <component :is="menu"></component>
        </div>
        <div v-if="menu == 'PreviewArea'" class="component_area">
            <div class="preview_control">
                <a class="menu_item" @click="previewOpen">
                    <span class="material-symbols-outlined menu_icon">open_in_browser</span>
                    <span>チャットオープン</span>
                </a>
                <a class="menu_item" @click="previewReset">
                    <span class="material-symbols-outlined menu_icon">restart_alt</span>
                    <span>リセット</span>
                </a>
            </div>
            <div style="width: 100%;height: calc(100% - 40px);">
                <component :is="menu" :id="scenario_id"></component>
            </div>
        </div>
</div>
</template>
  
<script>
import EditArea from '@/components/scenario/edit/EditArea.vue'
import CompleteArea from '@/components/scenario/edit/CompleteArea.vue'
import PreviewArea from '@/components/scenario/preview/PreviewArea.vue'
import CvtagArea from '@/components/scenario/edit/CvtagArea.vue'
import ChatbottagArea from '@/components/scenario/edit/ChatbottagArea.vue'
import ThanksmailArea from '@/components/scenario/edit/ThanksmailArea.vue'
import NotificationArea from '@/components/scenario/edit/NotificationArea.vue'
import AnswerlistArea from '@/components/scenario/edit/AnswerArea.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'MenuArea',
    props: [],
    components: {
        EditArea,
        CompleteArea,
        PreviewArea,
        CvtagArea,
        ChatbottagArea,
        ThanksmailArea,
        NotificationArea,
        AnswerlistArea,
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            scenario_id: 'scenario/id',
            scenario_dump: 'scenario/dump',
            menu: 'editView/menu',
        }),
    },
    watch: {
    },
    mounted: function () {
    },
    methods: {
        toEdit() {
            this.$store.commit('editView/setStatus', { 'status': 'view' })
            this.$store.commit('editView/setMenu', { 'menu': 'EditArea' })
        },
        toComplete() {
            this.$store.commit('editView/setStatus', { 'status': 'view' })
            this.$store.commit('editView/setMenu', { 'menu': 'CompleteArea' })
        },
        toThanksmail() {
            this.$store.commit('editView/setMenu', { 'menu': 'thanksmail' })
            this.$store.commit('editView/setMenu', { 'menu': 'ThanksmailArea' })
        },
        toNotification() {
            this.$store.commit('editView/setStatus', { 'status': 'view' })
            this.$store.commit('editView/setMenu', { 'menu': 'NotificationArea' })
        },
        toCvtag() {
            this.$store.commit('editView/setMenu', { 'menu': 'cvtag' })
            this.$store.commit('editView/setMenu', { 'menu': 'CvtagArea' })
        },
        toChatbottag() {
            this.$store.commit('editView/setMenu', { 'menu': 'chatbottag' })
            this.$store.commit('editView/setMenu', { 'menu': 'ChatbottagArea' })
        },
        toPreview() {
            if (this.menu != 'PreviewArea') {
                let s = this.scenario_dump
                this.$store.commit('scenarioPlay/setScenario', s)
                this.$store.commit('editView/setMenu', { 'menu': 'preview' })
                this.$store.commit('editView/setMenu', { 'menu': 'PreviewArea' })
            }
        },
        toAnswerlist() {
            this.$store.commit('editView/setMenu', { 'menu': 'answerlist' })
            this.$store.commit('editView/setMenu', { 'menu': 'AnswerlistArea' })
        },
        previewOpen() {
            this.$store.commit('scenarioPlay/setVisible', true)
        },
        previewReset() {
            this.$store.commit('scenarioPlay/clear')
            this.$store.commit('scenarioPlay/setVisible', false)
        },
    },
}
</script>

<style scoped>
.menu_area {
    width: 100%;
    height: 100%;
    background-color: #eaeaf0;
    overflow: hidden;
    position: relative;
}

.tab_area {
    left: auto;
    width: auto;
    height: 40px;
}

.white_blank {
    width: calc(100% - 30px);
    height: 20px;
    background-color: white;
    border-top: 1px solid #eaeaf0;
    border-radius: 20px 20px 0 0;
    margin: 0 15px;
}

.tab {
    position: relative;
    top: -40px;
    display: inline-block;
    height: 22px;
    border: 1px solid #eaeaf0;
    vertical-align: middle;
    border-radius: 6px 6px 0 0;
    color: #42B5D1;
    font-weight: 700;
    padding: 8px 0;
    background-color: white;
    width: 100px;
    cursor: pointer;
}
.tab:not(:first-child) {
    margin-left: 5px;
}

.thanksmail_tab {
    width: 150px;
}
.edit_tab {
    width: 150px;
}
.complete_tab {
    width: 150px;
}
.tab:hover {
    /* border-bottom-color: white; */
    color: black;
}

.selected {
    border-bottom-color: white;
    color: black;
}

.component_area {
    position: absolute;
    border: none;
    border-width: 0;
    border-color: black;
    width: 100%;
    height: calc(100% - 60px);
    margin: 0;
    padding: 0;
    background-color: #eaeaf0;
    overflow: auto;
}

.menu_item {
    position: relative;
    text-align: left;
    width: 150px;
    background-color: #eaeaf0;
    padding: 10px 19px;
    margin: 0 20px;
    border-radius: 8px;
    border: 1px solid white;
    cursor: pointer;
    line-height: 30px;
}

.menu_icon {
    position: relative;
    top: 4px;
    font-size: 20px;
    padding: 0;
    line-height: 30px;
}

.preview_control {
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: white;
    height: 40px;
}</style>