<template>
    <div class="dialog_area">
        <h1>シナリオ名変更</h1>
        <input type="text" class="dialog_input" v-model="text" />
        <button class="btn cancel" @click.stop="btnCancel">キャンセル</button>
        <button class="btn ok" @click.stop="btnOk">OK</button>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'dialogThanksMail',
    props: ['id'],
    components: {
    },
    data() {
        return {
            scenario: {},
            text: '',
        }
    },
    computed: {
        ...mapGetters({
        })
    },
    created: async function () {
        let response = await Api.scenarioGet(this, this.id)
        let s = response.data

        this.text = s.name
    },
    mounted: function () {
    },
    methods: {
        btnCancel() {
            this.$emit('submit', {'method': 'cancel'})
        },
        async btnOk() {
            let response = await Api.updateName(this.$store, {
                'id': this.id,
                'name': this.text,
            })
            this.$emit('submit', {'method': 'ok'})
        },
    },
}
</script>

<style scoped>
.dialog_area {
    position: fixed;
    width: calc(100% - 60px);
    height: 300px;
    top: 30px;
    left: 30px;
    background-color: #f6f6fa;
    overflow: hidden;
    z-index: 3;
    padding: 0;
}
.dialog_area h1 {
    position: absolute;
    top: 20px;
    left: 20px;
}
.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}
.cancel {
    background-color: #f6f6fa;
    color: black;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    position: absolute;
    right: 148px;
    bottom: 15px;
}
.cancel:hover {
    background-color: #ffffff;
}
.ok {
    position: absolute;
    right: 15px;
    bottom: 15px;
}
.ok:hover {
    background-color: #ffa56d;
}
.dialog_input {
    width: calc(100% - 46px);
    top: calc(20px + 60px);
    left: 20px;
    resize: none;
    font-size: 20px;
    position: absolute;
}
</style>