<template>
  <div class="title_area">
    <h1>お支払明細(サンプルデータ)</h1>
    <div class="button_list">
            <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/" target="_blank">マニュアル</a>
            <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/syoki_daikou/"
                target="_blank">初期設定代行サービス</a>
            <a class="title_button" href="https://webinar-master.com/webinartatsujin/manual/contact/"
                target="_blank">お問い合せ</a>
        </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex';

const componentLabel = {
  'operator_talk': 'オペレータートーク',
  'input_name': '会社名・姓名',
  'input_email_and_tel': 'メール・Tel',
  'select_schedule': 'スケジュール選択',
  'confirm': '確認',
  'complete': '完了',
}

export default {
  name: 'TitleArea',
  props: [],
  components: {
  },
  data() {
    return {
      mode: 'view',
      title: '',
      name: '',
      name_mode: 'view',
      status: '',
    }
  },
  computed: {
    ...mapGetters({
    })
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
  },
}
</script>

<style scoped>
.title_area {
  width: 100%;
  height: 100%;
  background-color: #f6f6fa;
  overflow: hidden;
  position: relative;
}

.title_area h1 {
  margin-top: 10px;
  margin-left: 20px;
}

.button_list {
    position: absolute;
    top: 20px;
    right: 20px;
}

.title_button {
    background-color: white;
    color: #00bcd4;
    text-decoration: none;
    margin: 0 10px;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 700;
    border-radius: 4px;
}

.title_button:hover {
    color: white;
    background: #00bcd4;
}
</style>