<template>
    <div class="forgot_area">
        <div class="logo">ロゴ</div>
        <h1 class="login_title">ログイン</h1>
        <div v-if="status == 0">
            <div class="nortice">
                メールアドレスを入力し、<br />「パスワードリセット」ボタンをクリックしてください。
            </div>
            <div class="input_box">
                <div class="input_icon_box">
                    <img class="input_icon" src="@/assets/system-icon/mail.png" />
                </div>
                <input class="input_text" type="text" v-model="login_name" @keypress.enter="login" autocomplete="username" />
            </div>
            <div>
                <label></label>
                <button class="login_btn" @click="passwordReset">パスワードリセット</button>
            </div>
        </div>
        <div v-if="status == 1">
            <div class="nortice">
                メールを送信しました。
            </div>
            <div>
                <a href="/login">ログイン</a>
            </div>
        </div>
    </div>
</template>
  
<script>
// import axios from "axios";
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'ForgotArea',
    props: [],
    components: {
    },
    data() {
        return {
            login_name: '',
            password: '',
            password_visible: false,
            apiResponse: null,
            env: '',
            status: 0,
        }
    },
    computed: {
        ...mapGetters({
            customer_id: 'customer/customer_id',
            customer_dump: 'customer/dump',
        })
    },
    mounted: function () {
        this.env = process.env.VUE_APP_ENV
    },
    methods: {
        async passwordReset() {
            this.apiResponse = await Api.passwordReset(this, this.login_name.trim())
            if (this.apiResponse != null && this.apiResponse.login_status == 'LOGGED_IN') {
                this.status = 1
            } else {
                this.status = 1
            }
        },
    },
}
</script>

<style scoped>
.forgot_area {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #eaeaf2;
    overflow: hidden;
    position: absolute;
    overflow: auto;
}

.logo {
    width: 48px;
    height: 48px;
    background-color: #303030;
    color: white;
    margin-top: 100px;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    line-height: 48px;
    font-weight: 700;
}

.login_title {
    display: block;
    text-align: center;
    margin-bottom: 48px;
}

.login_title::before {
    display: none;
}
.input_box {
    /* border: 1px solid black; */
    width: 360px;
    height: 48px;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.input_icon_box {
    position: absolute;
    width: 48px;
    height: 48px;
    border: 2px solid #b2b2b8;
    left: 0;
    background-color: white;
    box-sizing: border-box;
    border-radius: 5px 0 0 5px;
}
.input_icon {
    position: absolute;
    width: 24px;
    left: 10px;
    top: 10px;
}
.input_text {
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 48px);
    height: 48px;
    right: 0;
    font-size: 22px;
    background-color: #e8f0fe;
    border: 2px solid #b0c8f1;
    border-left: none;
    padding-left: 10px;
    border-radius: 0 5px 5px 0;
}

.nortice {
    border: 1px solid red;
    background-color: rgb(255, 200, 200);
    font-size: 14px;
    width: 360px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}

.login_btn {
    background-color: #42b5d1;
    color: white;
    border: none;
    width: 180px;
    height: 36px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 25px;
    font-weight: 700;
}
.login_btn:hover {
    background-color: white;
    color: #42b5d1;
}













.login_box {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 30px;
    background-color: white;
    left: calc(100% / 2 - 150px);
    top: max(0px, min(200px, calc(100% - 280px)));
}

.login_box label {
    display: block;
    margin-top: 15px;
}

.login_box input {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

</style>