<template>
    <div class="login_area">
        <div class="logo"><img src="@/assets/logo.png" /></div>
        <div class="input_box">
            <div class="input_icon_box">
                <img class="input_icon" src="@/assets/system-icon/mail.png" />
            </div>
            <input class="input_text" type="text" v-model="login_name" @keypress.enter="login" />
        </div>
        <div class="input_box">
            <div class="input_icon_box">
                <img class="input_icon" src="@/assets/system-icon/lock.png" />
            </div>
            <input class="input_text" type="password" v-model="password" @keypress.enter="login" />
            <div v-if="loading" class="loader"></div>
        </div>
        <div class="login_hold">
            <label><input type="checkbox" v-model="logindata_hold" />ログイン状態を保持</label>
        </div>
        <div>
            <button class="login_btn" @click="login" :disabled="loading">ログイン</button>
        </div>
        <div class="forgot"><a href="/forgot">パスワードを忘れた場合</a></div>
    </div>
</template>
  
<script>
// import axios from "axios";
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'

export default {
    name: 'LoginArea',
    props: [],
    components: {
    },
    data() {
        return {
            login_name: '',
            password: '',
            logindata_hold: false,
            apiResponse: null,
            env: '',
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            customer_id: 'customer/customer_id',
            customer_dump: 'customer/dump',
        })
    },
    mounted: function () {
        this.env = process.env.VUE_APP_ENV
    },
    methods: {
        async login() {
            this.loading = true
            this.apiResponse = await Api.login(this, this.login_name.trim(), this.password)
            if (this.apiResponse != null && this.apiResponse.login_status == 'LOGGED_IN') {
                this.$router.push({ path: '/' })
            } else {
                alert("ログインに失敗しました")
                this.loading = false
            }
        },
        async logout() {
            this.apiResponse = await Api.logout(this)
        },
        async getStatus() {
            this.apiResponse = await Api.getStatus(this)
        },
    },
}
</script>

<style scoped>
.login_area {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #eaeaf2;
    overflow: hidden;
    position: absolute;
    overflow: auto;
}
.logo {
    width: 250px;
    height: 250px;
    color: white;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    line-height: 48px;
    font-weight: 700;
}
.logo img {
    width: 100%;
    height: 100%;
}
.login_title {
    display: block;
    text-align: center;
    margin-bottom: 48px;
}
.login_title::before {
    display: none;
}
.input_box {
    /* border: 1px solid black; */
    width: 360px;
    height: 48px;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.input_icon_box {
    position: absolute;
    width: 48px;
    height: 48px;
    border: 2px solid #b2b2b8;
    left: 0;
    background-color: white;
    box-sizing: border-box;
    border-radius: 5px 0 0 5px;
}
.input_icon {
    position: absolute;
    width: 24px;
    left: 10px;
    top: 10px;
}
.input_text {
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 48px);
    height: 48px;
    right: 0;
    font-size: 22px;
    background-color: #e8f0fe;
    border: 2px solid #b0c8f1;
    border-left: none;
    padding-left: 10px;
    border-radius: 0 5px 5px 0;
}
.login_hold {
    margin-bottom: 30px;
}
.login_btn {
    background-color: #42b5d1;
    color: white;
    border: none;
    width: 180px;
    height: 36px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 25px;
    font-weight: 700;
}
.login_btn:hover {
    background-color: white;
    color: #42b5d1;
}
.forgot > a {
    font-size: 16px;
    text-decoration:none;
    color: #42b5d1;
    font-weight: 700;
}
.forgot > a:hover {
    color: #4f6671;
}

.loader {
    left: calc(100% / 2);
    color: rgba(128, 128, 128, 0.8);
    font-size: 10px;
    margin: 30px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

@-webkit-keyframes load4 {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
            0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
            0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@keyframes load4 {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
            0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
            0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
            0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
            0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}
</style>