import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import ForgotView from '@/views/ForgotView.vue'
import EditView from '@/views/scenario/EditView.vue'
import CustomerEditView from '@/views/customer/EditView.vue'
import ControlView from '@/views/scenario/ControlView.vue'
import PreviewView from '@/views/scenario/PreviewView.vue'
import PaymentDetailView from '@/views/payment/DetailView.vue'
import PaymentConfigView from '@/views/payment/ConfigView.vue'
import AdminPaymentConfigView from '@/views/admin/PaymentConfigView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: ControlView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/scenario/edit',
    name: 'scenario_edit',
    component: EditView
  },
  {
    path: '/scenario/control',
    name: 'scenario_control',
    component: ControlView
  },
  {
    path: '/scenario/preview',
    name: 'scenario_preview',
    component: PreviewView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotView
  },
  {
    path: '/profile/edit',
    name: 'profile_edit',
    component: CustomerEditView
  },
  {
    path: '/payment/detail',
    name: 'payment_detail',
    component: PaymentDetailView
  },
  {
    path: '/payment/config',
    name: 'payment_config',
    component: PaymentConfigView
  },
  {
    path: '/admin/paymentconfig',
    name: 'admin_payment_config',
    component: AdminPaymentConfigView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
