<template>
    <div class="dialog_area">
        <button @click="downloadCSV">CSVダウンロード</button>
        <table align="center">
            <tr v-for="(raw, index) in answer" :key="index">
                <td v-for="(col, cindex) in raw" :key="cindex">{{ col }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/lib/Api.js'
import {encode} from 'iconv-lite';

export default {
    name: 'dialogChatbottag',
    props: ['id'],
    components: {
    },
    data() {
        return {
            answer: [],
            visible: false,
        }
    },
    computed: {
        ...mapGetters({
            customer_id: 'customer/customer_id',
            scenario_id: 'scenario/id',
            scenario_tag: 'scenario/tag',
        })
    },
    created: async function () {
        let response = await Api.answerGetListAll(this, this.scenario_id)
        this.answer = response.data
    },
    mounted: function () {
        this.visible = true
    },
    methods: {
        downloadCSV() {
            let date = new Date()
            let dateStr = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2) + ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2)

            let csv = ''
            this.answer.forEach(r => {
                let sep = ''
                r.forEach(c => {
                    csv += sep + '"' + c.replace('"', '""') + '"'
                    sep = ','
                })
                csv += "\r\n"
            })
            const encoded = encode(csv, 'Shift_JIS');
            let blob = new Blob([encoded], { type: 'text/plain' });
            let blobURL = window.URL.createObjectURL(blob);
            let obj = document.createElement('a');
            obj.href = blobURL;
            obj.download = `scenario${this.scenario_id}_${dateStr}.csv`
            document.body.appendChild(obj);
            obj.click();
            obj.parentNode.removeChild(obj);
        },
    },
}
</script>

<style scoped>
.dialog_area {
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: white;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    overflow: hidden;
    z-index: 3;
    padding: 0;
    overflow-y: auto;
    text-align: center;
}

table {
    width: 95%;
    border-spacing: 0;
    border: 1px solid black;
    table-layout: fixed;
}

td {
    border: 1px solid black;
    word-break: break-all;
}

.dialog_area > button {
    margin-bottom: 20px;
    outline: none;
    border: none;
    color: white;
    background-color: #a5a4a4;
    padding: 5px 40px;
    border-radius: 4px;
    cursor: pointer;
}
.dialog_area > button:hover {
    background-color: #858484;;
}
.fadeout-leave-active {
    transition: all .3s .5s ease;
}

.fadeout-leave-from {
    opacity: 1;
}

.fadeout-leave-to {
    opacity: 0;
}
</style>