<template>
  <transition appear :name="status == 'input' ? 'fachatbot_input_agreement' : ''">
    <div class="input_agreement">
      <div class="input_body">
        <div class="input_group">
          <p class="title">{{ data.agreement_subject }}</p>
          <div class="input_box">
            <pre class="input" :style="{ '--lines': data.agreement_display_lines }">{{ data.agreement_text }}</pre>
          </div>
          <div class="input_box">
            <div class="agreement">
              <label>
                <input v-if="status === 'input'" type="checkbox" v-model="agreement" />
                <input v-else disabled type="checkbox" v-model="agreement" />
                {{ data.agreement_label }}</label>
            </div>
          </div>
          <label v-if="err_agreement != ''" class="err">{{ err_agreement }}</label>
        </div>
        <div class="next_message">
          <button v-if="status === 'input' && (btn_text == '次へ' || btn_text == '更新')" type="button" class="btn"
            @click="submit">
            {{ btn_text }}
          </button>
          <button v-if="status !== 'input' || btn_text == 'OK'" type="button" class="btn disabled" disabled
            @click="submit">
            {{ btn_text }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'InputAgreement',
  props: ['id', 'data', 'status'],
  components: {
  },
  data() {
    return {
      agreement: false,
      err_agreement: '',
      btn_text: '次へ',
      edit_data: {},
      result: {},
    }
  },
  watch: {
    agreement: function () {
      if (this.btn_text == 'OK') this.btn_text = '更新';
    },
  },
  created: function () {
  },
  mounted: function () {
    if (this.status == 'input') {
      this.result = this.$store.getters['scenarioPlay/result'](this.id)
    }

    if (typeof this.result !== 'undefined') {
      if (typeof this.result.agreement !== 'undefined') {
        this.agreement = this.result.agreement;
      }
    }
    if (this.status == 'input') {
      if (this.status == 'play') {
        this.$nextTick(() => this.$refs.agreement.focus())
      }
    }
  },
  methods: {
    afterEnter() {
      this.$emit("afterEnter");
    },
    toggleRequire(require) {
      this.$emit("toggleRequire", require);
    },
    submit() {
      this.err_agreement = '';
      if (this.agreement == false) {
        this.err_agreement = 'こちらは必須項目です。';
      }
      if (this.err_agreement == '') {
        this.btn_text = 'OK';
        let data = { 'id': this.id, 'agreement': this.agreement };
        this.$emit("submit", this.id, data);
      }
    },
  }
}
</script>

<style scoped>
.input_agreement {
  padding: 3px 0;
  overflow: visible;
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.input_body {
  padding: 10px;
  background: #fcf9ce;
}

.input_group {
  width: 100%;
  word-break: break-word;
  margin-bottom: 10px;
}

.title {
  white-space: pre-line;
  padding: 3px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 1px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
}

.input_box {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.input_box pre {
  width: calc(100% - 10px);
  font-size: 16px;
  line-height: 1.2em;
  height: calc(1.2em * var(--lines));
  margin-top: 10px;
  margin-bottom: 10px;
}

.input {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 100%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  outline: 0;
  margin: 0;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: block;
  overflow-y: scroll;
}

.input_half {
  text-align: left;
  font-size: 16px;
  background-color: #fff5f0;
  color: #333;
  border-color: #e7661c;
  width: 48%;
  height: 36px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  outline: 0;
  margin: 0 0 0 5px;
  line-height: 18px;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  display: inline-block;
}

.input::placeholder {
  color: #aaa;
}

.input_half::placeholder {
  color: #aaa;
}

.input_box input[type="checkbox"] {
  margin-left: 5px;
}

.next_message {
  text-align: right;
  float: right;
  padding-right: 0px;
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  overflow: visible;
}

.btn {
  min-width: 90px;
  width: auto;
  min-height: 42px;
  visibility: visible;
  box-sizing: content-box;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  outline: 0;
  color: #ffffff;
  background-color: #fa9352;
  padding: 0px 14px 0px 14px;
  border-radius: 25px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  background-image: none;
  overflow: visible;
}

button:not(.disabled) {
  -webkit-animation: bounce 2.6s infinite ease-in-out;
  animation: bounce 2.6s infinite ease-in-out;
}

@keyframes bounce {
  5% {
    -webkit-transform: scale(1.2, .8);
  }

  10% {
    -webkit-transform: scale(.8, 1.2) translateY(-5px);
  }

  15% {
    -webkit-transform: scale(1, 1);
  }
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.required {
  color: red;
  font-size: 12px;
}

.optional {
  color: gray;
  font-size: 12px;
}

.err {
  color: red;
  font-weight: 400;
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
}

.fachatbot_input_agreement-enter-active {
  transition: right .38s .3s ease, opacity .38s .3s ease-in;
}

.fachatbot_input_agreement-enter-to {
  opacity: 1;
  right: 0;
}

.fachatbot_input_agreement-enter-from {
  opacity: 0;
  right: -100px;
}
</style>
